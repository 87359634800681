import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { Col, Table, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { deleteCourses, getCourses } from "../../store/slices/courses";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";

function Courses() {
  const teamHeaders = ["Name", "Icon", "Combination", "Action"];
  const [courses, setCourses] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  // const item = {
  //   limit: itemsPerPage,
  //   page: currentPage,
  // };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const navigate = useNavigate();
  const handleViewCombinationsClick = (courseId) => {
    navigate(`/combination/${courseId}`);
  };

  const handleAddCourseClick = () => {
    navigate("/addcourse");
  };

  const HandleEdit = (id2) => {
    navigate(`/courses/edit/${id2}`);
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };
  useEffect(() => {
    getcourseslist();
  }, [dispatch, itemsPerPage, currentPage]);

  const getcourseslist = () => {
    setLoading(true);
    dispatch(getCourses())
      .unwrap()
      .then((data) => {
        console.log(data);
        setCourses(data.user.data);
        setTotaldata(data?.user?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const HandleDelete = () => {
    const item = {
      id: deleteId,
    };
    dispatch(deleteCourses(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        setShowModal(false);
        getcourseslist();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={HandleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>
              &nbsp;&#8811; Courses
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Courses</h4>
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={handleAddCourseClick}
                  >
                    Add Course
                  </button>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !courses?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>No courses were added to this category. </h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead>
                        <tr>
                          {teamHeaders.map((header, index) => (
                            <th
                              className="text-center align-middle"
                              key={index}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {courses.map((course, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="text-center align-middle">
                              {course.courseName}
                            </td>
                            <td className="text-center align-middle">
                              <img
                                src={course.icon}
                                alt={`${course.courseName} logo`}
                                style={{
                                  maxWidth: "80px",
                                  maxHeight: "50px",
                                }}
                              />
                            </td>
                            <td className="text-center align-middle">
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() =>
                                  handleViewCombinationsClick(course._id)
                                }
                              >
                                View Combinations
                              </button>
                            </td>

                            <td className="text-center align-middle">
                              <button
                                className="btn btn-outline-info btn-sm"
                                onClick={() => HandleEdit(course._id)}
                              >
                                <MdEdit className="viewbtn" />
                              </button>

                              <button
                                className="btn btn-outline-danger btn-sm ms-2"
                                onClick={() => handleDeleteClick(course._id)}
                              >
                                <MdDelete className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
              <div className="mr-5">
                {totaldata / itemsPerPage > 1 && (
                  <div className="mt-5 d-flex justify-content-end align-right">
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(totaldata / itemsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Courses;
