import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  AddChapters,
  GetCombinationsDetails,
} from "../../store/slices/courses";
import { Logout } from "../../utils/helpers/Logout";
import { categorynameregex } from "../../utils/Regex";

function AddChapter() {
  const { id } = useParams();
  const [chapterName, setChapterName] = useState("");
  const [chapterNumber, setChapterNumber] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [chapterNameError, setChapterNameError] = useState();
  const [chapterNumberError, setChapterNumberError] = useState();
  const [buttonDisabled, setButtonDisabled] = useState();
  const [breadData, setBreadData] = useState();
  const combinationId = localStorage.getItem("combinationId");
  const chapterId = localStorage.getItem("chapterId");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
  }, []);

  const fetchData1 = () => {
    dispatch(GetCombinationsDetails(chapterId))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      })
      .catch(({ message }) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    if (chapterName === "") {
      setChapterNameError("Please enter chapter name");
      isValid = false;
    }
    if (chapterNumber === "") {
      setChapterNumberError("Please enter chapter number ");
      isValid = false;
    } else if (chapterNumber <= 0) {
      setChapterNumberError("Please enter valid chapter number");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    let item = {
      chapterName,
      chapterNumber: parseInt(chapterNumber),
      status: 1,
      combinationId: id,
    };
    setButtonDisabled(true);
    dispatch(AddChapters(item))
      .unwrap()
      .then((data) => {
        setButtonDisabled(false);
        alert("Chapter added succesfully..");
        navigate(-1);
      })
      .catch(({ message }) => {
        setButtonDisabled(false);
        alert(message);
      });
  };

  const chapterNameChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
    setChapterName(filteredValue);
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
    if (!categorynameregex.test(filteredValue)) {
      setChapterNameError("Please enter valid Name");
    } else {
      setChapterNameError("");
    }
  };

  const chapterNumberChange = (val) => {
    if (val <= 0) {
      setChapterNumberError("Please enter valid chapter number");
    } else {
      setChapterNumberError("");
    }
    setChapterNumber(val);
  };

  const cancelHandler = () => {
    navigate(-1);
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationId}/${id}`}>Chapters</Link>
              &nbsp;&#8811;&nbsp;Add Chapter
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">
                    {breadData?.courseId?.courseName} &#8811;&nbsp;
                    {breadData?.subjectId?.subjectName}
                  </h4>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div>
                    <div className="row">
                      <div
                        style={{ height: "100%" }}
                        className="col-md-5 pt-2 pb-2"
                      >
                        <span className="label">Chapter Name</span>
                        <input
                          type="text"
                          placeholder="Enter chapter name"
                          className="form-control"
                          value={chapterName}
                          onChange={(e) => chapterNameChange(e.target.value)}
                        />
                        <p className="alert-message">{chapterNameError}</p>
                      </div>

                      <div
                        style={{ height: "100%" }}
                        className="col-md-5 pt-2 pb-2"
                      >
                        <span className="label">Chapter Number</span>
                        <input
                          type="string"
                          placeholder="Enter chapter number"
                          className="form-control"
                          value={chapterNumber}
                          maxLength={3}
                          onInput={(e) => {
                            e.target.value = e?.target?.value
                              ?.replace(/[^0-9]/g, "")
                              .slice(0, 3);
                          }}
                          onChange={(e) => chapterNumberChange(e.target.value)}
                        />
                        <p className="alert-message">{chapterNumberError}</p>
                      </div>
                    </div>

                    <div className="col-md-10 mt-3" align="center">
                      <button
                        className="btn btn-outline-info wd-100"
                        onClick={cancelHandler}
                        disabled={buttonDisabled}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-info wd-100 ms-2"
                        onClick={handleSubmit}
                        disabled={buttonDisabled}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default AddChapter;
