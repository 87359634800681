import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { Col, Table, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";
import { GetTickets } from "../../store/slices/tickets";
import { DeleteAlerts } from "../../store/slices/alert";
import moment from "moment";

function GetAllTickets() {
  const teamHeaders = [
    "Raised By",
    "Description",
    "Status",
    "Raised date",
    "Action",
  ];
  const [tickets, setTickets] = useState([]);
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const navigate = useNavigate();

  const handleaddTickests = () => {
    navigate("/tickets/add");
  };

  const HandleEdit = (id2) => {
    navigate(`/tickets/edit/${id2}`);
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };
  useEffect(() => {
    gettickets();
  }, [dispatch, itemsPerPage, currentPage]);

  const gettickets = () => {
    const item = {
      limit: itemsPerPage,
      page: currentPage,
    };
    setLoading(true);
    dispatch(GetTickets(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setTickets(data?.tickets?.data);
        setTotaldata(data?.tickets?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const HandleDelete = () => {
    const item = {
      id: deleteId,
    };

    console.log(item);
    dispatch(DeleteAlerts(item))
      .unwrap()
      .then((data) => {
        alert(data?.alerts?.message);
        setShowModal(false);
        gettickets();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={HandleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>
              &nbsp;&#8811; Tickets
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Tickets</h4>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !tickets?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>No tickets found.</h5>
                    </div>
                  ) : (
                    <>
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead>
                          <tr>
                            {teamHeaders.map((header, index) => (
                              <th
                                className="text-center align-middle"
                                key={index}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {tickets.map((tickets, rowIndex) => (
                            <tr key={rowIndex}>
                              <td className="text-center align-middle">
                                {tickets?.userId?.name}
                              </td>
                              <td className="text-center align-middle">
                                {tickets?.description}
                              </td>
                              <td className="text-center align-middle">
                                {tickets?.status === 1
                                  ? "Resolved"
                                  : "Not Resolved"}
                              </td>
                              <td className="text-center align-middle">
                                {moment
                                  .utc(tickets?.updatedAt)
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td className="text-center align-middle">
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  onClick={() => HandleEdit(tickets._id)}
                                >
                                  <MdEdit className="viewbtn" />
                                </button>

                                <button
                                  className="btn btn-outline-danger btn-sm ms-2"
                                  onClick={() => handleDeleteClick(tickets._id)}
                                >
                                  <MdDelete className="viewbtn" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </div>
              <div className="mr-5">
                {totaldata / itemsPerPage > 1 && (
                  <div className="mt-5 d-flex justify-content-end align-right">
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(totaldata / itemsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default GetAllTickets;
