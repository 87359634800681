import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";






function LineChart({Hourlydata}) {
    console.log(Hourlydata)

      // Extracting keys (time intervals) and values (view counts) from Hourlydata
      if(Hourlydata){
      const datad = Object.keys(Hourlydata);
      const data = Object.values(Hourlydata);
      const LineColumnAreaData = {
      series: [
        {
          name: "User activities",
          type: "column",
          data: data,
        },
      
      ],
      options: {
        chart: {
          height: 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false
            },
        },
        stroke: {
          width: [0, 1, 1],
            dashArray: [0, 0, 5],
            curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: "25%",
          },
        },
        legend: {
          show: false,
      },
        colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],
    
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: datad,
        markers: {
          size: 0,
        },
        xaxis: {
          type: "hours",
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + "  activities"
              }
              return y
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      }
      }
    


  return  <ReactApexChart
  options={LineColumnAreaData.options}
  series={LineColumnAreaData.series}
  type="line"
  height="450"
  stacked= "false"
  className="apex-charts"
/>
      }
}

export default LineChart;
