import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {  Col ,Container,Table} from "reactstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import { getCategoryQuiz,deleteCategoryQuiz } from "../../../store/slices/faq";
import ReactPaginate from "react-paginate";
import { MdDelete,MdEdit } from "react-icons/md";

function Quiz() {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [quiz, setQuiz] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [quizId, setQuizId] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [totaldata, setTotaldata] = useState();
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };
const handleEditQuizClick = (id) =>{
    navigate(`/editquiz/${id}`);
}
  const handleAddQuizSubjects = () => {
    navigate("/addquiz");
  };
const handleViewContentClick = (id) =>{
  navigate(`/quizcontent/${id}`);
}
  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout()
  }, []);

  useEffect(() => {
    getQuiz();
  }, [itemsPerPage, currentPage]);

  const getQuiz = () => {
    setLoading(true);
    dispatch(getCategoryQuiz(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setQuiz(data.quiz.data);
        setTotaldata(data?.quiz?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDelete = () => {
    const deleteItem = {
        id:quizId
    }
    dispatch(deleteCategoryQuiz(deleteItem))
      .unwrap()
      .then((data) => {
        alert(data.quiz.message);
        setShowModal(false);
        getQuiz();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const  handleDeleteQuizClick= (id) => {
    setShowModal(true);
    setQuizId(id);
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
     <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/dashboard">Home</Link> &#8811; Quiz
                  
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Quiz</h4>
                        &nbsp;
                        <button
                          className="btn btn-primary ms-2"
                          onClick={handleAddQuizSubjects}
                        >
                          Add Category
                        </button>
                      </div>
                    </div>

                    <div class="card-body collapse show">
                      <div class="card-block card-dashboard table-responsive">
                        {loading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : !quiz?.length ? (
                          <div className="mt-4 text-center card-body collapse show pb-3">
                            <h5>No Category were added </h5>
                          </div>
                        ) : (
                          <Table
                            responsive
                            hover
                            style={{ overflowX: "scroll" }}
                          >
                            <thead>
                              <tr>
                                <th className="text-center">Name</th>
                                <th className="text-center">Icon</th>
                                <th className="text-center">Quiz Type</th>
                                <th className="text-center">Content</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {quiz.map((quiz, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">
                                    {quiz.name}
                                  </td>
                                  <td className="text-center align-middle">
                                    <img
                                      src={quiz.icon}
                                      alt=""
                                      style={{ width: "60px" }}
                                    />
                                  </td>
                                  <td className="text-center align-middle">
                                  {quiz.type.charAt(0).toUpperCase() +
                                  quiz.type.slice(1)}
                                  </td>
                                  <td className="text-center align-middle">
                                    
                                      <button className="btn btn-outline-primary btn-sm"
                                      onClick={() => handleViewContentClick(quiz._id)}>
                                        View Content
                                      </button>
                                    
                                  </td>
                                  <td className="text-center align-middle">
                                    <button
                                       className="btn btn-outline-info btn-sm"
                                      onClick={() =>
                                        handleEditQuizClick(quiz._id)
                                      }
                                    >
                                    <MdEdit className="viewbtn"  />
                                    </button>

                                    <button
                                      className="btn btn-outline-danger btn-sm ms-2"
                                      onClick={() =>
                                        handleDeleteQuizClick(quiz._id)
                                      }
                                    >
                                      <MdDelete  className="viewbtn" />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                
        <div className="mr-5">
        {totaldata / itemsPerPage > 1 && (
          <div className="mt-5 d-flex justify-content-end align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    
      </div>
      </Container>
        </div>
     </React.Fragment>
  </>
  );
}

export default Quiz;
