import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Table } from "reactstrap";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ReactPaginate from "react-paginate";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import {
  GetUnitDetails,
  deleteMaterial,
  getMaterials,
} from "../../../store/slices/courses";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";

function Material() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const [breadData, setBreadData] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
  }, []);

  const fetchData1 = () => {
    dispatch(GetUnitDetails(id))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      })
      .catch(({ message }) => {});
  };
  const handleAddMaterial = () => {
    navigate(`/add-materials/${id}`);
  };

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    getMaterial();
  }, [itemsPerPage, currentPage]);

  const getMaterial = () => {
    setLoading(true);
    dispatch(getMaterials(id))
      .unwrap()
      .then((data) => {
        setData(data?.material?.data);
        setTotaldata(data?.material?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const DeleteMaterial = () => {
    dispatch(deleteMaterial(deleteId))
      .unwrap()
      .then((data) => {
        alert(data?.material?.message);
        setShowModal(false);
        getMaterial();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const chapterId = localStorage.getItem("chapterId");
  const combinationId = localStorage.getItem("combinationId");
  const unitId = localStorage.getItem("unitId");
  useEffect(() => {
    localStorage.setItem("materialId", id);
  }, []);

  return (
    <div>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={DeleteMaterial}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>&nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationId}/${chapterId}`}>
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/units/${unitId}`}>Units</Link>
              &nbsp;&#8811;&nbsp;Material
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex  align-items-center">
                  <h4 class="card-title">
                    {breadData?.courseId.courseName} &#8811;{" "}
                    {breadData?.subjectId.subjectName} &#8811;{" "}
                    {breadData?.chapterId.chapterName} &#8811;{" "}
                    {breadData?.unitName}
                  </h4>
                  &nbsp;
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={handleAddMaterial}
                  >
                    Add Material
                  </button>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No materials were added to this unit</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Topic Name</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">
                              {item.materialNumber}
                            </td>
                            <td className="text-center align-middle">
                              {item.materialName}
                            </td>
                            <td className="daily text-center align-middle">
                              <Link
                                to={`/previewmaterial/${id}/${item?._id}`}
                                style={{ height: "25px", color: "white" }}
                              >
                                <button className="btn btn-outline-info btn-sm">
                                  <MdEdit className="viewbtn" />
                                </button>
                              </Link>

                              <button
                                onClick={() => handleDeleteClick(item?._id)}
                                className="btn btn-outline-danger btn-sm ms-2"
                              >
                                <MdDelete className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
             

        <div className="mr-5">
          {totaldata / itemsPerPage > 1 && (
            <div className="mt-5 d-flex justify-content-end align-right">
              <ReactPaginate
                key={currentPage}
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakLinkClassName={"page-link"}
                pageCount={Math.ceil(totaldata / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={pagesToShowInitially}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                initialPage={currentPage - 1}
              />
            </div>
          )}
        </div>
        </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default Material;
