import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FaqService from "../../services/faq.services";


export const getFaq = createAsyncThunk(
    "faq/get",
    async (item,thunkAPI) => {
      try {
        const data = await FaqService.getFaq(item);
        return {faq: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  
  export const editFaq = createAsyncThunk(
    "edit/faq",
    async (item, thunkAPI) => {
      try {
        const data = await FaqService.editFaq(item);
        return {faq: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

export const getFaqDetails =createAsyncThunk(
  "faq/get",
  async (id, thunkAPI) => {
    try {
      const data = await FaqService.getFaqDetails(id);
      return {faq:data};
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


  export const addFaq = createAsyncThunk(
    "post/faq",
    async (item, thunkAPI) => {

      try {
        console.log({item});
        const data = await FaqService.postFaq(item);
        return { faq: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const deleteFaq = createAsyncThunk(
    "delete/deletefaq",
    async (item, thunkAPI) => {
      try {
        const data = await FaqService.deleteFaq(item);
        return {faq: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  export const getSupportIssues = createAsyncThunk(
    "faq/support",
    async (item,thunkAPI) => {
      try {
        const data = await FaqService.getSupportIssues(item);
        return {issues: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  //ticket
  export const getTickets = createAsyncThunk(
    "get/tickets",
    async (thunkAPI) => {
      try {
        const data = await FaqService.getTicketList();
        return {tickets : data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
    //close ticket
    export const CloseTickets = createAsyncThunk(
      "post/tickets",
      async (item,thunkAPI) => {
        try {
          const data = await FaqService.closeTicket(item);
          return {tickets : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

    //getticketStatistics
    export const GetticketStatistics = createAsyncThunk(
      "get/tickets",
      async (thunkAPI) => {
        try {
          const data = await FaqService.getTicketStatistics();
          return {tickets : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

    export const Getissues = createAsyncThunk(
      "get/issues",
      async (item,thunkAPI) => {
        try {
          const data = await FaqService.getIssues(item);
          return {tickets : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );


    export const GetissueDetails = createAsyncThunk(
      "get/issuesdetails",
      async (item,thunkAPI) => {
        try {
          const data = await FaqService.getIssueDetails(item);
          return {tickets : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );
    export const postissues = createAsyncThunk(
      "post/issues",
      async (item,thunkAPI) => {
        try {
          const data = await FaqService.addIssues(item);
          return {tickets : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

    export const EditIssues = createAsyncThunk(
      "edit/issues",
      async (item,thunkAPI) => {
        try {
          const data = await FaqService.editIssues(item);
          return {tickets : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

    
    export const deleteIssues = createAsyncThunk(
      "delete/issues",
      async (item,thunkAPI) => {
        try {
          const data = await FaqService.deleteIssues(item);
          return {tickets : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

    export const getVisitors = createAsyncThunk(
      "get/visitors",
      async (item,thunkAPI) => {
        try {
          const data = await FaqService.getVisitorsList(item);
          return {visitors : data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

    export const getCategoryQuiz = createAsyncThunk(
      "quiz/get",
      async (item, thunkAPI) => {
        try {
          const data = await FaqService.getCategoryQuiz(item);
          return { quiz: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

    export const deleteCategoryQuiz = createAsyncThunk(
      "delete/deletequiz",
      async (item, thunkAPI) => {
        try {
          const data = await FaqService.deleteCategoryQuiz(item);
          return { quiz: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );
    export const postCategoryQuiz = createAsyncThunk(
      "post/addquiz",
      async (item, thunkAPI) => {
  
        try {
          console.log({item});
          const data = await FaqService.postCategoryQuiz(item);
          return { quiz: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );
    export const editCategoryQuiz = createAsyncThunk(
      "edit/editquiz",
      async (item, thunkAPI) => {
        try {
          const data = await FaqService.editCategoryQuiz(item);
          return {quiz: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );
  
  export const getCategoryQuizDetails =createAsyncThunk(
    "get/quiz",
    async (id, thunkAPI) => {
      try {
        const data = await FaqService.getCategoryQuizDetails(id);
        return {quiz:data?.data};
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getContentQuiz = createAsyncThunk(
    "get/quizcontent",
    async (item, thunkAPI) => {
      try {
        const data = await FaqService.getContentQuiz(item);
        return { content: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const deleteContentQuiz = createAsyncThunk(
    "delete/deletequiz",
    async (item, thunkAPI) => {
      try {
        const data = await FaqService.deleteContentQuiz(item);
        return { content: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const  postContentQuiz = createAsyncThunk(
    "post/addquiz",
    async (item, thunkAPI) => {

      try {
        console.log({item});
        const data = await FaqService.postContentQuiz(item);
        return { content: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const editContentQuiz = createAsyncThunk(
    "edit/editcontent",
    async(item, thunkAPI) => {
        try {
            const data = await FaqService.editContentQuiz(item);
            return { content: data?.data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
    );

    export const getContentQuizDetails = createAsyncThunk(
      "/get/quizcontent",
      async (item,thunkAPI) => {
        try {
          const {quizCategoryId,id}=item;
          const data = await FaqService.getContentQuizDetails(quizCategoryId,id);
          return { content: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        }
      }
    );

  const initialState = {
    loading: false,
    error: "",
    faq :[]||null,
    isLoggedIn: false,
};
const faq = createSlice({
    name: "faq",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

  const { reducer } = faq;
export default reducer;