import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, ModalBody, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { getCourses } from "../../../store/slices/courses";
import { AssignPlans } from "../../../store/slices/plans";
function AssignModal({ showModal, setShowModal,planId,id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalMessage, setModalMessage] = useState();
  const handleCloseModal = () => setShowModal(false);
  const [courses,setCourses ] = useState([])
const [selectedIds,setSelectedIds] = useState([])
const [courseError,setCourseError] = useState("")
const [discountError,setDiscountErorr] = useState("")
const [discount,setDiscount] = useState()
const [courseIds,setCourseIds] = useState([])

  const handleChange = (selected) => {
		setCourseError("")
		setSelectedIds(selected)
    setCourseIds(selected)
	 }

   const discountChange =(e)=>{
    setDiscountErorr("")
    setDiscount(e.target.value)
   }

   useEffect(() => {
    getcourseslist();
  }, [dispatch]);

  const getcourseslist = () => {

    dispatch(getCourses())
      .unwrap()
      .then((data) => {
        setCourses(data.user.data);
      
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const submit =()=>{
    if(!discount){
      setDiscountErorr("Please enter discount")
    }

    if(courseIds.length <1){
      setCourseError("Please select course")
    }

    if(!discount || courseIds.length<1){
      return
    }else{
      handleAssignClick()
    }
  }

  const handleAssignClick = () => {
    const item = {
      planId: planId,
      userId: id,
      discount:discount,
      courseIds: courses
      .filter(course => courseIds.includes(course.courseName))
      .map(course => course._id)
    };
    dispatch(AssignPlans(item))
      .unwrap()
      .then((data) => {
        alert(data?.plans?.message);
        setShowModal(false);
        navigate(-2);
        
      })
      .catch((err) => {
        alert(err.message);
        setShowModal(false);
      });
  };

  return (
    <>
      <Modal
         isOpen={showModal}
         toggle={handleCloseModal}
         centered
      >
      <ModalBody className="d-flex flex-column justify-content-center align-items-center p-4" style={{ height: "400px" }}>
  <h5 className="mt-3 mb-4">Are you sure to assign this plan?</h5>
  
  <div className="d-flex flex-column align-items-start w-100">
    <label htmlFor="discountInput" className="mb-2">Discount</label>
    <input
      id="discountInput"
      type="number"
      value={discount}
      className="form-control"
      placeholder="Enter discount..."
      onChange={discountChange}
    />
      <p className='alert-message'>{discountError}</p>

  </div>

  <div className="d-flex flex-column align-items-start w-100">
    <label htmlFor="courseTypeahead" className="mb-2">Courses</label>
    <Typeahead
      id="courseTypeahead"
      clearButton
      labelKey="name"
      multiple
      onChange={handleChange}
      options={courses.map((module) => module.courseName)}
      placeholder="Select subjects"
      selected={selectedIds}
      className="w-100" // Ensure Typeahead takes full width
    />
    <p className='alert-message'>{courseError}</p>
  </div>

        <div className="d-flex">
        <button  color="primary"
         className="btn font-14 btn-outline-info waves-effect m-2 waves-light" style={{ marginRight: "5px" }} onClick={handleCloseModal}>
          Cancel
        </button>
        <button className="btn font-14 btn-info waves-effect m-2 waves-light" onClick={submit}>
          Assign
        </button>
        </div>
      </ModalBody>
      </Modal>
    </>
  );
}

export default AssignModal;
//modal