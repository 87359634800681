import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button ,Container,Table} from "reactstrap";


function Withdrawal() {
  const teamHeaders = [
    "Name",
    "E-mail",
    "Phone Number",
    "Amount",
    "Status",
    "Date of Payment",
  ];
  const data = [
    {
      name: "Siri",
      email: "siri21@gmail.com",
      phonenumber: "9098765432",
      amount: "890",
      status: "active",
      dateofpayment: "09-01-2000",
    },
    {
      name: "Siri",
      email: "siri21@gmail.com",
      phonenumber: "9098765432",
      amount: "890",
      status: "active",
      dateofpayment: "09-01-2000",
    },
    {
      name: "Siri",
      email: "siri21@gmail.com",
      phonenumber: "9098765432",
      amount: "890",
      status: "active",
      dateofpayment: "09-01-2000",
    },
    {
      name: "Siri",
      email: "siri21@gmail.com",
      phonenumber: "9098765432",
      amount: "890",
      status: "active",
      dateofpayment: "09-01-2000",
    },
    {
      name: "Siri",
      email: "siri21@gmail.com",
      phonenumber: "9098765432",
      amount: "890",
      status: "active",
      dateofpayment: "09-01-2000",
    },
  ];
 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = () => {
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
  };
  const containerStyle = {
    width: "200px",
    height: "10px",
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/dashboard">Home</Link> &#8811; Withdrawal
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Withdrawal</h4>
                      </div>
                    </div>
                    <div class="row ps-5">
                      <div class="col-md-4">
                        <label>Start Date:</label>
                        <input
                          type="date"
                          required={true}
                          name="startDate"
                          className="form-control"
                          placeholder="Enter date"
                          onChange={(e) => handleStartDateChange(e)}
                          value={startDate}
                        />
                      </div>
                      <div class="col-md-4">
                        <label>End Date:</label>
                        <input
                          type="date"
                          required={true}
                          name="startDate"
                          className="form-control"
                          placeholder="Enter date"
                          onChange={(e) => handleStartDateChange(e)}
                          value={startDate}
                        />
                      </div>
                      <div class="col-md-4">
                        <button
                          className="btn btn-info mt-3"
                          // onClick={{ handleSubmit }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div class="card-body collapse show mt-4">
                      <div class="card-block card-dashboard table-responsive">
                        <Table responsive hover style={{ overflowX: "scroll" }}>
                          <thead className="responseHeaderStyle">
                            <tr>
                              {teamHeaders.map((header, index) => (
                                <th
                                  key={index}
                                  style={{ backgroundColor: "white" }}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          {/* <tbody>
                            {data.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {columns.map((column, colIndex) => (
                                  <td key={colIndex}>{row[column]}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody> */}
                        </Table>
                      </div>
                    </div>
                  </div>
             </Container>
             </div>
             </React.Fragment>
    </>
  );
}
export default Withdrawal;
