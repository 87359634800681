import React, { useEffect, useState } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { Button, Col, Table, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import { deleteplans, getplans } from "../../store/slices/plans";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import ReactPaginate from "react-paginate";
import { MdDelete,MdEdit } from "react-icons/md";

const Plans = () => {
  const teamHeaders = ["Name", "Price", "Description", "Action"];
  const [data, setData] = useState([]);
  const [search, setSearch] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const [totaldata, setTotaldata] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditClick = (elem) => {
    navigate(`/edit-plans/${elem._id}`);
  };

  const handleAddPlansClick = () => {
    navigate("/add-plans");
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  useEffect(() => {
    GetPlans();
  }, [dispatch, itemsPerPage, currentPage]);

  const GetPlans = () => {
    setLoading(true);
    dispatch(getplans(item))
      .unwrap()
      .then((response) => {
        console.log("API Response:", response);
        setData(response.plans.data);
        setTotaldata(response.plans.totalDataCount);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching plans:", err);
        setLoading(false);
      });
  };

  const handleDeleteClick = (elem) => {
    setShowModal(true);
    setDeleteId(elem._id);
  };

  const handleDelete = () => {
    dispatch(deleteplans(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.plans.message);
        GetPlans();
        setShowModal(false);
      })
      .catch((err) => {
        console.error("Error deleting plan:", err);
      });
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              className="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811; Plans
            </div>
            <div className="card">
              <div className="card-header">
                <div className="card-title-wrap bar-success d-flex align-items-center">
                  <h4 className="card-title">Plans</h4>
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={handleAddPlansClick}
                  >
                    Add Plans
                  </button>
                </div>
              </div>
              <div className="card-body collapse show">
                <div className="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data || data.length === 0 ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>No plans were added yet.</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead>
                        <tr>
                          {teamHeaders.map((header, index) => (
                            <th
                              className="text-center align-middle"
                              key={index}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">
                              {item.name}
                            </td>
                            <td className="text-center align-middle">
                              {item.price}
                            </td>
                            <td className="text-center align-middle">
                              {item.description}
                            </td>
                            <td
                              style={{ minWidth: "300px" }}
                              className="text-center align-middle"
                            >
                              <button
                                className="btn btn-outline-info btn-sm"
                                onClick={() => handleEditClick(item)}
                              > 
                              <MdEdit className="viewbtn"  />
                              </button>
                              <button
                                className="btn btn-outline-danger btn-sm ms-2"
                                onClick={() => handleDeleteClick(item)}
                              >
                                 <MdDelete  className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              
        <div className="mr-5">
          {totaldata / itemsPerPage > 1 && (
            <div className="mt-5 d-flex justify-content-end align-right">
              <ReactPaginate
                key={currentPage}
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakLinkClassName={"page-link"}
                pageCount={Math.ceil(totaldata / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={pagesToShowInitially}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                initialPage={currentPage - 1}
              />
            </div>
          )}
        </div>
        </div>
            </div>
          </Container>
          </div>
      </React.Fragment>
    </>
  );
};

export default Plans;
