import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class PlanService {
    static postplans(item) {
        
       
        return axiosInstance
            .post(API_PATHS.addplan, item)
          
    }
    static getallplans(item) {
       
        let api;
      
        api = `${API_PATHS.getplan}?limit=${item.limit}&page=${item.page}`;
    
        return axiosInstance.get(api);
           
    }

    static getPlanDetails (id) {
       
        let api;
      
        api = `${API_PATHS.getplan}?id=${id}`;

        return axiosInstance.get(api);  
    }

    static editplans(item) {
        
        return axiosInstance.put(API_PATHS.editplan, item)
            
    }

    static deleteplans(id) {
      
        return axiosInstance
            .delete(`${API_PATHS.deleteplan}?id=${id}`)
            
    }


    static assignPlans(item) {
      return axiosInstance
          .post(API_PATHS.assignplans, item)
          
  }


  static upgradePlans(item) {
    
    return axiosInstance
        .post(API_PATHS.upgradeplan, item)
        
}

    static getcategories(item) {
    
        let api = '';
          api = `${API_PATHS.getcategories}`;
        return axiosInstance
            .get(api)
            
    }

   
}

// export default AuthService;
export default PlanService;