import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal, Button, Col, Container, Table } from "reactstrap";
import { getUnPaid } from "../../store/slices/paid";
import ReactPaginate from "react-paginate";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import DataTable from "react-data-table-component";

import Moment from "moment";
import ExportCSVButton from "../../utils/helpers/ExportButton";

const UnpaidUsers = () => {
  const teamHeaders = [
    "Name",
    "Referral Code",
    "Email",
    "Phone Number",
    "Sponser Code",
    "Date of Registration",
  ];
  const [data, setData] = useState([]);
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState([]);
  const [searchterm, setSearchterm] = useState();
  const [limit, setLimit] = useState();
  const itemsPerPage = 15;
  const pagesToShowInitially = 3;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <Link to={`/userDetails/${"unpaid"}/${row?._id}`}>{row?.name}</Link>
      ),
    },
   
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.mobileNo,
    },
   
    {
      name: "Date of Registration",
      selector: (row) =>formatDate(row?.updatedAt)
      
    }
    
  ];

  const dispatch = useDispatch();

  // const [modalShow, setModalShow] = useState(false);
  // const [modalData, setModalData] = useState({});

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
    const start = selected * itemsPerPage;
    const end = start + itemsPerPage;
    setFilter(data.slice(start, end));
  };
  // const handleClose = () => setModalShow(false);
  let subscriptionStatus=0
  const item = {
    userType: "unpaid",
    limit: itemsPerPage,
    page: currentPage,
    subscriptionStatus:subscriptionStatus
  };

  useEffect(() => {
    GetUnPaid();
  }, [currentPage]);

  const GetUnPaid = () => {
    setLoading(true);
    dispatch(getUnPaid(item))
      .unwrap()
      .then((data) => {
        setData(data?.users?.data);
        setTotaldata(data?.users?.totalDataCount);
        setFilter(data?.users?.data.slice(0, itemsPerPage));
        setLimit(data?.users?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    let filteredItems = data.filter((item) =>
      item?.name?.toLowerCase().match(searchterm?.toLocaleLowerCase())
    );
    setFilter(filteredItems.slice(0, itemsPerPage));
    setTotaldata(filteredItems.length);
    setCurrentPage(1);
    if(searchterm ===""){
      GetUnPaid()
    }
  }, [searchterm]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811; Unpaid Users
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Unpaid Users</h4>
                </div>
                <div class="d-flex justify-content-end">
                  <ExportCSVButton
                    limit={limit}
                    filename="unpaidusers.xlsx"
                    getData={getUnPaid}
                    item={item}
                  />
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-end mr-2 mb-3">
                  <form
                    className="app-search  d-lg-block"
                    style={{ marginRight: "30px" }}
                  >
                    <div className="position-relative ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => setSearchterm(e.target.value)}
                      />
                      <span className="ri-search-line"></span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !filter?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No Users data is added.</h5>
                    </div>
                  ) : (
                    <>
                      <DataTable columns={columns} data={filter} />
                    </>
                  )}
                </div>
          <div class="mr-5">
            {totaldata / itemsPerPage > 1 && (
              <div className=" d-flex justify-content-end align-right">
                <ReactPaginate
                  key={currentPage}
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakLinkClassName={"page-link"}
                  pageCount={Math.ceil(totaldata / itemsPerPage)}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={pagesToShowInitially}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  initialPage={currentPage - 1}
                />
              </div>
            )}
          </div>

          </div>
        </div>
          </Container>
          </div>
      </React.Fragment>

      {/* <Modal show={modalShow}>
        <Modal.Header>
          <Modal.Title style={{ marginBottom: "20px", marginTop: "10px" }}>
            Sponsor Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalData && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Name:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.name}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Email:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.email}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Phone Number:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.mobileNo}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Sponsor Code:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.sponsorCode}</p>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn saveBtn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default UnpaidUsers;
