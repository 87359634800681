import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card ,Container} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetticketStatistics } from "../../store/slices/faq";
import { Button ,Col} from 'reactstrap';
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";

const Support = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [issuesData, setIssuesData] = useState([]);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    dispatch(GetticketStatistics())
      .unwrap()
      .then((data) => {
        setIssuesData(data.tickets.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        console.log(message);
      });
  }, [dispatch]);

 const handleAddIssueClick =()=>{
  navigate('/issue')
 }

 const handleEditIssueClick =()=>{
  navigate('/editissue')
 }

 useEffect(() => {
  
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  Logout()
}, []);


  return (
    <div>
         <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{ fontWeight: "600" }}
                  >
                    <Link to="/dashboard">Home</Link>&nbsp; &#8811; Tickets Statistics
                  </div>
                  <div class="card">
                    <div class="card-header">
                    <div className="d-flex justify-content-between">
                      <div   class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Tickets Statistics</h4>
                        <button
                    className='btn btn-primary btn-sm ms-2'
                    onClick={ handleAddIssueClick}
                  >
                    Add Issue
                  </button>
 
                      </div>
                       
                 
                    </div>
                    </div>
                    <Card className="support-card">
                      <div>
                      {loading ? (
                        <Col lg={12} xs={12} md={12} align="center">
                          {ShowSkeleton()}
                        </Col>
                      ) : !issuesData?.length ? (
                        <div className="mt-4 text-center card-body collapse show pb-3">
                          <h5> No Issues  were raised yet. </h5>
                        </div>
                      ) : (
                        <div className="d-flex flex-wrap">
                          {issuesData.map((elem) => (
                            <div className="support-box">
                              <Link to={`/issues/${elem.name}/${elem._id}`}>
                                <div className="text-center text-primary">
                                  <h5 className="sm-font-15 roboto">
                                    {elem.name}
                                  </h5>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="text-dark ">
                                    Total issues raised
                                  </p>
                                  <p className="text-dark ">
                                    {elem.totalTickets}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="text-dark ">
                                    Total issues Open
                                  </p>
                                  <p className="text-dark  ">
                                    {elem.openTicketsCount}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="text-dark ">
                                    Total issues resolved
                                  </p>
                                  <p className="text-dark  ">
                                    {elem.closedTicketsCount}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                      </div>
                    </Card>
                  </div>
                  </Container>
                </div>
             </React.Fragment>
             </div>
  );
};

export default Support;
