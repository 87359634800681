import React, { useState, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

// Step 1: Register custom font sizes
const Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
];
Quill.register(Size, true);

const FaqTextEditor = ({ data, handleChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const quillRef = useRef(null);

  // Initialize editor with content if available
  useEffect(() => {
    if (data && !isInitialized) {
      setEditorHtml(data);
      setIsInitialized(true);

      // Manually set the correct font size in the dropdown after initialization
      const editor = quillRef.current?.getEditor();
      const selectedSize = editor?.getFormat().size || "16px"; // Default to 16px

      const picker = document.querySelector(".ql-size .ql-picker-label");
      picker.setAttribute("data-value", selectedSize);
    }
  }, [data, isInitialized]);

  const handleEditorChange = (html, delta, source, editor) => {
    setEditorHtml(html);
    handleChange(html);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: Size.whitelist }],
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  return (
    <div className="mt-2">
      <ReactQuill
        ref={quillRef}
        value={editorHtml}
        onChange={handleEditorChange}
        modules={modules}
        placeholder="Start From Here..."
        theme="snow"
      />
      <br />
    </div>
  );
};

export default FaqTextEditor;
