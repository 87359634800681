import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Table } from "reactstrap";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  deletefundamenntalMaterial,
  deletefundamentalUnits,
  getfundamentalMaterials,
  getfundamentalUnits,
} from "../../../store/slices/fundamentals";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import ReactPaginate from "react-paginate";
import { MdDelete, MdEdit } from "react-icons/md";

function FundamentalMaterial() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totaldata, setTotaldata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const data1 = JSON.parse(localStorage.getItem("units"))

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
    id
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  const handleAddFundamentalMaterial = () => {
    navigate(`/fundamentals/materials/add/${id}`,{state:data[0]});
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  useEffect(() => {
    getMaterials();
  }, [dispatch,itemsPerPage, currentPage]);

  const getMaterials = () => {
    setLoading(true);
    dispatch(getfundamentalMaterials(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setTotaldata(data?.fundamentalmatrial?.totalDataCount);
        setData(data.fundamentalmatrial.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const DeleteMaterial = () => {
    dispatch(deletefundamenntalMaterial(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.fundamentalmatrial.message);
        setShowModal(false);
        getMaterials();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const fundchapterid = localStorage.getItem("fundmentalchapterId");
  const fundunitid = localStorage.getItem("fundmentalunitId");

  return (
    <div>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={DeleteMaterial}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/home">Home</Link>&#8811;&nbsp;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/fundamentals/units/${fundunitid}`}>Units</Link>
              &nbsp;&#8811;&nbsp; Fundamental Material
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex  align-items-center">
                  <h4 class="card-title">

                  {data1?.subjectId?.subjectName}  &nbsp;&#8811;&nbsp;
                  {data1?.chapterId?.chapterName} &nbsp;&#8811;&nbsp; {data[0]?.unitId?.unitName}
                  </h4>
                  &nbsp;
                  <button
                    className="btn btn-primary btn-sm m-1"
                    onClick={handleAddFundamentalMaterial}
                  >
                    Add Material
                  </button>
                </div>

                <div class="card-body collapse show">
                  <div class="card-block card-dashboard table-responsive">
                    {loading ? (
                      <Col lg={12} xs={12} md={12} align="center">
                        {ShowSkeleton()}
                      </Col>
                    ) : !data?.length ? (
                      <div className="mt-4 text-center card-body collapse show pb-3">
                        <h5> No materials were added to this unit</h5>
                      </div>
                    ) : (
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th className="text-center">Topic Name</th>
                       
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => (
                            <tr key={index}>
                              <td className="text-center align-middle">
                                {item.materialNumber}
                              </td>
                              <td className="text-center align-middle">
                                {item.materialName}
                              </td>
                              <td className="daily text-center align-middle">
                                <Link
                                  to={{pathname:`/fundamentals/previewmaterial/${id}/${item._id}`,state:data[0]}}
                                  style={{
                                    height: "25px",
                                    color: "white",
                                  }}
                                >
                                  <button className="btn btn-outline-info btn-sm">
                                  <MdEdit className="viewbtn" />
                                  </button>
                                </Link>
                            
                             
                                <button
                                  onClick={() => handleDeleteClick(item._id)}
                                  className="btn btn-outline-danger btn-sm ms-2 "
                                >
                                   <MdDelete className="viewbtn" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                  </div>

          <div className="mr-5">
            {totaldata / itemsPerPage > 1 && (
              <div className="mt-5 d-flex justify-content-end align-right">
                <ReactPaginate
                  key={currentPage}
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakLinkClassName={"page-link"}
                  pageCount={Math.ceil(totaldata / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={pagesToShowInitially}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  initialPage={currentPage - 1}
                />
              </div>
            )}
          </div>
        </div>
        
              </div>
              </Container>
            </div>
         
      </React.Fragment>
    </div>
  );
}

export default FundamentalMaterial;
