import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import { Button, Col, Container, Table } from "reactstrap";
import {
  getUnitPretest,
  deleteUnitPretest,
} from "../../../store/slices/courses";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import ReactPaginate from "react-paginate";

function Pretest() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [totaldata, setTotaldata] = useState();
  const [pretestId, setPretestId] = useState();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const navigate = useNavigate("");

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    GetUnitPretest();
  }, [itemsPerPage, currentPage]);

  const GetUnitPretest = () => {
    setLoading(true);
    dispatch(getUnitPretest(id))
      .unwrap()
      .then((data) => {
        console.log({ get: data });
        if (data.pretest.data.length) {
          setData(data.pretest.data[0].questions);

          setTotaldata(data?.pretest?.totalDataCount);
          setPretestId(data.pretest.data[0]._id);
        } else if (!data.pretest.data.length) {
          setData([]);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {});
  };

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleDeletePretestClick = () => {
    dispatch(deleteUnitPretest(pretestId))
      .unwrap()
      .then((data) => {
        console.log({ deleteData: data });
        setShowModal(false);
        alert(data.pretest.message);
        GetUnitPretest();
      })
      .catch((errr) => {});
  };

  const handleAddPretestClick = () => {
    navigate(`/addpretest/${id}`);
  };

  const handleEditClick = () => {
    navigate(`/editpretest/${id}`);
  };

  const courseid = localStorage.getItem("courseId");
  const categoryid = localStorage.getItem("categoryId");
  const combinationid = localStorage.getItem("combinationId");
  const combinationid1 = localStorage.getItem("combinationId1");
  const unitId = localStorage.getItem("unitId");

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDeletePretestClick}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to={`/courses/${categoryid}`}>Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/view-combinations/${combinationid1}`}>
                Combinations
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationid}`}>Chapters</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/units/${unitId}`}>Units</Link>
              &nbsp;&#8811;&nbsp;Pretest
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <h4 class="card-title">Pre Test</h4>
                    {!data?.length ? (
                      <button
                        className="btn btn-primary btn-sm ms-2"
                        onClick={handleAddPretestClick}
                      >
                        Add Question
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  {data?.length > 0 && (
                    <div className="td">
                      <button
                        className="btn btn-outline-info btn-sm ms-2"
                        onClick={handleEditClick}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-outline-danger btn-sm ms-2"
                        onClick={handleDeleteClick}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No questions were added to this pretest.</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead className="responseHeaderStyle">
                        <tr>
                          <th className="text-center">Question</th>
                          <th className="text-center">Option</th>
                          <th className="text-center">Correct Option</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">
                              {item.question}
                            </td>

                            <td className="text-center align-middle">
                              {item.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  style={{ marginBottom: "15px" }}
                                >
                                  {option.optionNumber}-{option.optionValue}
                                </div>
                              ))}
                            </td>
                            <td className="text-center align-middle">
                              {item.correctOption}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>

                <div className="mr-5">
                  {totaldata / itemsPerPage > 1 && (
                    <div className="mt-5 d-flex justify-content-end align-right">
                      <ReactPaginate
                        key={currentPage}
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakLinkClassName={"page-link"}
                        pageCount={Math.ceil(totaldata / itemsPerPage)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={pagesToShowInitially}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        disabledClassName="disabled"
                        initialPage={currentPage - 1}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Pretest;
