import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AlertsService from "../../services/alerts.services";

export const AddAlert = createAsyncThunk(
    "/post/alerts",
    async (item, thunkAPI) => {
      try {
        const data = await AlertsService.addAlerts(item);
        return { alerts: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const EditAlerts = createAsyncThunk(
    "/edit/alerts",
    async (item, thunkAPI) => {
      try {
        const data = await AlertsService.editAlerts(item);
        return { alerts : data?.data }; 
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const GetAlerts = createAsyncThunk(
    "/get/alerts",
    async (item, thunkAPI) => {
      try {
        const data = await AlertsService.getAlerts(item);
        return { alerts : data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const DeleteAlerts = createAsyncThunk(
    "/delete/alerts",
    async (id, thunkAPI) => {
      try {
        const data = await AlertsService.deleteAlerts(id);
        return { alerts : data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  
  
const initialState = {
    loading: false,
    error: "",
  plas :[]||null,
    isLoggedIn: false,
};

const alertsSlice = createSlice({
    name: "alerts",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = alertsSlice;
export default reducer;
