import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class AuthService {
  static login(item) {
    return axios.post(API_PATHS.login, item).then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
      }
      return response.data;
    });
  }
  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    const user = localStorage.getItem("user");
    try {
      return JSON.parse(user);
    } catch (e) {
      console.error("Error parsing user details from localStorage", e);
      return null;
    }
  }

  // Method to set tokens in localStorage
  static setTokens(data) {
    localStorage.setItem("token", data.data.token);
    localStorage.setItem("refreshToken", data.data.refreshToken);
  }
}

// export default AuthService;
export default AuthService;
