import React from "react";
import Routes from "./Routes/index";
import "./App.css"
// Import Scss

import './assets/scss/theme.scss';


function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
