import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class AlertsService {
  static addAlerts(item) {
    return axiosInstance.post(API_PATHS.addalerts, item);
  }


  static getAlerts(item) {
    let api;
    if (item?.id) {
      api = `${API_PATHS.getalerts}?id=${item?.id}`;
    } else if (item?.courseId){
      api = `${API_PATHS.getalerts}?courseId=${item?.courseId}&limit=${item?.limit}&page=${item?.page}`;
    }else{
        api = `${API_PATHS.getalerts}?limit=${item?.limit}&page=${item?.page}`;
    }

    return axiosInstance.get(api);
  }

  static editAlerts(item) {
   
    return axiosInstance.put(API_PATHS.editalerts, item);
  }

  static deleteAlerts(item) {
   
    return axiosInstance.delete(`${API_PATHS.deletealerts}?id=${item?.id}`);
  }
}

export default AlertsService;
