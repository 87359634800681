import React from "react";
import { useNavigate } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import { Col, Container, Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import Moment from "moment";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  DeleteCurrentAffairs,
  getCurrentAffairs,
} from "../../store/slices/currentaffairs";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";

const CurrentAffairs = () => {
  const teamHeaders = [" Month", "Date", "Action"];
  const [data, setData] = useState();
  const [pagData, setPageData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useSearchParams();
  const [totaldata, setTotaldata] = useState();
  const [loading, setLoading] = useState(false);
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 25;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const handleEditClick = (elem) => {
    navigate(`/edit-current-affairs/${elem._id}`);
  };
  const handleAddCurrentAffairsClick = () => {
    navigate("/add-current-affairs");
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
  };
  useEffect(() => {
    GetcurrentAffairs();
  }, []);

  const GetcurrentAffairs = () => {
    setLoading(true);
    dispatch(getCurrentAffairs())
      .unwrap()
      .then((data) => {
        const allData = data?.currentAffairs?.data.flatMap(
          (el) => el.currentAffairsData
        );
        setData(allData);
        setTotaldata(data?.currentAffairs?.totalDataCount);
        setPageData(allData.slice(0, itemsPerPage));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Error fetching current affairs", err);
      });
  };

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPageData(data?.slice(start, end));
  }, [currentPage, itemsPerPage]);

  const handleDeleteClick = (elem) => {
    setShowModal(true);
    setDeleteId(elem._id);
  };

  const handleDelete = () => {
    dispatch(DeleteCurrentAffairs(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.currentAffairs.message);
        GetcurrentAffairs();
        setShowModal(false);
        setCurrentPage(1);
      })
      .catch((errr) => {});
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
      <div>
        <React.Fragment>
          <div className="page-content">
            <Container fluid={true}>
              <div
                class="text-start my-2 ms-1"
                style={{
                  fontWeight: "600",
                }}
              >
                <Link to="/dashboard">Home</Link>&nbsp;&#8811; Current Affairs
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title-wrap bar-success d-flex align-items-center">
                    <h5 class="card-title">Current Affairs</h5>
                    &nbsp;
                    <button
                      className="btn btn-primary btn-sm ms-2"
                      onClick={handleAddCurrentAffairsClick}
                    >
                      Add Current Affairs
                    </button>
                  </div>
                </div>

                <div class="card-body collapse show">
                  <div class="card-block card-dashboard table-responsive">
                    {loading ? (
                      <Col lg={12} xs={12} md={12} align="center">
                        {ShowSkeleton()}
                      </Col>
                    ) : !data?.length ? (
                      <div className="mt-4 text-center card-body collapse show pb-3">
                        <h5>No current affairs were added yet. </h5>
                      </div>
                    ) : (
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead>
                          <tr>
                            {teamHeaders.map((header, index) => (
                              <th
                                className="text-center align-middle"
                                key={index}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {pagData?.map((elem, index) => (
                            <tr key={index}>
                              <td className="text-center align-middle">
                                {elem.monthName.charAt(0).toUpperCase() +
                                  elem.monthName.slice(1)}
                              </td>
                              <td className="text-center align-middle">
                                {Moment.utc(elem.date).format("DD-MM-YYYY")}
                              </td>

                              <td className="text-center align-middle">
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  onClick={() => handleEditClick(elem)}
                                >
                                  <MdEdit className="viewbtn" />
                                </button>

                                <button
                                  className="btn btn-outline-danger btn-sm ms-2"
                                  onClick={() => handleDeleteClick(elem)}
                                >
                                  <MdDelete className="viewbtn" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                {data?.length / itemsPerPage > 1 && (
                  <div className="mt-5 d-flex justify-content-end align-right">
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(data?.length / itemsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                    />
                  </div>
                )}
              </div>
            </Container>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
export default CurrentAffairs;
