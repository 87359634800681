import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.services";
import CourseService from '../../services/course.service';


export const AddCourses = createAsyncThunk(
    "courses/post/courses",
    async(item, thunkAPI) => {
        try {
            const data = await CourseService.postCourses(item);
            
            return { user: data?.data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const editCourse = createAsyncThunk(
  "courses/edit",
  async(item, thunkAPI) => {
      try {
          const data = await CourseService.editCourse(item);
          return { user: data?.data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);


export const getCourses = createAsyncThunk(
  "courses/get",
  async ( thunkAPI) => {
    try {
      const data = await CourseService.getcourses();
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const deleteCourses = createAsyncThunk(
  "courses/delete",
  async ( item,thunkAPI) => {
    try {
      const data = await CourseService.deleteCourses(item);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getCourseDetails = createAsyncThunk(
    "/get/enrolledCourse",
    async (id, thunkAPI) => {
      try {
        const data = await CourseService.getCourseDetails(id);
        return { user: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  //for adding categories
  


  export const AddCategory = createAsyncThunk(
    "category/post",
    async(item, thunkAPI) => {
        try {
            const data = await CourseService.postCategories(item);
            return { user: data?.data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const editCategory = createAsyncThunk(
  "category/edit",
  async(item, thunkAPI) => {
      try {
          const data = await CourseService.editCategories(item);
          return { user: data?.data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);


export const Getcategory = createAsyncThunk(
  "categories/get",
  async ( item,thunkAPI) => {
    try {
      const data = await CourseService.getcategories(item);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const DeleteCategory = createAsyncThunk(
  "category/delete",
  async ( item,thunkAPI) => {
    try {
      const data = await CourseService.deleteCategories(item);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const GetCategoryDetails = createAsyncThunk(
    "/get/categorydetails",
    async (id, thunkAPI) => {
      try {
        const data = await CourseService.getCategoryDetails(id);
        return { user: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  

//subjects

  export const AddSubjects = createAsyncThunk(
    "subjects/post",
    async(item, thunkAPI) => {
        try {
            const data = await CourseService.postSubjects(item);
            return { user: data?.data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const  EditSubjects1 = createAsyncThunk(
  "subjects/edit",
  async(item, thunkAPI) => {
      try {
          const data = await CourseService.editSubject(item);
          return { user: data?.data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);


export const getSubjects = createAsyncThunk(
  "subjects/get",
  async ( item,thunkAPI) => {
    try {
      const data = await CourseService.getSubjects(item);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const DeleteSubject = createAsyncThunk(
  "subjects/delete",
  async ( item,thunkAPI) => {
    try {
      const data = await CourseService.deleteSubjects(item);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const GetSubjectDetails = createAsyncThunk(
    "/get/subjectDetails",
    async (id, thunkAPI) => {
      try {
        const data = await CourseService.getSubjectDetails(id);
        return { user: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  

  
//units 

export const AddUnit = createAsyncThunk(
  "units/post",
  async(item, thunkAPI) => {
      try {
          const data = await CourseService.postUnits(item);
          return { user: data?.data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);

export const Editunits = createAsyncThunk(
"units/edit",
async(item, thunkAPI) => {
    try {
        const data = await CourseService.editUnit(item);
        return { user: data?.data };
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue({ message });
    }
}
);


export const GetUnits = createAsyncThunk(
"units/get",
async ( item,thunkAPI) => {
  try {
    const data = await CourseService.getunits(item);
    return { user: data?.data };
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue({ message });
  }
}
);


export const DeleteUnit = createAsyncThunk(
"unit/delete",
async ( id,thunkAPI) => {
  try {
    const data = await CourseService.deleteUnits(id);
    return { user: data?.data };
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue({ message });
  }
}
);

export const GetUnitDetails = createAsyncThunk(
  "/get/unitDetails",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getunitDetails(id);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


//combinations 

export const AddCombinations = createAsyncThunk(
  "combinations/post",
  async(item, thunkAPI) => {
      try {
          const data = await CourseService.postCombinations(item);
          return { user: data?.data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);

export const EditCombinations = createAsyncThunk(
"combinations/edit",
async(item, thunkAPI) => {
    try {
        const data = await CourseService.editCombinations(item);
        return { user: data?.data };
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue({ message });
    }
}
);


export const GetCombinations = createAsyncThunk(
"combinations/get",
async ( item,thunkAPI) => {
  try {
    const data = await CourseService.getcombinations(item);
    return { user: data?.data };
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue({ message });
  }
}
);

export const GetCombinated = createAsyncThunk(
  "combinations/get",
  async ( item,thunkAPI) => {
    try {
      const data = await CourseService.getcombinated(item);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
  );

export const DeleteCombinations = createAsyncThunk(
"combinations/delete",
async ( item,thunkAPI) => {
  try {
    const data = await CourseService.deleteCombinations(item);
    return { user: data?.data };
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue({ message });
  }
}
);

export const GetCombinationsDetails = createAsyncThunk(
  "/get/Combinationsdetails",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getcominationDetails(id);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


//chapters 

export const AddChapters = createAsyncThunk(
  "chapters/post",
  async(item, thunkAPI) => {
      try {
          const data = await CourseService.postChapters(item);
          return { user: data?.data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);

export const EditChapters = createAsyncThunk(
"chapters/edit",
async(item, thunkAPI) => {
    try {
        const data = await CourseService.editChapters(item);
        return { user: data?.data };
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue({ message });
    }
}
);


export const GetChapters = createAsyncThunk(
"chapters/get",
async ( item,thunkAPI) => {
  try {
    const data = await CourseService.getChapters(item);
    return { user: data?.data };
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue({ message });
  }
}
);


export const DeleteChapters = createAsyncThunk(
"chapters/delete",
async ( item,thunkAPI) => {
  try {
    const data = await CourseService.deleteChapters(item);
    return { user: data?.data };
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue({ message });
  }
}
);

export const GetChapterDetails = createAsyncThunk(
  "/get/chapterDetails",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getChaptersDetails(id);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


//for materials

export const postMaterial = createAsyncThunk(
  "/post/material",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.postmaterial(item);
      return { material: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const editMaterial = createAsyncThunk(
  "/edit/fundamentalmaterial",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.editmaterial(item);
      return { material: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getMaterials = createAsyncThunk(
  "/get/materials",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getmaterial(id);
      return { material: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);



export const getsingleMaterials = createAsyncThunk(
  "/get/singlematerials",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.getsinglematerial(item);
      return { material: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const deleteMaterial = createAsyncThunk(
  "/delete/material",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.deletematerial(id);
      return { material: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


// pretest
export const getPretest = createAsyncThunk(
  "/get/pretest",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getPretest(id);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getPretestByUnitId = createAsyncThunk(
  "/get/units/pretest",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getPretestByUnitId(id);
      return {pretest:data?.data};
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const editPretest = createAsyncThunk(
  "/edit/fundamentalpretest",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.editPretest(item);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const postAddPretest = createAsyncThunk(
  "/post/addfundamentalpretest",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.postAddPretest (item);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


 //for fundamental finaltest
 export const postFinaltest = createAsyncThunk(
  "/post/finaltest",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.postfinaltests(item);
      return { finaltest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const deletePretest = createAsyncThunk(
  "/delete/pretest",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.deletePretest(id);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const editFinaltest = createAsyncThunk(
  "/edit/finaltest",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.editfinaltest(item);
      return { finaltest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getFinaltest = createAsyncThunk(
  "/get/finaltests",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getfinaltest(id);
      return { finaltest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);



export const getsingleFinalTest = createAsyncThunk(
  "/get/singlefinaltest",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.getsinglefinaltests(item);
      return { finaltest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const deleteFinaltests = createAsyncThunk(
  "/delete/finaltest",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.deletefinaltest(id);
      return { finaltest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getUnitPretest  = createAsyncThunk(
  "/get/pretests",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getUnitPretest(id);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const postPretest = createAsyncThunk(
  "/post/pretest",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.postPretest(item);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const deleteUnitPretest = createAsyncThunk(
  "/delete/pretest",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.deleteUnitPretest(id);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const editUnitPretest = createAsyncThunk(
  "/edit/pretest",
  async (item, thunkAPI) => {
    try {
      const data = await CourseService.editUnitPretest(item);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const getSingleUnitPretest = createAsyncThunk(
  "/get/pretest",
  async (id, thunkAPI) => {
    try {
      const data = await CourseService.getSingleUnitPretest(id);
      return { pretest: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getSeries = createAsyncThunk(
  "series/get",
  async ( item,thunkAPI) => {
    try {
      const data = await CourseService.getSeries(item);
      return { user: data?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
  );
  export const addSeries = createAsyncThunk(
    "series/post",
    async(item, thunkAPI) => {
        try {
            const data = await CourseService.addSeries(item);
            return { user: data?.data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );
  export const deleteSeries = createAsyncThunk(
    "series/delete",
    async ( item,thunkAPI) => {
      try {
        const data = await CourseService.deleteSeries(item);
        return { user: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
    );

    export const editSeries = createAsyncThunk(
      "series/edit",
      async(item, thunkAPI) => {
          try {
              const data = await CourseService.editSeries(item);
              return { user: data?.data };
          } catch (error) {
              const message =
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();
              return thunkAPI.rejectWithValue({ message });
          }
      }
      );

      export const getSeriesDetails = createAsyncThunk(
        "/get/seriesDetails",
        async (item,thunkAPI) => {
          try {
            const {id,id1}=item;
            const data = await CourseService.getSeriesDetails(id,id1);
            return { user: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          }
        }
      );

      export const getSeriesTestDetails= createAsyncThunk(
        "testseries/get",
        async (id, thunkAPI) => {
          try {
            const data = await CourseService.getSeriesTestDetails(id);
            return { series: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          }
        }

      );

      export const getSeriesTest= createAsyncThunk(
        "testseries/get",
        async (item, thunkAPI) => {
          try {
            const data = await CourseService.getSeriesTest(item);
            return { series: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          }
        }
      );
      export const postSeriesTest = createAsyncThunk(
        "/post/addseriestest",
        async (item, thunkAPI) => {
          try {
            const data = await CourseService.postSeriesTest(item);
            console.log(data)
            return { series: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          }
        }
      );

      export const editSeriesTest = createAsyncThunk(
        "/edit/editseriestest",
        async (item, thunkAPI) => {
          try {
            const data = await CourseService.editSeriesTest(item);
            return {series: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          }
        }
      );

      //addmoretest series questions
      
      export const AddMoreSeriesTestQuestions = createAsyncThunk(
        "/add/addmoreseriestest",
        async (item, thunkAPI) => {
          try {
            const data = await CourseService.addMoreSeriesTest(item);
            return {series: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          }
        }
      );

      export const deleteSeriesTest = createAsyncThunk(
        "/delete/testseries",
        async (id, thunkAPI) => {
          try {
            const data = await CourseService.deleteSeriesTest(id);
            return { series: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          }
        }
      );

// export const logout = createAsyncThunk("auth/logout", async() => {
//     CourseService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const coursesSlice = createSlice({
    name: "course",
    initialState,
    extraReducers: {
        [AddCourses.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [AddCourses.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [AddCourses.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        [editCourse.pending]: (state) => {
          state.loading = true;
          state.error = "";
          state.isLoggedIn = false;
          state.user = null;
      },
      [editCourse.fulfilled]: (state, action) => {
          state.loading = false;
          state.error = "";
          state.isLoggedIn = true;
          state.user = action.payload.user;
      },
      [editCourse.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload.message;
          state.isLoggedIn = false;
          state.user = null;
      },
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = coursesSlice;
export default reducer;