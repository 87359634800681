import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logout } from "../../utils/helpers/Logout";
import { GetTickets } from "../../store/slices/tickets";
import { EditTickets } from "../../store/slices/tickets";

function Edittickets() {
  const { id } = useParams();
  const [buttonDisabled, setButtonDisabled] = useState();
  const dispatch = useDispatch();
  const [resolution,setResoltion] = useState()
  const [status, setStatus] = useState();
  const navigate = useNavigate("");
  const [resolutionError, setResoltionError] = useState();
  const [statusError, setStatusError] = useState();

  useEffect(() => {
    let item ={
        id:id
    }
    dispatch(GetTickets(item))
      .unwrap()
      .then((data) => {
        setResoltion(data?.tickets?.data[0]?.resolution);
        setStatus(data?.tickets?.data[0]?.status);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  console.log(resolution,status)

  const save = (e) => {
    if (!resolution) {
      setResoltionError("Please enter  resolution");
    }
    if (status === "") {
      setStatusError("Please choose status");
    }
    if (!resolution || status ==="") {
      return;
    } else {
      setResoltionError("");
      setStatusError("")
      editTickes(e);
    }
  };


  async function editTickes(e) {
    e.preventDefault();
    setButtonDisabled((prevState) => !prevState);
    setTimeout(() => {
      setButtonDisabled((prevState) => !prevState);
    }, 4000);
 
    let item = {
      id: id,
      status:parseInt(status),
      resolution,
    };

    dispatch(EditTickets(item))
      .unwrap()
      .then(() => {
        alert("ticket updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
        setButtonDisabled(false);
      });
  }

  const resolutionChange = (value) => {
   
    setResoltion(value );
    if (!value) {
      setResoltionError("Please enter valid resolution");
    } else {
      setResoltionError("");
    }  
  };


  const statusChange = (value) => {
   
    setStatus(value );
    if (!value) {
      setStatusError("Please choose Status");
    } else {
        setStatusError("");
    }  
  };

  const cancelClick = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Tickets</Link>
              &nbsp;&#8811; Edit Tickets
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Tickets</h4>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Resolution</span>
                      <input
                        type="text"
                        placeholder="Enter  resolution"
                        className="form-control"
                        value={resolution}
                        onChange={(e) => resolutionChange(e.target.value)}
                      />
                      <p className="alert-message">{resolutionError}</p>
                    </div>

                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Status</span>
                        <select
                          name="status"
                          className="form-control"
                          value={status}
                          onChange={(e)=>statusChange(e.target.value)}
                        >
                      <option value="">Select</option>
                      <option value={0}>Not Resolved</option>
                      <option value={1}> Resolved</option>

                          
                        </select>
                      <p className="alert-message">{statusError}</p>
                    </div>
                  </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={cancelClick}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2 wd-100"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Edittickets;
