import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import {
  editUnitPretest,
  getSingleUnitPretest,
} from "../../../../store/slices/courses";
import { Logout } from "../../../../utils/helpers/Logout";

const EditPretest = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [testId, setTestId] = useState();
  const [isPretestMandatory, setisPretestMandatory] = useState(true);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  const initialQuestion = {
    type: "text",
    question: "",
    order: "",
    explanation: "",
    correctOption: "",
    options: Array.from({ length: 6 }, () => ({
      optionNumber: "",
      optionValue: "",
      correctOption: false,
    })),
  };
  const [questions, setQuestions] = useState(
    [...Array(5)].map(() => ({
      type: "text",
      question: "",
      correctOption: "",
      order: "",
      explanation: "",
      options: [
        { optionNumber: "1", optionValue: "", correctOption: false },
        { optionNumber: "2", optionValue: "", correctOption: false },
        { optionNumber: "3", optionValue: "", correctOption: false },
        { optionNumber: "4", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
      ],
    }))
  );

  const [errors, setErrors] = useState(
    [...Array(5)].map(() => ({
      question: "",
      questionNumber: "",
      explanation: "",
      correctOption: "",
      options: [
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
      ],
    }))
  );
  const [data, setData] = useState({
    id: "",
    unitId: id,
    questions: questions,
  });

  useEffect(() => {
    dispatch(getSingleUnitPretest(id))
      .unwrap()
      .then((data1) => {
        const userData = data1?.pretest?.data[0];

        if (userData) {
          const { _id, questions } = userData;

          const updatedQuestions = questions.map((question, index) => {
            const backendQuestion = questions[index];

            if (backendQuestion) {
              const paddedOptions = Array.from({ length: 6 }, (_, oIndex) => ({
                ...(backendQuestion.options[oIndex] || {
                  optionNumber: "",
                  optionValue: "",
                  correctOption: false,
                }),
              }));

              return {
                ...initialQuestion,
                ...backendQuestion,
                options: paddedOptions,
              };
            } else {
              return { ...initialQuestion };
            }
          });

          setDatafunc(_id, updatedQuestions);
          setQuestions(updatedQuestions);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  function setDatafunc(_id, questions) {
    setData({
      ...data,
      id: _id,

      questions: questions,
    });
  }

  const handleQuestionChange = (e, questionIndex) => {
    const { name, value } = e.target;
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];

      if (questionIndex >= 0 && questionIndex < updatedQuestions.length) {
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          [name]: value,
        };
      }

      return updatedQuestions;
    });

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];

      if (questionIndex >= 0 && questionIndex < updatedErrors.length) {
        updatedErrors[questionIndex][name] =
          name === "explanation" && value === ""
            ? ""
            : value === ""
            ? "Please enter question"
            : "";
      }

      return updatedErrors;
    });
  };

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const { name, value, checked } = e.target;

    if (name === "optionNumber" && value !== "" && (value < 1 || value > 6)) {
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] =
          "Option number must be between 1 and 6.";
        return updatedErrors;
      });
      return;
    }

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      const currentQuestion = updatedQuestions[questionIndex];

      if (!currentQuestion) {
        console.error(`Question at index ${questionIndex} is undefined.`);
        return prevQuestions;
      }

      const updatedOptions = currentQuestion.options.map((option, index) => {
        if (index === optionIndex) {
          return {
            ...option,
            [name]: name === "correctOption" ? checked : value,
          };
        }
        return option;
      });

      if (name === "correctOption") {
        currentQuestion.correctOption = checked ? optionIndex + 1 : null;
        updatedOptions.forEach((option, index) => {
          if (option) {
            option.correctOption = index === optionIndex && checked;
          }
        });
      }

      updatedQuestions[questionIndex] = {
        ...currentQuestion,
        options: updatedOptions,
      };

      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] = "";

        if (name === "correctOption" && checked) {
          updatedErrors[questionIndex].options.forEach((option) => {
            option.validationMessage = "";
          });
        }

        return updatedErrors;
      });

      return updatedQuestions;
    });
  };

  const validateOptions = () => {
    const updatedErrors = [...errors];
    questions.forEach((question, qIndex) => {
      const optionsError = { ...updatedErrors[qIndex]?.options };

      if (!optionsError.hasOwnProperty("options")) {
        optionsError.options = [];
      }

      const firstTwoOptions = question.options.slice(0, 2);

      const validOptions = firstTwoOptions.filter(
        (option) => option.optionValue.trim() !== ""
      );

      if (validOptions.length < 2) {
        optionsError.validationMessage = "At least two options are required";
      } else {
        optionsError.validationMessage = "";
      }

      updatedErrors[qIndex].options = optionsError;
    });

    setErrors(updatedErrors);
  };

  const handleAddMainForms = () => {
    const lastQuestionNumber =
      questions.length > 0 ? questions[questions.length - 1].questionNumber : 0;
    const newQuestionNumber = lastQuestionNumber + 1;
    const updatedQuestions = [
      ...questions,
      {
        type: "text",
        question: "",
        correctOption: "",
        explanation: "",
        questionNumber: "",
        options: [
          { optionNumber: "1", optionValue: "", isCorrect: false },
          { optionNumber: "2", optionValue: "", isCorrect: false },
          { optionNumber: "3", optionValue: "", isCorrect: false },
          { optionNumber: "4", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
        ],
      },
    ];
    const updatedErrors = [
      ...errors,
      {
        question: "",
        correctOption: "",
        questionNumber: "",
        explanation: "",
        options: [
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
        ],
      },
    ];
    setQuestions(updatedQuestions);
    setErrors(updatedErrors);
  };

  const handleDeleteMainForms = () => {
    if (questions.length > 1) {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions.pop();
        const updatedErrors = [...errors];
        updatedErrors.pop();
        setErrors(updatedErrors);
        return updatedQuestions;
      });
    }
  };
  const handleSwitchChange = (event) => {
    setisPretestMandatory(event.target.checked);
  };

  const save = (e) => {
    e.preventDefault();

    const filledQuestions = questions.filter(
      (question) => question?.question?.trim() !== ""
    );

    if (filledQuestions.length === 0) {
      alert("Please fill at least one question");
      return;
    }

    const updatedErrors = filledQuestions.map((question, qIndex) => {
      const isAnyCheckboxSelected = question.correctOption !== "";
      const isValidOrder = question.questionNumber !== "";

      const isValidOption =
        question.options.length >= 2 &&
        question.options.filter(
          (option) => option.optionNumber !== "" && option.optionValue !== ""
        ).length >= 2;

      return {
        question: question.question !== "" ? "" : "Please enter question",
        questionNumber: isValidOrder ? "" : "Please enter questionNumber",

        options: question.options.map((option, oIndex) => ({
          optionNumber:
            isValidOption || option.optionNumber !== ""
              ? ""
              : "Please enter option number",
          optionValue:
            isValidOption || option.optionValue !== ""
              ? ""
              : "Please enter option value",
          validationMessage: isAnyCheckboxSelected
            ? ""
            : "please select correct option",
        })),
      };
    });

    setErrors(updatedErrors);

    const hasErrors = updatedErrors.some(
      (error) =>
        error.question !== "" ||
        error.options.some(
          (opt) =>
            opt.optionNumber !== "" ||
            opt.optionValue !== "" ||
            opt.validationMessage
        )
    );

    if (hasErrors) {
      alert("Please fill in all required fields correctly");
      return;
    } else {
      const validQuestions = questions.filter(
        (question) => question.question !== ""
      );
      const item = validQuestions.map((question) => {
        const validOptions = question.options.filter(
          (option) => option.optionNumber !== "" && option.optionValue !== ""
        );

        return {
          question: question.question,
          questionNumber: question.questionNumber,
          explanation: question.explanation || undefined,
          correctOption: question.correctOption,
          options: validOptions, // Use only the filled options
        };
      });

      console.log({ dataSam: data });
      const qitem = {
        unitId: id,
        id: data.id,
        questions: item,
      };

      dispatch(editUnitPretest(qitem))
        .unwrap()
        .then((res) => {
          console.log(res);
          if (res?.pretest?.message) {
            alert(res?.pretest?.message);
            navigate(-1);
          }
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const clear = () => {
    setQuestions([
      {
        type: "text",
        question: "",
        correctOption: "",
        questionNumber: "",
        explanation: "",
        options: [
          { optionNumber: "1", optionValue: "", isCorrect: false },
          { optionNumber: "2", optionValue: "", isCorrect: false },
          { optionNumber: "3", optionValue: "", isCorrect: false },
          { optionNumber: "4", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
        ],
      },
    ]);
    setErrors([
      {
        type: "text",
        question: "",
        correctOption: "",
        questionNumber: "",
        explanation: "",
        options: [
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
        ],
      },
    ]);
    setShowModal(true);
  };

  const courseid = localStorage.getItem("courseId");
  const categoryid = localStorage.getItem("categoryId");
  const combinationid = localStorage.getItem("combinationId");
  const combinationid1 = localStorage.getItem("combinationId1");
  const unitId = localStorage.getItem("unitId");
  const cancelHandler = () => {
    navigate(`/pretest/${id}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div
            class="text-start my-2 ms-1"
            style={{
              fontWeight: "600",
            }}
          >
            <Link to="/dashboard">Home</Link>&nbsp;&#8811;
            <Link to={`/courses/${categoryid}`}>Courses</Link>
            &nbsp;&#8811;&nbsp;
            <Link to={`/view-combinations/${combinationid1}`}>
              Combinations
            </Link>
            &nbsp;&#8811;&nbsp;
            <Link to={`/viewchapters/${combinationid}`}>Chapters</Link>
            &nbsp;&#8811;&nbsp;
            <Link to={`/units/${unitId}`}>Units</Link>
            &nbsp;&#8811;&nbsp;
            <Link to={`/pretest/${id}`}>Pretest</Link>
            &#8811; Edit Question
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                <h4 class="card-title">Edit Question</h4>
                &nbsp;
                <div className="container-buttons1">
                  <div className="col-12 d-flex justify-content-end mt-1">
                    <button
                      className="btn btn-info btn-sm"
                      style={{ height: "30px", width: "60px" }}
                      onClick={(e) => handleAddMainForms(e)}
                    >
                      <CiCirclePlus style={{ fontSize: "20px" }} />
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      style={{ height: "30px", width: "50px" }}
                      onClick={(e) => handleDeleteMainForms(e)}
                    >
                      <CiCircleMinus style={{ fontSize: "20px" }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <section className="form-Questionnaire p-4">
              <Form.Group
                controlId="formBasicSwitch"
                style={{ marginBottom: "2%", marginTop: "-30px" }}
              >
                <div className="d-flex justify-content-start  mt-4">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="bread-head1"
                    label="Pretest Mandatory"
                    checked={isPretestMandatory}
                    onChange={handleSwitchChange}
                  />
                </div>
              </Form.Group>
              {questions.map((question, qindex) => (
                <form key={qindex}>
                  <span className="bread-head1 mt-3">
                    Question No - {qindex + 1}
                  </span>
                  <div className="row">
                    <div className="col-md-11 col-sm-12 pt-2">
                      <div className="question-explanation-section">
                        <textarea
                          className="form-control"
                          name="question"
                          placeholder="Enter your question"
                          onChange={(e) => handleQuestionChange(e, qindex)}
                          value={question.question}
                        />
                      </div>
                      <p className="alert-message">
                        {errors[qindex]?.question}
                      </p>
                    </div>
                    <div className="col-md-11 col-sm-12 pt-2">
                      <div className="question-explanation-section">
                        <span className="bread-head1 mt-3">Explanation</span>
                        <textarea
                          className="form-control"
                          name="explanation"
                          placeholder="Enter explanation"
                          onChange={(e) => handleQuestionChange(e, qindex)}
                          value={question.explanation}
                        />
                      </div>
                      <p className="alert-message">
                        {errors[qindex]?.explanation}
                      </p>
                    </div>

                    <div className=" d-flex col-md-4 col-sm-12 pt-2 pb-1 col-ml-5">
                      <p className="bread-head1 mt-2">Correct option :</p>
                      <span className="bread-head1  m-2">
                        {questions[qindex]?.correctOption}
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <span className="bread-head1">Options</span>
                      </div>
                    </div>
                    <div style={{ paddingBottom: "30px" }}>
                      <div className="row option-section ">
                        {question.options.map((option, optionIndex) => (
                          <div className="col-12" key={optionIndex}>
                            <div className="row">
                              <div
                                style={{ height: "100%" }}
                                className="col-md-2 col-sm-12 pt-2 pb-1"
                              >
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={`Option No - ${optionIndex + 1}`}
                                  value={option.optionNumber}
                                  onChange={(e) =>
                                    handleOptionChange(e, qindex, optionIndex)
                                  }
                                  name="optionNumber"
                                />
                                <p className="alert-message">
                                  {optionIndex < 2
                                    ? errors[qindex]?.options[optionIndex]
                                        ?.optionNumber
                                    : ""}
                                </p>
                              </div>
                              <div
                                style={{ height: "100%" }}
                                className="col-md-6 col-sm-12 pt-2 pb-1"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={`Enter option ${
                                    optionIndex + 1
                                  }`}
                                  onChange={(e) =>
                                    handleOptionChange(e, qindex, optionIndex)
                                  }
                                  value={option.optionValue}
                                  name="optionValue"
                                  required
                                />
                                <p className="alert-message">
                                  {optionIndex < 2
                                    ? errors[qindex]?.options[optionIndex]
                                        ?.optionValue
                                    : ""}
                                </p>
                              </div>

                              <div
                                style={{ height: "100%" }}
                                className="col-md-2 col-sm-12 pt-2 pb-1"
                              >
                                <input
                                  type="checkbox"
                                  label=""
                                  checked={
                                    question.correctOption !== "" &&
                                    question.correctOption == optionIndex + 1
                                  }
                                  onChange={(e) =>
                                    handleOptionChange(e, qindex, optionIndex)
                                  }
                                  name="correctOption"
                                  id={`correctOption-${qindex}-${optionIndex}`}
                                  disabled={
                                    !option.optionNumber || !option.optionValue
                                  }
                                />
                                <p className="alert-message">
                                  {optionIndex < 2
                                    ? errors[qindex]?.options[optionIndex]
                                        ?.validationMessage
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </form>
              ))}
            </section>
            <div className="container-buttons mb-5 me-3">
              <div className="col-12 d-flex justify-content-end p-2">
                <button
                  type="reset"
                  className="btn btn-outline-danger"
                  onClick={cancelHandler}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-info ms-2"
                  disabled={buttonDisabled}
                  onClick={save}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditPretest;
