import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Col, Table, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";
import { DeleteAlerts, GetAlerts } from "../../store/slices/alert";
import { getCourses } from "../../store/slices/courses";

function GetAllAlerts() {
  const teamHeaders = ["Course Name", "Title", "Details", "Action"];
  const [alerts, setAlerts] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState("");
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    GetCourses();
  }, []);

  const GetCourses = () => {
    dispatch(getCourses())
      .unwrap()
      .then((data) => {
        setCourses(data?.user?.data);
        console.log(data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const navigate = useNavigate();

  const handleaddAlerts = () => {
    navigate("/alerts/add");
  };

  const HandleEdit = (id2) => {
    navigate(`/alerts/edit/${id2}`);
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };
  useEffect(() => {
    getalerts();
  }, [dispatch, itemsPerPage, currentPage, courseId]);

  const getalerts = () => {
    const item = {
      limit: itemsPerPage,
      page: currentPage,
      courseId: courseId || undefined,
    };
    setLoading(true);
    dispatch(GetAlerts(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setAlerts(data?.alerts?.data);
        setTotaldata(data?.alerts?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const HandleDelete = () => {
    const item = {
      id: deleteId,
    };

    console.log(item);
    dispatch(DeleteAlerts(item))
      .unwrap()
      .then((data) => {
        alert(data?.alerts?.message);
        setShowModal(false);
        getalerts();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleCourseChange = (e) => {
    setCourseId(e.target.value);
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={HandleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>
              &nbsp;&#8811; Alerts
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Alerts</h4>
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={handleaddAlerts}
                  >
                    Add Alerts
                  </button>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="text-end mb-5">
                    <Col md={4} className="ms-auto">
                      <p className="text-start">Select Course</p>
                      <select
                        name="upgradePlan"
                        className="form-control"
                        value={courseId}
                        onChange={handleCourseChange}
                      >
                        <option value="">Select</option>

                        {courses.map((elem) => (
                          <option value={elem?._id}>{elem?.courseName}</option>
                        ))}
                      </select>
                    </Col>
                  </div>
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !alerts?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>No job alerts were found</h5>
                    </div>
                  ) : (
                    <>
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead>
                          <tr>
                            {teamHeaders.map((header, index) => (
                              <th
                                className="text-center align-middle"
                                key={index}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {alerts.map((alert, rowIndex) => (
                            <tr key={rowIndex}>
                              <td
                                className="text-center align-middle"
                                style={{ minWidth: "30%" }}
                              >
                                {alert?.title}
                              </td>
                              <td
                                className="text-center align-middle"
                                style={{ minWidth: "30%" }}
                              >
                                <img
                                  src={alert.icon}
                                  alt={` logo`}
                                  style={{
                                    maxWidth: "80px",
                                    maxHeight: "50px",
                                  }}
                                />
                              </td>

                              <td className="text-center align-middle">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      alert?.details
                                        ?.replace(
                                          '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
                                          ""
                                        )
                                        ?.substring(0, 50) + "...",
                                  }}
                                />
                              </td>

                              <td
                                className="text-center align-middle"
                                style={{ minWidth: "30%" }}
                              >
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  onClick={() => HandleEdit(alert._id)}
                                >
                                  <MdEdit className="viewbtn" />
                                </button>

                                <button
                                  className="btn btn-outline-danger btn-sm ms-2"
                                  onClick={() => handleDeleteClick(alert._id)}
                                >
                                  <MdDelete className="viewbtn" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </div>
              <div className="mr-5">
                {totaldata / itemsPerPage > 1 && (
                  <div className="mt-5 d-flex justify-content-end align-right">
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(totaldata / itemsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default GetAllAlerts;
