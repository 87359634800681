import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import { Button, Col, Table, Container } from "reactstrap";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import {
  getSeriesTest,
  deleteSeriesTest,
  getSeriesDetails,
} from "../../store/slices/courses";
import ReactPaginate from "react-paginate";
import { Logout } from "../../utils/helpers/Logout";

function Testseries() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [testseriesId, setTestSeriesId] = useState();
  const [totaldata, setTotaldata] = useState();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [breadData, setBreadData] = useState();
  const [series, setSeries] = useState();
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
    id: id,
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const navigate = useNavigate("");

  useEffect(() => {
    GetSeriesTest();
  }, [dispatch, itemsPerPage, currentPage]);

  const GetSeriesTest = () => {
    setLoading(true);
    dispatch(getSeriesTest(item))
      .unwrap()
      .then((data) => {
        if (data?.series?.data?.length) {
          setData(data?.series?.data[0]?.questions);
          setTotaldata(data?.series?.totalDataCount);
          setTestSeriesId(data?.series?.data[0]?._id);
        } else if (!data.series.data.length) setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {});
  };

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteSeriesTest(testseriesId))
      .unwrap()
      .then((data) => {
        console.log("Delete response:", data);
        alert(data.series.message);

        setShowModal(false);
        GetSeriesTest();
      })
      .catch((error) => {
        console.error("Error deleting series:", error);
        alert("Error deleting series. Please try again.");
      });
  };

  const handleAddSeriesClick = () => {
    navigate(`/addseriestest/${id}`);
  };
  const handleAddMoreSeriesClick = () => {
    navigate(`/addmoreseriestest/${id}`);
  };
  const handleEditClick = () => {
    navigate(`/editseriestest/${id}`);
  };
  const cancelHandler = () => {
    navigate(-1);
  };
  const combinationId = localStorage.getItem("combinationId");
  const seriesId = localStorage.getItem("seriesId");

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/series/${seriesId}`}>Series</Link>
              &nbsp;&#8811;&nbsp;Series Test
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <h4 class="card-title">Series Test</h4>
                    {!data?.length ? (
                      <button
                        className="btn btn-primary btn-sm ms-2"
                        onClick={handleAddSeriesClick}
                      >
                        Add Question
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-sm ms-2"
                        onClick={handleAddMoreSeriesClick}
                      >
                        Add More Questions
                      </button>
                    )}
                  </div>
                  {data?.length > 0 && (
                    <div>
                      <button
                        className="btn btn-outline-info btn-sm"
                        onClick={handleEditClick}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-outline-danger btn-sm ms-2"
                        onClick={handleDeleteClick}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No questions were added to this TestSeries.</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead className="responseHeaderStyle">
                        <tr>
                        <th className="text-center">Sr No</th>
                          <th className="text-center">Question</th>
                          <th className="text-center">Option</th>
                          <th className="text-center">Correct Option</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle pr-3">
                              {item.questionNumber}
                            </td>
                            <td className="text-center align-middle" style={{maxWidth:"500px"}} >
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.question.replace(
                                    '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
                                    ""
                                  ),
                                }}
                              />
                            </td>

                            <td className="text-center align-middle">
                              {item.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  style={{ marginBottom: "15px" }}
                                >
                                  {option.optionNumber}-&nbsp;
                                  {option.optionValue}
                                </div>
                              ))}
                            </td>
                            <td className="text-center align-middle">
                              {item.correctOption}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              

        <div className="mr-5">
          {totaldata / itemsPerPage > 1 && (
            <div className="mt-5 d-flex justify-content-end align-right">
              <ReactPaginate
                key={currentPage}
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakLinkClassName={"page-link"}
                pageCount={Math.ceil(totaldata / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={pagesToShowInitially}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                initialPage={currentPage - 1}
              />
            </div>
          )}
        </div>
        </div>
            </div>
          </Container>
          </div>

      </React.Fragment>
    </>
  );
}
export default Testseries;
