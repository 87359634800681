import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard




// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import UserProfile from "../Pages/Authentication/user-profile";


import Dashboard from "../Pages/Dashboard/Dashboard.jsx";
import Payment from "../Pages/Payment/Payment.js";
import PaidUsers from "../Pages/PaidUsers/PaidUsers.jsx";
import UnpaidUsers from "../Pages/UnpaidUsers/UnpaidUsers.jsx";
import Support from "../Pages/Support/Support.js";
import Visitors from "../Pages/Visitors/Visitors.js";
import ViewResponse from "../Pages/Visitors/View-Response/ViewResponse.js";
import Notification from "../Pages/Notification/Notification.js";
import Withdrawal from "../Pages/Withdrawal/Withdrawal.js";
import CurrentAffairs from "../Pages/CurrentAffairs/CurrentAffairs.jsx";
import Courses from "../Pages/Courses/Courses.jsx";
import Category from "../Pages/Categories/Category.jsx";
import AddCategoery from "../Pages/Categories/AddCategory.jsx";
import EditCategoery from "../Pages/Categories/EditCategory.jsx";
import EditCourses from "../Pages/Courses/EditCourses/EditCourses.jsx";
import AddCourse from "../Pages/Courses/AddCourse/AddCourse.jsx";
import CourseSubjects from "../Pages/CourseSubjects/CourseSubjects.jsx";
import AddSubject from "../Pages/CourseSubjects/AddSubject.jsx";
import EditSubjects from "../Pages/CourseSubjects/EditSubjects.jsx";

import AddCombination from "../Pages/Combinations/AddCombinations.jsx";
import EditCombination from "../Pages/Combinations/EditCombinations.jsx";
import Pretest from "../Pages/Units/Pretest/Pretest.jsx";
import AddPretest from "../Pages/Units/Pretest/Addpretest/AddPretest.jsx";
import EditPretest from "../Pages/Units/Pretest/Editpretest/EditPretest.jsx";
import FinalTests from "../Pages/Units/Finaltest/Finaltest.jsx";
import AddFinalTest from "../Pages/Units/Finaltest/AddFinaltest/AddFinaltest.jsx";
import EditFinalTest from "../Pages/Units/Finaltest/EditFinaltest/EditFinaltest.jsx";
import Material from "../Pages/Units/Material/Material.jsx";
import AddMaterial from "../Pages/Units/Material/AddMaterial.jsx";
import EditMaterial from "../Pages/Units/Material/EditMaterial.jsx";
import PreviewMaterial from "../Pages/Units/Material/MaterialDetails.jsx";
import AddCurrentAffairs from "../Pages/CurrentAffairs/AddCurrentAffairs/AddCurrentAffiars.jsx";
import EditcurentAffairs from "../Pages/CurrentAffairs/EditCurrentAffairs/EditCurrentAffairs.jsx";
import FundamentalSubjects from "../Pages/Fundamentals/Subjects/Subjects.jsx";
import AddFundamentalSubject from "../Pages/Fundamentals/Subjects/AddSubjects.jsx";
import EditFundamentalSubject from "../Pages/Fundamentals/Subjects/EditSubjects.jsx";
import EditFundamentalUnits from "../Pages/Fundamentals/FundamentalUnits/EditFundamentalUnits.jsx";
import FundamentalChapters from "../Pages/Fundamentals/FundamentalChapters/FundamentalChapters.jsx";
import FundamentalUnits from "../Pages/Fundamentals/FundamentalUnits/FundamentalUnits.jsx";
import AddFundamentalUnits from "../Pages/Fundamentals/FundamentalUnits/AddFundamentalUnits.jsx";
import FundamentalMaterial from "../Pages/Fundamentals/fundamentalMaterial/fundamentalMaterial.jsx";
import PreviewFundamentalMaterial from "../Pages/Fundamentals/fundamentalMaterial/PreveiwFundamentalMaterial.jsx";
import AddFundamentalMaterial from "../Pages/Fundamentals/fundamentalMaterial/AddFundamentalMaterial.jsx";
import EditFundamentalMaterial from "../Pages/Fundamentals/fundamentalMaterial/EditFundamentalMaterial.jsx";
import AddFundamentalChapter from "../Pages/Fundamentals/FundamentalChapters/AddChapters/AddFundamentalChapters.jsx";
import EditFundamentalChapters from "../Pages/Fundamentals/FundamentalChapters/EditChapters/EditFundamentalChapters.jsx";
import FundamentalPretest from "../Pages/Fundamentals/FundamentalPretest/FundamentalPretest.jsx";
import AddFundamentalPretest from "../Pages/Fundamentals/FundamentalPretest/AddFundamentalPretest/AddFundamentalPretest.jsx";
import EditFundamentalPretest from "../Pages/Fundamentals/FundamentalPretest/EditFundamentalPretest/EditFundamentalPretest.jsx";
import AddFundamentalFinalTest from "../Pages/Fundamentals/FundamentalFinalTests/AddFundamentalFinalTests.jsx";
import EditFundamentalFinalTest from "../Pages/Fundamentals/FundamentalFinalTests/EditFundamentalFinalTests.jsx";
import FundamentalFinalTests from "../Pages/Fundamentals/FundamentalFinalTests/FinalTests.jsx";
import Plans from "../Pages/Plans/Plans.jsx";
import EditPlans from "../Pages/Plans/EditPlan.jsx";
import AddPlans from "../Pages/Plans/AddPlans.jsx";
import Faq from "../Pages/Frequently/Faq.jsx";
import AddFaq from "../Pages/Frequently/Addfaq/Addfaq.jsx";
import EditFaq from "../Pages/Frequently/Editfaq/Editfaq.jsx";
import AddContent from "../Pages/Quiz/Content/AddContent.js";
import AddContentMcq from "../Pages/Quiz/Content/AddContentMcq.jsx";
import EditContent from "../Pages/Quiz/Content/EditContent.js";
import EditContentMcq from "../Pages/Quiz/Content/EditContentMcq.jsx";

import GetChallengesList from "../Pages/Challenges/getChallenges.js";
import ViewChallengeDetails from "../Pages/Challenges/ChallengeDetails.js";
import AddChallenge from "../Pages/Challenges/addChallenges.js";
import EditChallenge from "../Pages/Challenges/editChallenges.js";
import {UserDetails} from "../Pages/PaidUsers/UserDetails.jsx";
import AddUnits from "../Pages/Units/AddUnit.jsx";
import ViewChapters from "../Pages/ViewChapters/ViewChapters.jsx";
import AddChapter from "../Pages/ViewChapters/AddChapter.jsx";
import EditChapter from "../Pages/ViewChapters/EditChapter.jsx";
import Units from "../Pages/Units/Units.jsx";
import EditUnit from "../Pages/Units/EditUnit.jsx";
import TicketsList from "../Pages/Support/Ticketslist.jsx";
import GetIssues from "../Pages/Support/Issues/GetIssues.jsx";
import AddIssue from "../Pages/Support/AddIssues.jsx";
import Series from "../Pages/TestSeries/Series.jsx";
import AddSeries from "../Pages/TestSeries/AddSeries.jsx";
import EditSeries from "../Pages/TestSeries/EditSeries.jsx";
import AddSeriesTest from "../Pages/Series/Addseriestest.jsx";
import AddMoreSeriesTest from "../Pages/Series/AddMoreSeriesTest.jsx";
import EditSeriestest from "../Pages/Series/Editseriestest.jsx";
import Testseries from "../Pages/Series/Seriestest.jsx";
import Quiz from "../Pages/Quiz/Category/quiz.js";
import AddQuiz from "../Pages/Quiz/Category/addquiz.js";
import EditQuiz from "../Pages/Quiz/Category/editquiz.js";
import ContentQuiz from "../Pages/Quiz/Content/Contentquiz.js";
import Activity from "../Pages/Activity/Activity.jsx";
import Combinations from "../Pages/Combinations/GetCombinations.jsx";
import Userdetails1 from "../Pages/Activity/UserDetails.jsx";
import EditIssue from "../Pages/Support/EditIssues.jsx";
import AssignPlan from "../Pages/Plans/AssignPlans.jsx";
import Subjects from "../Pages/Subjects/Subjects.jsx";
import PendingPayments from "../Pages/Payment/PendingPayment.js";
import GetAlerts from "../Pages/Alerts/GetAlerts.jsx";
import AddAlerts from "../Pages/Alerts/AddAlerts.jsx";
import EditAlert from "../Pages/Alerts/EditAlert.jsx";
import GetAllTickets from "../Pages/Tickets/GetTickets.jsx";
import Edittickets from "../Pages/Tickets/EditTickets.jsx";
import UpgradePlan from "../Pages/Plans/UpgradePlan.jsx";
import TestSeriesLogs from "../Pages/PaidUsers/TestSeriesLogs.jsx";

const authProtectedRoutes =[ //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path:'/payments', component :<Payment/>},
  { path:'/pendingpayments', component :<PendingPayments/>},
      { path:'/paidusers', component :<PaidUsers key="paidusers"/>},
      { path:'/unpaidusers', component :<UnpaidUsers key="unpaidusers"/>},
      { path:'/userDetails/:type/:id', component :<UserDetails/>},

     
      { path:'/support', component :<Support key="support"/>},
      { path:'/userDetails/:id', component :<Userdetails1 />},
      { path:'/visitors', component :<Visitors key="visitors"/>},
      { path:'/subjects', component :<Subjects key="subjects"/>},
      { path:'/viewresponse', component :<ViewResponse key="viewresponse"/>},
      { path:'/notification', component :<Notification key="notification"/>},
      { path:'/withdrawal', component :<Withdrawal key="withdrawal"/>},
      { path:'/currentaffairs', component :<CurrentAffairs key="currentaffairs"/>},
      { path:'/addcurrentaffairs', component :<AddCurrentAffairs key="addcurrentaffairs"/>}, 
      { path:'/editcurrentaffairs', component :<EditcurentAffairs key="editcurrentaffairs"/>},
      { path:'/courses', component :<Courses />}, 
      { path:'/category', component :<Category />}, 
      { path:'/add-category', component :<AddCategoery />}, 
      { path:'/edit-category/:id', component :<EditCategoery />}, 
      { path:'/courses/edit/:id2', component :<EditCourses />}, 
      { path:'/addcourse', component :<AddCourse key="addcourse"/>}, 
      { path:'/coursesubjects', component :<CourseSubjects key="coursesubjects"/>}, 
      { path:'/addsubject', component :<AddSubject />},
      { path:'/editsubject/:id1', component :<EditSubjects/>},
      { path:'/viewchapters/:id1/:id2', component :<ViewChapters key="viewchapters"/>},
      { path:'/addchapters/:id', component :<AddChapter />},
      { path:'/editchapters/:id1/:id2', component :<EditChapter />},
      { path:'/units/:id', component :<Units />},
      { path:'/addunits/:id', component :<AddUnits/>},
      { path:'/editunits/:id1/:id', component :<EditUnit/>},
      { path:'/addchapter', component :<AddChapter key="addchapter"/>},
      { path:'/combination/:id', component :<Combinations/>},
      { path:"/addcombinations/:id", component :<AddCombination />},
      { path:"/editcombinations/:id1/:id2", component :<EditCombination />},
      { path:'/pretest/:id', component :<Pretest key="pretest"/>},
      { path:'/addpretest/:id', component :<AddPretest />},
      { path:'/editpretest/:id', component :<EditPretest />},
      { path:'/finaltest/:id', component :<FinalTests key="finaltest"/>},
      { path:'/add-finaltest/:id', component :<AddFinalTest key="addfinaltest"/>},
      { path:'/edit-finaltest/:id', component :<EditFinalTest key="editfinaltest"/>},
      { path:'/materials/:id', component :<Material key="material"/>},
      { path:'/test-series-logs/:id', component :<TestSeriesLogs />},
      { path:'/add-materials/:id', component :<AddMaterial key="addmaterial"/>},
      { path:'/editmaterial/:id1/:id2', component :<EditMaterial />},
      { path:'/previewmaterial/:id1/:id2', component :<PreviewMaterial key="materialdetails"/>},
      { path:'/editmaterial', component :<EditMaterial key="editmaterial"/>},
      { path:"/add-current-affairs", component :<AddCurrentAffairs  />},
      { path:"/edit-current-affairs/:id" , component :<EditcurentAffairs />},
      { path:"/currentaffairs", component :<CurrentAffairs />},
      { path:"/fundamentals/subjects", component :<FundamentalSubjects/>},
      { path:"/fundamentals/addsubjects", component :<AddFundamentalSubject/>},
      { path:"/fundamentals/subjects/edit/:id", component :<EditFundamentalSubject/>},
      { path:"/fundamentals/units/edit/:id1/:id2", component :<EditFundamentalUnits/>},
      { path:'/fundamentals/chapters/:id', component :<FundamentalChapters />},
      { path:'/fundamentals/units/:id', component :<FundamentalUnits/>},
      { path:'/fundamentals/addunits/:id', component :<AddFundamentalUnits/>},
      { path:'/fundamentals/materials/:id', component :<FundamentalMaterial />},
      { path:'/fundamentals/previewmaterial/:id1/:id2', component :<PreviewFundamentalMaterial />},
      { path:'/fundamentals/materials/add/:id', component :<AddFundamentalMaterial />},
      { path:"/fundamentals/editmaterial/:id1/:id2", component :<EditFundamentalMaterial/>},
      { path:'/fundamentals/chapters/:id', component :<FundamentalChapters key="fundamentalchapters"/>},
      { path:'/fundamentals/units', component :<FundamentalUnits key="fundamentalchapters"/>},
      { path:'/fundamentals/addunits', component :<AddFundamentalUnits/>},
      { path:'/fundamentals/add-chapter/:id', component :<AddFundamentalChapter />},
      { path:'/fundamentals/edit-chapter/:subjectId/:chapterId', component :<EditFundamentalChapters />},
      { path:'/fundamentals/pretest/:id', component :<FundamentalPretest/>},
      { path:'/addfundamental/pretest/:unitId', component :<AddFundamentalPretest/>},
      { path:'/editfundamentalpretest/:unitId', component :<EditFundamentalPretest/>},
      { path:'/fundamentals/chapters/:id', component :<FundamentalChapters />},
      { path:'/fundamentals/units/:id', component :<FundamentalUnits />},
     { path:"/fundamentals/finaltest/:id", component :<FundamentalFinalTests/>},
     { path:"/fundamentals/add-questions/:id", component :<AddFundamentalFinalTest />},
     { path:"/fundamentals/edit-questions/:id", component :<EditFundamentalFinalTest/>},
     { path:'/plans', component :<Plans/>},
     { path:'/edit-plans/:id', component :<EditPlans/>},
     { path:'/add-plans', component :<AddPlans />},
     { path:'/faq', component :<Faq/>},
    //  { path:'/tickets', component :<TicketsList/>},
     { path:'/issues/:name/:id', component :<GetIssues />},
     { path:'/addfaq', component :<AddFaq/>},
     { path:'/editfaq/:id', component :<EditFaq/>},
     { path:'/issue', component :<AddIssue />},
     { path:'/issues-edit/:id', component :<EditIssue/>},
     { path:'/series/:id', component :<Series/>},
     { path: '/addseries/:id', component :<AddSeries/>},
     { path:'/editseries/:id/:id1', component : <EditSeries/>},
     { path:'/testseries/:id', component : <Testseries/>},
     { path:'/addseriestest/:id', component :<AddSeriesTest/>},
     { path:'/addmoreseriestest/:id', component :<AddMoreSeriesTest />},
     { path:'/editseriestest/:id', component :<EditSeriestest/>},
     { path: '/quiz', component : <Quiz/>},
     { path:'/addquiz', component : <AddQuiz/>},
     { path:'/editquiz/:id', component : <EditQuiz/>},
     { path:'/quizcontent/:id', component : <ContentQuiz/>},
     { path:'/addcontent/:id', component : <AddContent/>},
     { path:'/addcontent/mcq/:id', component : <AddContentMcq />},
     { path:'/editcontent/:id/:id1', component :<EditContent/>},
     { path:'/activity', component : <Activity/>},
     { path:'/editcontent/mcq/:id/:id1', component :<EditContentMcq />},
     { path:'/challenges', component :<GetChallengesList />},
     { path:'/challenge/view/:id', component :<ViewChallengeDetails />},
     { path:'/add-challenges', component :<AddChallenge />},
     { path:'/edit-challenges/:id2', component :<EditChallenge />},
     {path:"/assign-plans/:id",component:<AssignPlan/>},
     {path:"/upgrade-plans/:id",component:<UpgradePlan/>},
     {path:"/alerts/add",component:<AddAlerts/>},
     {path:"/alerts/edit/:id",component:<EditAlert/>},
     {path:"/alerts" ,component:<GetAlerts/>},
     {path:"/tickets" ,component:<GetAllTickets/>},
     {path:"/tickets/edit/:id" ,component:<Edittickets/>},


     


  // Profile
  { path: "/userprofile", component: <UserProfile /> },



 

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes= [
  // Authentication Page

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },



];

export { authProtectedRoutes, publicRoutes };