import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Card, Button } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GetPaidUserDetails,
  GetUserCourses,
  GetUserStats,
} from "../../store/slices/paid";
import { Logout } from "../../utils/helpers/Logout";

export const UserDetails = () => {
  const [loading, setLoading] = useState(false);
  const { id, type } = useParams();
  const [details, setDetails] = useState();
  const navigate = useNavigate();
  const [userStats, setUserStats] = useState();
  const [courses, setCourses] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const item = { id: id };
    dispatch(GetPaidUserDetails(item))
      .unwrap()
      .then((data) => {
        setDetails(data?.paid?.data[0]);
        setLoading(false);
      })
      .catch(({ message }) => {
        alert(message);
        setLoading(false);
      });
  }, [dispatch]);

  const getUserCourses = () => {
    const item = { userId: id };
    dispatch(GetUserCourses(item))
      .unwrap()
      .then((data) => {
        setCourses(data?.users?.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const GetUserStatsfunc = () => {
    const item = { id: id, type: type };
    dispatch(GetUserStats(item))
      .unwrap()
      .then((data) => {
        setUserStats(data.data.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    GetUserStatsfunc();
    getUserCourses();
  }, []);

  const handleUpgradePlan = () => {
    if (type === "paid") {
      navigate(`/upgrade-plans/${id}`);
    } else {
      navigate(`/assign-plans/${id}`);
    }
  };

  const SeriesTestLogs = () => {
    navigate(`/test-series-logs/${id}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  return (
    <div className="page-content">
      <Container fluid={true}>
        <div
          class="text-start my-2 ms-1"
          style={{
            fontWeight: "600",
          }}
        >
          <Link to="/dashboard">Home</Link>&nbsp;&#8811;
          <Link to="/paidusers">{type} Users</Link>&nbsp;&#8811; User Details
        </div>
        <div class="card">
          <div class="card-header">
            <div class="card-title-wrap bar-success d-flex align-items-center">
              <h4 class="card-title">User Details</h4>
            </div>
            <div className="d-flex justify-content-end p-3">
              {!details?.maxCourse ? (
                <button
                  className="btn btn-primary btn-sm ms-2  "
                  onClick={handleUpgradePlan}
                >
                  {type === "paid" ? "Upgrade Plan" : "Assign Plan"}
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-primary btn-sm ms-2  "
                onClick={SeriesTestLogs}
              >
                SeriesTest Logs
              </button>
            </div>
          </div>

          <Row className="p-3">
            <Col lg={4} md={8} sm={10} className="mb-4">
              <Card
                className="p-3 rounded  "
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <div className="text-center">
                  <img
                    src={
                      details?.profileImageUrl ||
                      "https://hancockogundiyapartners.com/wp-content/uploads/2019/07/dummy-profile-pic-300x300.jpg"
                    }
                    alt="User Profile"
                    className=" mb-3"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h5 className="text-center mb-2">
                  {details?.name || "User Name"}
                </h5>
                <p className="text-center text-muted mb-2">{details?.email}</p>
                <p className="text-center text-muted">{details?.mobileNo}</p>

                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <h6 className="mb-0" style={{ color: "#FE7533" }}>
                      Plan start date:
                    </h6>
                  </div>
                  <div>
                    {details?.planStartsAt ? (
                      <p className="mb-0">
                        {formatDate(details?.planStartsAt)}
                      </p>
                    ) : (
                      "No Active plan"
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <h6 className="mb-0" style={{ color: "#FE7533" }}>
                      Plan end date:
                    </h6>
                  </div>
                  <div>
                    {details?.planExpiresAt ? (
                      <p className="mb-0">
                        {formatDate(details?.planExpiresAt)}
                      </p>
                    ) : (
                      "No Active plan"
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  {details?.isEmailVerified ? (
                    <div className="d-flex align-items-center text-success">
                      <i className="ri-check-fill me-2"></i>
                      <span>Email Verified</span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center text-danger">
                      <i className="ri-error-warning-fill me-2"></i>
                      <span>Email Not Verified</span>
                    </div>
                  )}
                  {details?.isMobileVerified ? (
                    <div className="d-flex align-items-center text-success mt-2">
                      <i className="ri-check-fill me-2"></i>
                      <span>Mobile Verified</span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center text-danger mt-2">
                      <i className="ri-error-warning-fill me-2"></i>
                      <span>Mobile Not Verified</span>
                    </div>
                  )}
                </div>
              </Card>
            </Col>

            <Col lg={6} md={8} sm={10}>
              {courses?.length > 1 ? <h5>User Courses</h5> : ""}
              {courses?.map((item) => (
                <Card
                  className="courses-card p-3"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                >
                  <Card.Body>
                    <div className="courses-list">
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "40px" }}
                      >
                        <p>
                          <strong style={{ color: "#FE7533" }}>
                            Course Name:
                          </strong>
                        </p>
                        <p style={{ marginRight: "40px" }}>
                          {" "}
                          {item?.course?.courseName}
                        </p>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "40px" }}
                      >
                        <p>
                          <strong style={{ color: "#FE7533" }}>Status:</strong>
                        </p>
                        <p style={{ marginRight: "40px" }}>
                          {" "}
                          {item?.course?.status === 1 ? "Active" : "Inactive"}
                        </p>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "40px" }}
                      >
                        <p>
                          <strong style={{ color: "#FE7533" }}>
                            Course Type:
                          </strong>
                        </p>
                        <p style={{ marginRight: "40px" }}>
                          {item?.course?.type}
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
