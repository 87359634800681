import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Container} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import FileUpload from "../../../utils/helpers/Fileupload";
import { Logout } from "../../../utils/helpers/Logout";
import { postCategoryQuiz } from "../../../store/slices/faq";
import { categorynameregex } from "../../../utils/Regex";

function AddQuiz() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // const [loading, setLoading] = useState("idle");
  const dispatch = useDispatch();
  const [sectorName, setSectorName] = useState("");
  const [icon, setIcon] = useState("");
  const [sectorNameError, setSectorNameError] = useState();
  const [iconError, setIconError] = useState();
  const [type, setType] = useState();
  const [typeError, setTypeError] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const save = (e) => {
    let isValid = true;

    e.preventDefault();
    if (sectorName === "") {
      setSectorNameError("Please enter category name");
       isValid = false;
    }else if (!categorynameregex.test(sectorName)) {
      setSectorNameError("Please enter valid name");
      isValid = false;
    }
    if (icon === "") {
      setIconError("Please upload icon");
      isValid = false;
    }
    if (!type) {
      setTypeError("Please select quiz type");
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    else {
      addData(e);
    }
  };

  async function addData(e) {
    e.preventDefault();
    // setLoading("loading");

    // let status = 1;
    let item = {
      name: sectorName,
      type: type,
      icon,
    };
    setButtonDisabled(true);

    dispatch(postCategoryQuiz(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        // setButtonDisabled(false);
        alert("Quiz added successfully");
        navigate("/quiz");
      })
      .catch(({ message }) => {
    
        alert(message);
        
      });
  }

  async function reset(e) {
    setSectorName("");
  }

  const sectorChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, ''); 
    setSectorName(filteredValue );
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
    if (!categorynameregex.test(filteredValue )) {
      setSectorNameError("Please enter valid name");
    }else{
    setSectorNameError("");
    }
  };
  const IconChange = async (e) => {
    try {
      const val = await FileUpload(e);
      setIcon(val);
      setIconError("");
    } catch (error) {
      alert("File Size is Large");
      setIconError("File Size is Large choose small size");
    }
  };

  const TypeChange = (e) => {
    setType(e.target.value);
    setTypeError("");
  };

  const handleCancelClick = () => {
    navigate("/quiz");
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>
              <Link to="/quiz" style={{ marginLeft: "5px" }}>
                &#8811; Quiz
              </Link>
               &nbsp;&#8811; Add Quiz
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Add Quiz</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Category Name</span>
                      <input
                        type="text"
                        placeholder="Enter category name"
                        className="form-control"
                        value={sectorName}
                        onChange={(e) => sectorChange(e.target.value)}
                      />
                      <p className="alert-message">{sectorNameError}</p>
                    </div>

                   
                
                  <div
                    className="col-md-5 pt-2 pb-2"
                  >
                    <span className="label">Type</span>
                    <select
                      value={type}
                      className="form-control"
                      name="type"
                      onChange={TypeChange}
                    >
                      <option value="">Select type </option>
                      <option value="content">Content </option>
                      <option value="mcq">MCQ </option>
                    </select>
                    <p className="alert-message">{typeError}</p>
                  </div>
                  <div
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Icon</span>
                      <input
                        type="file"
                        placeholder="Enter icon  url link"
                        className="form-control"
                        onChange={IconChange}
                      />
                      <p className="alert-message">{iconError}</p>
                    </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={handleCancelClick}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2 wd-100"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div></div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default AddQuiz;



  