import React from 'react';
import { useNavigate,useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button,Col,Table ,Container} from 'reactstrap';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
import { getVisitors } from '../../store/slices/faq';
import { useState } from 'react';
import ShowSkeleton from '../../utils/helpers/Skeleton';
import { Logout } from '../../utils/helpers/Logout';
import ReactPaginate from "react-paginate";

const  Visitors =()=>{
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const [totaldata, setTotaldata] = useState();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [data,setData] = useState([])
  const dispatch = useDispatch()
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };
    const navigate = useNavigate();
    const handleViewResponseClick = () => {
        navigate('/viewresponse');
      };

      const handlePageChange = ({ selected }) => {
        const newPage = selected + 1 || currentPage;
        setCurrentPage(newPage);
        search.set("page", newPage);
        setSearch(search, { replace: true });
      };
   
      useEffect(()=>{
        GetVisitors(item)
      }, [itemsPerPage, currentPage]);

      useEffect(() => {
        
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        Logout()
      }, []);
      
      const GetVisitors = (item) => {
        setLoading(true);
       
        dispatch(getVisitors(item))
          .unwrap().then((data)=>{
            setData(data?.visitors?.data);
            setTotaldata(data?.visitors?.totalDataCount);
          
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }) 
          .catch((err) => {
            console.error("Error fetching current affairs:", err);
          });
        }
      
    return(
        <>
           <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{ fontWeight: "600", }}>
                    <Link to="/dashboard">Home</Link> &#8811;Visitors                   
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Visitors Response</h4>
                      
                      </div>
                    </div>
                    <div class="card-body collapse show">
                      <div class="card-block card-dashboard table-responsive">
                      {loading ? (
                        <Col lg={12} xs={12} md={12} align="center">
                          {ShowSkeleton()}
                        </Col>
                      ) : !data?.length ? (
                        <div className="mt-4 text-center card-body collapse show pb-3">
                          <h5>No visitors registered yet. </h5>
                        </div>
                      ) : (
                        <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead  className='responseHeaderStyle'>
                           <tr>
                           <th className="text-center">Name</th>
                            <th className="text-center">Email</th>
                           <th className="text-center">Mobile No</th>
                           <th className="text-center">Qualification</th>
                       
                          </tr>
                          </thead>
                              <tbody>
                              {data?.map((elem, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">
                                    {elem?.name}
                                  </td>
                                  <td className="text-center align-middle">
                                    {elem?.email}
                                  </td>
                                  <td className="text-center align-middle">
                                  {elem?.mobileNo}
                                </td>
                                <td className="text-center align-middle">
                                  {elem?.qualification}
                                </td>
                              {/*    <td className="text-center align-middle">
                                   

                                    <button
                                      className="btn btn-danger ms-2"
                                     
                                    >
                                   Delete
                                    </button>
                              </td> */}
                                </tr>
                              ))}
               
                            </tbody>
                          </Table>
                      )}
                       </div>
                    </div>
                    {totaldata / itemsPerPage > 1 && (
                      <div className="mt-5 d-flex justify-content-end align-right">
                        <ReactPaginate
                          key={currentPage}
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakLinkClassName={"page-link"}
                          pageCount={Math.ceil(totaldata / itemsPerPage)}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={pagesToShowInitially}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          disabledClassName="disabled"
                          initialPage={currentPage - 1}
                        />
                      </div>
                    )}
                 </div>
                 </Container>
               </div>
</React.Fragment>
        </>
    );
};
export default Visitors;