import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class TicketsService {
  static addTickets(item) {
    return axiosInstance.post(API_PATHS.addalerts, item);
  }


  static getTickets(item) {
    let api;
    if (item?.id) {
      api = `${API_PATHS.fetchtickets}?id=${item?.id}`;
    }else{
        api = `${API_PATHS.fetchtickets}?limit=${item?.limit}&page=${item?.page}`;
    }
    return axiosInstance.get(api);
  }

  static editTickets(item) {
    return axiosInstance.put(API_PATHS.editickets, item);
  }

  static deleteTickets(item) {
    return axiosInstance.delete(`${API_PATHS.deletealerts}?id=${item?.id}`);
  }
}

export default TicketsService;
