
const SidebarDatarole = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        isHasArrow: true,
    },

    {
        label: "Courses",
        icon: "mdi  mdi-clipboard-text",
        isHasArrow: true,
        url: "/courses",
    },
  {
        label: "Logout",
        icon: "mdi mdi-logout",
        isHasArrow: true,
        url: "/auth-login",
    },
    
]
export default SidebarDatarole;