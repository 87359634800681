import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { editFinaltest, getFinaltest } from "../../../../store/slices/courses";
import TextEditor2 from "../../../../utils/helpers/Editor2";
import TextEditor3 from "../../../../utils/helpers/Editor3";
import { ShowSkeleton1 } from "../../../../utils/helpers/Skeleton";

function EditFinalTest() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const initialQuestion = {
    type: "text",
    question: "",
    order: "",
    explanation: "",
    correctOption: "",
    options: Array.from({ length: 6 }, () => ({
      optionNumber: "",
      optionValue: "",
      correctOption: false,
    })),
  };
  const [questions, setQuestions] = useState(
    [...Array(5)].map((_, index) => ({
      type: "text",
      question: "",
      correctOption: "",
      questionNumber: index + 1,
      explanation: "",
      options: [
        { optionNumber: "1", optionValue: "", correctOption: false },
        { optionNumber: "2", optionValue: "", correctOption: false },
        { optionNumber: "3", optionValue: "", correctOption: false },
        { optionNumber: "4", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
      ],
    }))
  );
  const [data, setData] = useState({
    id: "",
    unitId: id,
    questions: questions,
  });
  const [errors, setErrors] = useState(
    [...Array(5)].map(() => ({
      question: "",
      questionNumber: "",
      explanation: "",
      correctOption: "",
      options: [
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
      ],
    }))
  );

  useEffect(() => {
    dispatch(getFinaltest(id))
      .unwrap()
      .then((data1) => {
        const userData = data1?.finaltest?.data[0];
        if (userData) {
          const { _id, questions } = userData;

          const updatedQuestions = questions.map((question, index) => {
            const backendQuestion = questions[index];

            if (backendQuestion) {
              const paddedOptions = Array.from({ length: 6 }, (_, oIndex) => ({
                ...(backendQuestion.options[oIndex] || {
                  optionNumber: "",
                  optionValue: "",
                  correctOption: false,
                }),
              }));

              return {
                ...initialQuestion,
                ...backendQuestion,
                options: paddedOptions,
              };
            } else {
              return { ...initialQuestion };
            }
          });

          setDatafunc(_id, updatedQuestions);
          setQuestions(updatedQuestions);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  function setDatafunc(_id, questions) {
    setData({
      ...data,
      id: _id,
      questions: questions,
    });
  }

  const handleQuestionChange = (e, questionIndex) => {
    const { name, value } = e.target;
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];

      if (questionIndex >= 0 && questionIndex < updatedQuestions.length) {
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          [name]: value,
        };
      }

      return updatedQuestions;
    });

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];

      if (questionIndex >= 0 && questionIndex < updatedErrors.length) {
        updatedErrors[questionIndex][name] =
          name === "explanation" && value === "" ? "" : value === "" ? "" : "";
      }

      return updatedErrors;
    });
  };

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const { name, value, checked } = e.target;

    if (name === "optionNumber" && (value < 1 || value > 6)) {
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] =
          "Option number must be between 1 and 6.";
        return updatedErrors;
      });
      return;
    }

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      const currentQuestion = updatedQuestions[questionIndex];
      if (!currentQuestion) return prevQuestions;

      const updatedOptions = currentQuestion.options.map((option, index) => {
        if (index === optionIndex) {
          return {
            ...option,
            [name]: name === "correctOption" ? checked : value,
          };
        }
        return option;
      });

      if (name === "correctOption") {
        currentQuestion.correctOption = checked ? optionIndex + 1 : null;
        updatedOptions.forEach((option, index) => {
          if (option) {
            option.correctOption = index === optionIndex && checked;
          }
        });
      }

      updatedQuestions[questionIndex] = {
        ...currentQuestion,
        options: updatedOptions,
      };

      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] = "";

        if (name === "correctOption" && checked) {
          updatedErrors[questionIndex].options.forEach((option) => {
            option.validationMessage = "";
          });
        }

        return updatedErrors;
      });

      return updatedQuestions;
    });
  };

  const handleAddMainForms = () => {
    const lastQuestionNumber =
      questions.length > 0 ? questions[questions.length - 1].questionNumber : 0;
    const newQuestionNumber = lastQuestionNumber + 1;

    const updatedQuestions = [
      ...questions,
      {
        type: "text",
        question: "",
        correctOption: "",
        explanation: "",
        questionNumber: newQuestionNumber,
        options: [
          { optionNumber: "1", optionValue: "", isCorrect: false },
          { optionNumber: "2", optionValue: "", isCorrect: false },
          { optionNumber: "3", optionValue: "", isCorrect: false },
          { optionNumber: "4", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
        ],
      },
    ];
    const updatedErrors = [
      ...errors,
      {
        question: "",
        correctOption: "",
        questionNumber: newQuestionNumber,
        explanation: "",
        options: [
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
        ],
      },
    ];
    setQuestions(updatedQuestions);
    setErrors(updatedErrors);
  };

  const handleDeleteQuestion = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(questionIndex, 1);

      return updatedQuestions.map((question, index) => ({
        ...question,
        questionNumber: index + 1,
      }));
    });

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors.splice(questionIndex, 1);

      return updatedErrors.map((error, index) => ({
        ...error,
        questionNumber: index + 1,
      }));
    });
  };

  const save = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 4000);

    const filledQuestions = questions.filter(
      (question) => question?.question?.trim() !== ""
    );

    if (filledQuestions.length === 0) {
      alert("Please fill at least one question");
      return;
    }

    const updatedErrors = filledQuestions.map((question, qIndex) => {
      const isAnyCheckboxSelected = question.correctOption;
      const isValidOrder =
        question.questionNumber !== "" || parseInt(question.questionNumber) > 0;

      const isValidOption =
        question.options.length >= 2 &&
        question.options.filter(
          (option) => option.optionNumber !== "" && option.optionValue !== ""
        ).length >= 2;

      const errors = question.options.map((option, oIndex) => {
        const error = {};

        if (!isValidOption) {
          error.optionNumber = "";
        } else if (
          option.optionNumber !== "" &&
          (parseInt(option.optionNumber) <= 0 ||
            parseInt(option.optionNumber) > 6)
        ) {
          error.optionNumber = "";
        } else {
          error.optionNumber = "";
        }

        error.optionValue =
          isValidOption || option.optionValue !== ""
            ? ""
            : "Please enter option value";

        error.validationMessage = isAnyCheckboxSelected
          ? ""
          : "Please select correct option";

        return error;
      });

      return {
        question:
          question.question.trim() !== "" ? "" : "Please enter question",
        questionNumber: isValidOrder ? "" : "Please enter questionNumber",
        options: errors,
      };
    });

    setErrors(updatedErrors);

    const hasErrors = updatedErrors.some(
      (error) =>
        error.question !== "" ||
        error.questionNumber !== "" ||
        error.options.some(
          (opt) =>
            opt.optionNumber !== "" ||
            opt.optionValue !== "" ||
            opt.validationMessage
        )
    );

    if (hasErrors) {
      alert("Please fill in all required fields correctly");
      return;
    } else {
      const validQuestions = questions.filter(
        (question) => question.question !== ""
      );
      const item = validQuestions.map((question) => {
        const validOptions = question.options.filter(
          (option) => option.optionNumber !== "" && option.optionValue !== ""
        );

        return {
          question: question.question,
          questionNumber: question.questionNumber,
          explanation: question.explanation || undefined,
          correctOption: question.correctOption,
          options: validOptions,
        };
      });

      const qitem = {
        unitId: id,
        id: data.id,
        questions: item,
      };

      dispatch(editFinaltest(qitem))
        .unwrap()
        .then((res) => {
          if (res?.finaltest?.message) {
            alert(res?.finaltest?.message);
            navigate(`/finaltest/${id}`);
          }
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const categoryid = localStorage.getItem("categoryId");
  const combinationid = localStorage.getItem("combinationId");
  const combinationid1 = localStorage.getItem("combinationId1");
  const unitId = localStorage.getItem("unitId");
  const cancelHandler = () => {
    navigate(`/finaltest/${id}`);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to={`/courses/${categoryid}`}>Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/view-combinations/${combinationid1}`}>
                Combinations
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationid}`}>Chapters</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/units/${unitId}`}>Units</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/finaltest/${id}`}>Final Test</Link>
              &nbsp;&#8811; Edit Final Test
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                  <h4 class="card-title">Edit Questions </h4>
                  &nbsp;
                  <div className="container-buttons1">
                    <div className="col-11 d-flex justify-content-end mt-1">
                      <button
                        className="btn btn-info btn-s p-1"
                        style={{ height: "30px", width: "60px" }}
                        onClick={(e) => handleAddMainForms(e)}
                      >
                        <CiCirclePlus style={{ fontSize: "20px" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <section className="form-Questionnaire p-4">
                {questions.map((question, qindex) => (
                  <form key={qindex}>
                    <span className="bread-head1 mt-3">
                      Question No - {qindex + 1}
                    </span>
                    <button
                      style={{ height: "30px", width: "30px" }}
                      className="btn btn-danger btn-sm p-1 ms-2 "
                      onClick={() => handleDeleteQuestion(qindex)}
                    >
                      <CiCircleMinus
                        style={{ fontSize: "20px", color: "white" }}
                      />
                    </button>
                    <div className="row">
                      <div className="col-md-11 col-sm-11 pt-2">
                        {loading ? (
                          <ShowSkeleton1 />
                        ) : (
                          <div className="question-explanation-section">
                            <TextEditor3
                              question={question}
                              handleQuestionChange={handleQuestionChange}
                              questionIndex={qindex}
                            />
                          </div>
                        )}
                        <p className="alert-message">
                          {errors[qindex]?.question}
                        </p>
                      </div>
                      <div className="col-md-11 col-sm-11 pt-2">
                        {loading ? (
                          <ShowSkeleton1 />
                        ) : (
                          <div className="question-explanation-section">
                            <span className="bread-head1 mt-3">
                              Explanation
                            </span>
                            <TextEditor2
                              question={question}
                              handleQuestionChange={handleQuestionChange}
                              questionIndex={qindex}
                            />
                          </div>
                        )}
                        <p className="alert-message">
                          {errors[qindex]?.explanation}
                        </p>
                      </div>
                      <div className=" d-flex col-md-4 col-sm-12 pt-2 pb-1 col-ml-5">
                        <span className="bread-head1 mt-2">
                          Correct option :
                        </span>
                        <span className="bread-head1  m-2">
                          {questions[qindex]?.correctOption}
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <span className="bread-head1">Options</span>
                        </div>
                      </div>
                      <div style={{ paddingBottom: "30px" }}>
                        <div className="row option-section ">
                          {question.options.map((option, optionIndex) => (
                            <div className="col-11" key={optionIndex}>
                              <div className="row">
                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-2 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={`Option No - ${
                                      optionIndex + 1
                                    }`}
                                    value={option.optionNumber}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    name="optionNumber"
                                  />
                                  <p className="alert-message">
                                    {option.optionNumber !== "" && (
                                      <span>
                                        {
                                          errors[qindex]?.options[optionIndex]
                                            ?.optionNumber
                                        }
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-6 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter option ${
                                      optionIndex + 1
                                    }`}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    value={option.optionValue}
                                    name="optionValue"
                                    required
                                  />
                                  <p className="alert-message">
                                    {optionIndex < 2
                                      ? errors[qindex]?.options[optionIndex]
                                          ?.optionValue
                                      : ""}
                                  </p>
                                </div>

                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-2 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="checkbox"
                                    className="mt-2"
                                    label=""
                                    checked={
                                      question.correctOption !== "" &&
                                      question.correctOption == optionIndex + 1
                                    }
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    name="correctOption"
                                    id={`correctOption-${qindex}-${optionIndex}`}
                                    disabled={
                                      !option.optionNumber ||
                                      !option.optionValue
                                    }
                                  />
                                  <p className="alert-message">
                                    {optionIndex < 2
                                      ? errors[qindex]?.options[optionIndex]
                                          ?.validationMessage
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>
                ))}
              </section>
              <div className="container-buttons">
                <div className="col-12 d-flex justify-content-center p-2">
                  <button
                    className="btn btn-outline-info wd-100"
                    onClick={cancelHandler}
                    disabled={buttonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info wd-100  ms-2"
                    onClick={save}
                    disabled={buttonDisabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default EditFinalTest;
