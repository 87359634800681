import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Logout } from "../../../utils/helpers/Logout";
import TextEditor from "../../Fundamentals/fundamentalMaterial/Editor";
import { Container} from "reactstrap";
import { postContentQuiz } from "../../../store/slices/faq";

function AddContent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dateError, setDateError] = useState("");
  const [textError, setTextError] = useState("");
  const dispatch = useDispatch();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState({
    date: "",
    quizCategoryId: id,
    content: "",
  });

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const handleChange = (e) => {
   
    let { name, value } = e.target;
    switch (name) {
      case "date":
        setDateError(value === "" ? "Please enter date" : "");
        break;
      case "content":
        setTextError(value === "" ? "" : "");
        break;
      default:
        break;
    }
    setData({
      ...data,
      [name]: value,
    });
  };

  const save = (e) => {
    e.preventDefault();
    let isValid = true
      if (data.date === "") {
      setDateError("please enter date");
      isValid= false
    }

    if(!isValid){
      return
    }else {
      setButtonDisabled(true);
      const item = data;
      dispatch(postContentQuiz(item))
        .unwrap()
        .then((data) => {
          console.log(data);
          if (data.content.message) {
            alert(data.content.message);
            navigate(-1);
          }
          setButtonDisabled(false);
        })
        .catch(({ message }) => {
          setButtonDisabled(false);
          alert(message);
        });
    }
  };

  const handleCancelClick = () => {
    navigate(-1);
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/quiz">Quiz</Link>
              &nbsp;&#8811;&nbsp;<Link to={`/quizcontent/${id}`}>Quiz Content</Link>
              &nbsp;&#8811;&nbsp; Add Content
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                  <h4 class="card-title">Add Content</h4>
                </div>
              </div>
              <section className="form-Questionnaire p-4">
                <div style={{ height: "100%" }} className="col-md-6 col-sm-12">
                  <span className="bread-head1 mt-3">Date</span>

                  <div className="pt-2 pb-2">
                    <input
                      type="date"
                      required="true"
                      name="date"
                      className="form-control "
                      placeholder="Enter date"
                      onChange={(e) => handleChange(e)}
                      value={data.date}
                    />
                  </div>
                  <p className="alert-message">{dateError}</p>
                </div>
                <div className="col-md-11 col-sm-12 mt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="bread-head1">Content</label>
                  </div>

                  <TextEditor
                    data={data}
                    setData={setData}
                    handleChange={handleChange}
                  />
                  <p className="alert-message">{textError}</p>
                </div>
                <div className="col-md-12 mt-3" align="center">
                  <button className="btn btn-outline-info wd-100" onClick={handleCancelClick}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info wd-100 ms-2"
                    onClick={save}
                  >
                    Submit
                  </button>
                </div>
              </section>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default AddContent;
