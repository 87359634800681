import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class challengesService {
  static addChallenge(item) {
    let api = API_PATHS.addchallenges;
    return axiosInstance.post(api, item);
  }

  static editChallenge(item) {
    let api = API_PATHS.editchallenges;
    return axiosInstance.put(api, item);
  }

  static deleteChallenge(item) {
    return axiosInstance.delete(API_PATHS.deletechallenges, item);
  }
  static getchallenges(item) {
    let api;
    if (item?.id) {
      api = `${API_PATHS.getchallenges}?id=${item.id}`;
    } else {
      api = `${API_PATHS.getchallenges}?limit=${item.limit}&page=${item.page}`;
    }

    return axiosInstance.get(api);
  }
}

export default challengesService;
