import React, { useState, useEffect } from "react";
import { Card, Button, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import {
  getfundamentalMaterials,
  getsinglefundamentalMaterials,
} from "../../../store/slices/fundamentals";
import { Logout } from "../../../utils/helpers/Logout";

function PreviewFundamentalMaterial() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { id1, id2 } = useParams();
  const unitId = id1;
  localStorage.setItem("previewmaterial", id2);
  let materialsId = localStorage.getItem("previewmaterial");
 
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  React.useEffect(() => {
    const item = {
      id1: id1,
      id2: id2,
    };
    dispatch(getsinglefundamentalMaterials(item))
      .unwrap()
      .then((data) => {
        setData(data.fundamentalmatrial.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const cancelHandler = () => {
    navigate(-1);
  };

  const cancelHandler2 = () => {
    navigate(-2);
  };

  const cancelHandler3 = () => {
    navigate(-3);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Check screen size on mount
    handleScreenSizeChange();

    // Listen for screen size changes
    window.addEventListener("resize", handleScreenSizeChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleScreenSizeChange);
    };
  }, []);

  const handleScreenSizeChange = () => {
    // Set the small screen state based on the window width
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };
  const fundchapterid = localStorage.getItem("fundmentalchapterId");
  const fundunitid = localStorage.getItem("fundmentalunitId");

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/home">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/fundamentals/units/${fundunitid}`}>Units</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/fundamentals/materials/${id1}`}>Material</Link>
              &nbsp;&#8811; Materials
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                  <h4 class="card-title">Fundamental Material</h4>
                  <div className="d-flex justify-content-end">
                    <Link
                      to={{pathname:`/fundamentals/editmaterial/${unitId}/${materialsId}`}}
                      style={{ height: "25px", color: "white" }}
                    >
                      <button className="btn btn-info">Edit &nbsp;</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {data.map((elem, index) => (
                    <Card key={index} className="mb-3 p-5 mt-3">
                      <div className="d-flex w-100 ">
                        <div style={{ width: isSmallScreen ? "50%" : "30%" }}>
                          <p>Unit Name : </p>
                        </div>
                        <div style={{ width: isSmallScreen ? "50%" : "70%" }}>
                          <p>{elem.materialName}</p>
                        </div>
                      </div>
                      <div className="d-flex w-100 ">
                        <div style={{ width: isSmallScreen ? "50%" : "30%" }}>
                          <p>Topic Number : </p>
                        </div>
                        <div style={{ width: isSmallScreen ? "50%" : "70%" }}>
                          <p>{elem.materialNumber}</p>
                        </div>
                      </div>
                      <div className="d-flex w-100 ">
                        <div style={{ width: isSmallScreen ? "50%" : "30%" }}>
                          <p>Topic Url : </p>
                        </div>
                        <div style={{ width: isSmallScreen ? "50%" : "70%" }}>
                          <a href={elem.url}>{elem.url}</a>
                        </div>
                      </div>
                      <div
                        className={`w-100 ${
                          isSmallScreen ? "d-block" : "d-flex"
                        }`}
                      >
                        <div
                          style={{
                            width: isSmallScreen ? "100%" : "30%",
                          }}
                        >
                          <p>Topic Details : </p>
                        </div>
                        <div
                          style={{
                            width: isSmallScreen ? "100%" : "70%",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: elem.description.replace(
                                '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
                                ""
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default PreviewFundamentalMaterial;
