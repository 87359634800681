import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button,Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddSubjects, getCourses } from "../../store/slices/courses";
import { useEffect } from "react";
import FileUpload from "../../utils/helpers/Fileupload";
import { Logout } from "../../utils/helpers/Logout";
import { EditChallenges, GetChallenges } from "../../store/slices/challenges";
import RulesEditor from "../../utils/helpers/RulesEditor";

function EditChallenge() {
  const { id2 } = useParams();
  const [title, setTitle] = useState();
  const [rules, setRules] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [lastDate, setLastDate] = useState();
  const navigate = useNavigate();
  const [titleError, setTitleError] = useState();
  const [rulesError, setRulesError] = useState();
  const [startDateError, setStartDateError] = useState();
  const [endDateError, setEndDateError] = useState();
  const [lastDateError, setLastDateError] = useState();
  const [course, setCourse] = useState();
  const [courses, setCourses] = useState();
  const [courseError, setCourseError] = useState();
  const [type, setType] = useState();
  const [typeError, setTypeError] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getcourseslist();
  }, []);

  const getcourseslist = () => {
    dispatch(getCourses())
      .unwrap()
      .then((data) => {
        setCourses(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };
  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  const save = (e) => {
    e.preventDefault();
    if (!title) {
      setTitleError("Please enter Title");
      return;
    }
    if (!course) {
      setCourseError("Please select course");
      return;
    }
    if (!startDate) {
      setStartDateError("Please select start date");
      return;
    }
    if (!lastDate) {
      setLastDateError("Please enter last date");
      return;
    } else if (lastDate >= startDate) {
      setLastDateError("Last Date should be less than  start date");
      return;
    }
    if (!endDate) {
      setEndDateError("Please enter end date");
      return;
    } else if (endDate <= lastDate) {
      setEndDateError("End Date should be greater than last date");
      return;
    }
    if (!rules) {
      setRulesError("Please enter rules");
      return;
    }
    if (!type) {
      setTypeError("Please select type");
      return;
    } else {
      addData(e);
    }
  };

  useEffect(() => {
    const item = {
      id: id2,
    };
    dispatch(GetChallenges(item))
      .unwrap()
      .then((data) => {
        setType(data?.challenges?.data[0].type);
        setTitle(data?.challenges.data[0].title);
        setCourse(data?.challenges.data[0].courseId._id);
        setStartDate(data?.challenges.data[0].startDate.split("T")[0]);
        setRules(data?.challenges.data[0].rules);
        setEndDate(data?.challenges.data[0].endDate.split("T")[0]);
        setLastDate(data?.challenges.data[0].lastDate.split("T")[0]);       
     
      })
      .catch((err) => {});
  }, []);

  async function addData(e) {
    e.preventDefault();

    setButtonDisabled((prevState) => !prevState);
    setTimeout(() => {
      setButtonDisabled((prevState) => !prevState);
    }, 4000);
    let status = 1;

    let item = {
      courseId: course,
      id: id2,
      title: title,
      rules: rules,
      startDate: startDate,
      endDate: endDate,
      lastDate: lastDate,
      type: type,
    };
    dispatch(EditChallenges(item))
      .unwrap()
      .then(() => {
        alert("challenge updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleChange = (e) => {
    let { value, name } = e.target;

    switch (name) {
      case "title":
        setTitle(value);
        if (!value) {
          setTitleError("Please enter title");
        } else {
          setTitleError("");
        }
        break;

      case "course":
        setCourse(value);
        if (!value) {
          setCourseError("Please select course");
        } else {
          setCourseError("");
        }
        break;
      case "rules":
        setRules(value);
        setRulesError("");
        break;
      case "type":
        setType(value);
        if (!value) {
          setTypeError("Please select type");
        } else {
          setTypeError("");
        }
        break;
      case "startDate":
        setStartDate(value);
        if (!value) {
          setStartDateError("Please select start date");
        } else {
          setEndDateError("");
        }
        break;
      case "lastDate":
        setLastDate(value);
        if (!value) {
          setLastDateError("Please select last date");
        } else if (value >= startDate) {
          setLastDateError("Last Date should be less than  start date");
        } else {
          setLastDateError("");
        }
        break;
      case "endDate":
        setEndDate(value);
        if (!value) {
          setEndDateError("Please select end date");
        } else if (value <= lastDate) {
          setEndDateError("End Date should be greater than last date");
        } else {
          setEndDateError("");
        }
        break;
      default:
        break;
    }
  };

  const cancelClick = () => {
    navigate(`/challenges`);
  };


  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Link to={`/challenges`}>Challenges</Link>
                    &nbsp;&#8811;&nbsp;Edit Challenge
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Edit Challenge</h4>
                      </div>
                    </div>

                    <div class="card-body collapse show">
                      <div class="card-block card-dashboard table-responsive">
                        <div className="row">
                          <div
                            style={{ height: "100%" }}
                            className="col-md-5 pt-2 pb-2"
                          >
                            <span className="label">Title</span>
                            <input
                              type="text"
                              name="title"
                              placeholder="Enter Tilte"
                              className="form-control"
                              value={title}
                              onChange={handleChange}
                            />
                            <p className="alert-message pt-2">{titleError}</p>
                          </div>
                          <div
                            style={{ height: "100%" }}
                            className="col-md-5 pt-2 pb-2"
                          >
                            <span className="label">Select Course</span>
                            <select
                              type="select"
                              placeholder="select course"
                              className="form-control"
                              value={course}
                              name="course"
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {courses?.map((el) => (
                                <option value={el._id}>{el.courseName}</option>
                              ))}
                            </select>

                            <p className="alert-message pt-2">{courseError}</p>
                          </div>
                          <div
                            style={{ height: "100%" }}
                            className="col-md-5 pt-2 pb-2">
                            <span className="label">Select Challenge Type</span>
                            <select
                              type="select"
                              placeholder="select challenge type"
                              className="form-control"
                              value={type}
                              name="type"
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              <option value="1">All</option>
                              <option value="2">Paid</option>
                              <option value="3">Free</option>
                            </select>

                            <p className="alert-message pt-2">{typeError}</p>
                          </div>
                          <div
                            style={{ height: "100%" }}
                            className="col-md-5 pt-2 pb-2"
                          >
                            <span className="label">Start Date</span>
                            <input
                              type="date"
                              name="startDate"
                              placeholder="Select start date"
                              className="form-control"
                              value={startDate}
                              min={new Date().toISOString().split("T")[0]}
                              onChange={handleChange}
                            />
                            <p className="alert-message pt-2">
                              {startDateError}
                            </p>
                          </div>

                          <div
                            style={{ height: "100%" }}
                            className="col-md-5 pt-2 pb-2"
                          >
                            <span className="label">Last Date to join</span>
                            <input
                              type="date"
                              name="lastDate"
                              placeholder="Enter Tilte"
                              className="form-control"
                              value={lastDate}
                              onChange={handleChange}
                            />
                            <p className="alert-message pt-2">
                              {lastDateError}
                            </p>
                          </div>
                          <div
                            style={{ height: "100%" }}
                            className="col-md-5 pt-2 pb-2"
                          >
                            <span className="label">End Date</span>
                            <input
                              type="date"
                              name="endDate"
                              placeholder="Enter Tilte"
                              min={new Date().toISOString().split("T")[0]}
                              className="form-control"
                              value={endDate}
                              onChange={handleChange}
                            />
                            <p className="alert-message pt-2">{endDateError}</p>
                          </div>

                          <div
                            style={{ height: "100%" }}
                            className="col-md-10 pt-2 pb-2"
                          >
                            <span className="label">Rules</span>

                            <RulesEditor
                              value={rules}
                              handleChange={handleChange}
                            />

                            <p className="alert-message pt-2">{rulesError}</p>
                          </div>
                        </div>

                        <div className="container-buttons">
                      <div className="col-12 d-flex justify-content-end p-2">
                        <button
                            className="btn btn-outline-danger "
                            onClick={cancelClick}
                            disabled={buttonDisabled}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-info ms-2"
                            onClick={save}
                            disabled={buttonDisabled}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </Container>
                </div>
                </React.Fragment>
    </>
  );
}
export default EditChallenge;
