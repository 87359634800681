import React from 'react';
import { Link } from 'react-router-dom';
import { Button ,Container} from 'reactstrap';

function Notification() {
  return (
    <>
      <h6 className='notified-local' style={{marginTop:"80px"}} >
      <Link to="/dashboard">Home</Link>&#8811;Notification
       </h6>
    
        {/* </div> */}
    </>
  );
}

export default Notification;
