import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import CurrentAffairService from "../../services/currentaffairs.service";


export const postCurrentAffairs = createAsyncThunk(
    "/post/currentaffairs",
    async (item, thunkAPI) => {
      try {
        const data = await CurrentAffairService.postCurrentAffairs(item);
        return { currentAffairs: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const editCurrentAffairs = createAsyncThunk(
    "/edit/currentaffairs",
    async (item, thunkAPI) => {
      try {
        const data = await CurrentAffairService.editCurrentAffairs(item);
        return { currentAffairs: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getCurrentAffairs = createAsyncThunk(
    "/get/currentaffairs",
    async (id, thunkAPI) => {
      try {
        const data = await CurrentAffairService.getCurrentAffairs(id);
        return { currentAffairs: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const DeleteCurrentAffairs = createAsyncThunk(
    "/delete/currentaffairs",
    async (id, thunkAPI) => {
      try {
        const data = await CurrentAffairService.deleteCurrentAffairs(id);
        return { currentAffairs: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  
const initialState = {
    loading: false,
    error: "",
    currentAffairs: [] || null,
    isLoggedIn: false,
};

const currentAffairSlice = createSlice({
    name: "currentAffairs",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = currentAffairSlice;
export default reducer;