import React, { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {  GetSeriestestLogs } from "../../store/slices/paid";
import {  Container, Col } from "reactstrap";
import ReactPaginate from "react-paginate";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import DataTable from "react-data-table-component";

const TestSeriesLogs = () => {
    const {id} = useParams()

  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 20;
  const pagesToShowInitially = 3;


  const columns = [
    {
      name: "Course Name",
      selector: (row) => (
       row.courseId.courseName
      ),
    },
  
    {
      name: "Subject Name",
      selector: (row) => row.subjectId.subjectName,
    },
    {
      name: "series Name",
      selector: (row) => row.seriesId.seriesName,
    },
    {
        name: "Percentage",
        selector: (row) => row.percentage.toFixed(2) +"  " +" %",
      },
    {
      name: "Test Taken Date",
      selector: (row) =>formatDate(row?.updatedAt),
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  // const [modalShow, setModalShow] = useState(false);
  // const [modalData, setModalData] = useState({});

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
   
  };

  // const handleClose = () => setModalShow(false);
  const item = {
    limit: itemsPerPage,
    page: currentPage,
userId:id
};

  useEffect(() => {
    getSerieslogs();
  }, [itemsPerPage,currentPage]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const getSerieslogs = () => {
    setLoading(true);
    dispatch(GetSeriestestLogs(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setData(data?.users?.data);
        setTotaldata(data?.users?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {});
  };

 

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;  User Test Series Logs
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">User Test Series Logs</h4>
                </div>
               
              </div>
             
              
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No tests taken by this user.</h5>
                    </div>
                  ) : (
                    <div>
                      <DataTable columns={columns} data={data} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>

          <div class="mr-5">
            {totaldata / itemsPerPage > 1 && (
              <div class="mt-2 d-flex justify-content-end align-right">
                <ReactPaginate
                  key={currentPage}
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakLinkclassName={"page-link"}
                  pageCount={Math.ceil(totaldata / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={pagesToShowInitially}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  initialPage={currentPage - 1}
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>

      {/* <Modal show={modalShow}>
        <Modal.Header>
          <Modal.Title style={{ marginBottom: "20px", marginTop: "10px" }}>
            Sponsor Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalData && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Name:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.name}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Email:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.email}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Phone Number:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.mobileNo}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Sponsor Code:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.sponsorCode}</p>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn saveBtn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default TestSeriesLogs;
