import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class CourseService {
  static getCourseDetails(id) {
    let api = API_PATHS.getcourses + "?id=" + id;
    return axiosInstance.get(api);
  }

  static getcourses(item) {
    let api = "";
    
      api = `${API_PATHS.getcourses}`;
    
     return axiosInstance.get(api);
  }

  static postCourses(item) {
    let api = API_PATHS.addcourse;
    return axiosInstance.post(api, item);
  }

  static deleteCourses(item) {
    let api = `${API_PATHS.deletecourse}?id=${item.id}`;

    return axiosInstance.delete(api);
  }

  static editCourse(item) {
    let api = "";

    api = API_PATHS.editCourse;

    return axiosInstance.put(api, item);
  }

  //categies list

  static getCategoryDetails(id) {
    let api;

    api = API_PATHS.getcategories + "?id=" + id;
    return axiosInstance.get(api);
  }

  static getcategories(item) {
    let api = "";
    api = `${API_PATHS.getcategories}?limit=${item?.limit}&page=${item?.page}`;
    return axiosInstance.get(api);
  }

  static postCategories(item) {
    let api = API_PATHS.addcategories;
    return axiosInstance.post(api, item);
  }

  static deleteCategories(item) {
    let api = `${API_PATHS.deletecategory}?id=${item.id}`;

    return axiosInstance.delete(api, item);
  }

  static editCategories(item) {
    let api;

    api = API_PATHS.editcategory;

    return axiosInstance.put(api, item);
  }

  //get subject details
  static getSubjectDetails(id) {
    let api;

    api = API_PATHS.getcoursesubjects + "?id=" + id;
    return axiosInstance.get(api);
  }

  static getSubjects(item) {
    let api = "";
    api = `${API_PATHS.getcoursesubjects}`;
    return axiosInstance.get(api);
  }

  static postSubjects(item) {
    let api = API_PATHS.addcoursesubject;
    return axiosInstance.post(api, item);
  }

  static deleteSubjects(item) {
    let api = `${API_PATHS.deletecoursesubject}?id=${item.id}`;

    return axiosInstance.delete(api);
  }

  static editSubject(item) {
    let api;

    api = API_PATHS.editcoursesubjectDetails;

    return axiosInstance.put(api, item);
  }

  //units services

  static getunitDetails(id) {
    let api = "";
    api = API_PATHS.getunits + "?id=" + id;
    return axiosInstance.get(api);
  }

  static getunits(item) {
    let api = "";
    api = `${API_PATHS.getunits}?chapterId=${item.id}`;
    return axiosInstance.get(api);
  }

  static postUnits(item) {
    let api = API_PATHS.addunits
    return axiosInstance.post(api, item);
  }

  static deleteUnits(id) {
    let api = `${API_PATHS.deleteunits}?id=${id}`;

    return axiosInstance.delete(api);
  }

  static editUnit(item) {
    let api;
    api = API_PATHS.editunits;

    return axiosInstance.put(api, item);
  }

  //combinations
  static getcominationDetails(id) {
    let api = "";

    api = API_PATHS.getcombinations + "?id=" + id;
    return axiosInstance.get(api);
  }

  static getcombinations(item) {
    let api = "";
    api = `${API_PATHS.getcombinations}?courseId=${item.courseId}`;
    return axiosInstance.get(api);
  }

  static getcombinated(item) {
    let api = "";
    api = `${API_PATHS.getcombinations}?courseId=${item.courseId}`;
    return axiosInstance.get(api);
  }

  static postCombinations(item) {
    let api = API_PATHS.addcombinations;

    return axiosInstance.post(api, item);
  }

  static deleteCombinations(item) {
    let api = `${API_PATHS.deletecombinations}?id=${item.id}`;
    return axiosInstance.delete(api, item);
  }

  static editCombinations(item) {
    let api = "";
    api = API_PATHS.editcombinations;
    return axiosInstance.put(api, item);
  }

  //chapters
  static getChaptersDetails(id) {
    let api = "";

    api = API_PATHS.getchapters + "?id=" + id;
    return axiosInstance.get(api);
  }

  static getChapters(item) {
    let api = "";
    api = `${API_PATHS.getchapters}?combinationId=${item.id}`;
    return axiosInstance.get(api);
  }

  static postChapters(item) {
    let api = API_PATHS.addchapters;

    return axiosInstance.post(api, item);
  }

  static deleteChapters(item) {
    let api = `${API_PATHS.deletechapters}?id=${item.id}`;

    return axiosInstance.delete(api);
  }

  static editChapters(item) {
    let api;
    api = API_PATHS.editchapters;
    return axiosInstance.put(api, item);
  }

  //materials
  static postmaterial(item) {
    let api = API_PATHS.addmaterials;
    return axiosInstance.post(api, item);
  }

  static getmaterial(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getmaterials}?unitId=${id}`;
    } else {
      api = `${API_PATHS.getmaterials}`;
    }

    return axiosInstance.get(api);
  }

  static getsinglematerial(item) {
    let api;

    api = `${API_PATHS.getmaterials}?unitId=${item.id1}&id=${item.id2}`;
    return axiosInstance.get(api);
  }

  static editmaterial(item) {
    let api = API_PATHS.editmaterials;
    return axiosInstance.put(api, item);
  }

  static deletematerial(id) {
    let api = `${API_PATHS.deletematerials}?id=${id}`;
    return axiosInstance.delete(api);
  }

  static getPretest(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getpretest}?unitId=${id}`;
    } else {
      api = `${API_PATHS.getpretest}`;
    }

    return axiosInstance.get(api);
  }

  static editPretest(item) {
    let api = API_PATHS.editpretest;
    return axiosInstance.put(api, item);
  }
  static getPretestByUnitId(item) {
    let api;

    api = `${API_PATHS.getpretest}?unitId=${item.id1}&id=${item.id2}`;

    return axiosInstance.get(api);
  }

  static deletePretest(id) {
    let api = `${API_PATHS.deletepretest}?id=${id}`;

    return axiosInstance.delete(api);
  }

  //fundamental finaltest services
  static postfinaltests(item) {
    let api = API_PATHS.addfinaltests;
    return axiosInstance.post(api, item);
  }

  static getfinaltest(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getfinaltests}?unitId=${id}`;
    } else {
      api = `${API_PATHS.getfinaltests}`;
    }

    return axiosInstance.get(api);
  }

  static getsinglefinaltests(item) {
    let api;

    api = `${API_PATHS.getfinaltests}?unitId=${item.id1}&id=${item.id2}`;
    return axiosInstance.get(api);
  }

  static editfinaltest(item) {
    let api = API_PATHS.editfinaltests;

    return axiosInstance.put(api, item);
  }

  static deletefinaltest(id) {
    let api = `${API_PATHS.deletefinaltets}?id=${id}`;

    return axiosInstance.delete(api);
  }
  static getUnitPretest(id) {

    let api;
    if (id) {
      api = `${API_PATHS.getUnitPretest}?unitId=${id}`;
    } else {
      api = `${API_PATHS.getUnitPretest}`;
    }
    return axiosInstance.get(api);
  }

  static postPretest(item) {
    let api = API_PATHS.postPretest;
    return axiosInstance.post(api, item);
  }

  static deleteUnitPretest(id) {
    let api = `${API_PATHS.deleteUnitPretest}?id=${id}`;
    return axiosInstance.delete(api);
  }

  static editUnitPretest(item) {
    let api = API_PATHS.editUnitPretest;
    return axiosInstance.put(api, item);
  }

  static getSingleUnitPretest(id) {
    let api;

    if (id) {
      api = `${API_PATHS.getUnitPretest}?unitId=${id}`;
    } else {
      api = `${API_PATHS.getUnitPretest}`;
    }

    return axiosInstance.get(api);
  }

  static getSeries(item) {
    let api = "";
    api = `${API_PATHS.getSeries}?combinationId=${item.id}`;
    return axiosInstance.get(api, item);
  }

  static addSeries(item) {
    let api = API_PATHS.addSeries;
    return axiosInstance.post(api, item);
  }

  static deleteSeries(item) {
    let api = `${API_PATHS.deleteSeries}?id=${item.id}`;

    return axiosInstance.delete(api);
  }

  static editSeries(item) {
    let api = "";
    api = API_PATHS.editSeries;

    return axiosInstance.put(api, item);
  }

  static getSeriesDetails(id, id1) {
    let api;

    api = `${API_PATHS.getSeries}?combinationId=${id}&id=${id1}`;
    return axiosInstance.get(api);
  }

  static getSeriesTest(item) {
    let api;
    if (item) {
      api = `${API_PATHS.getSeriesTest}?seriesId=${item.id}`;
    } else {
      api = `${API_PATHS.getSeriesTest}`;
    }
    return axiosInstance.get(api);
  }

  static getSeriesTestDetails(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getSeriesTest}?seriesId=${id}`;
    } else {
      api = `${API_PATHS.getSeriesTest}`;
    }
    return axiosInstance.get(api);
  }

  static postSeriesTest(item) {
    let api = API_PATHS.postSeriesTest;
    return axiosInstance.post(api, item);
  }

  static editSeriesTest(item) {
    let api = API_PATHS.editSeriesTest;
    return axiosInstance.put(api, item);
  }

  static addMoreSeriesTest(item) {
    let api = `${API_PATHS.AddMoreSeriesTest}?id=${item.id}`;
    return axiosInstance.patch(api, item);
  }

  static deleteSeriesTest(id) {
    let api = `${API_PATHS.deleteSeriesTest}?id=${id}`;

    return axiosInstance.delete(api);
  }

  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default CourseService;
