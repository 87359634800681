import React, { useState } from "react";
import { Col, Row, Container } from "reactstrap";
import { Form } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LineChart from "./LineChart";
import { getActivity } from "../../store/slices/paid";

function Dashboard() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [totalHourlydata, setTotalHourlydata] = useState();
  const [dateError, setDateError] = useState("");
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 12;

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [date, setDate] = useState(formatDate(new Date()));

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   GetActivity();
  // }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/login");
  //   } else {
  //     const timeout = setTimeout(() => {
  //       setIsLoading(false);
  //     }, 600);
  //     return () => clearTimeout(timeout);
  //   }
  // }, [navigate]);

  const item = {
    id: id,
    activity: "login",
    limit: itemsPerPage,
    page: currentPage,
    date: date,
  };

  const GetUserActivity = () => {
    setLoading(true);
    dispatch(getActivity(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setData(data?.activity?.data);
        setTotaldata(data?.activity?.totalDataCount);
        setTotalHourlydata(data?.activity?.totalHourlyData[0]?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handledateChange = (e) => {
    setDate(e.target.value);
    setDateError("");
  };

  const handleResetFilter = (e) => {
    e.preventDefault();
    setDate("");
  };
  const handleApplyFilter = (e) => {
    e.preventDefault();
    GetUserActivity();
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* {loading ? (
              <SkeletonCard />
            ) : ( */}
            <div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title-wrap bar-success d-flex align-items-center">
                    <h5 class="card-title"> Dashboard</h5>
                  </div>
                </div>
                <div className="p-5" style={{ maxHeight: "100px" }}>
                  <Form>
                    <Row className="d-flex flex-row  ps-2 pe-2 justify-content-left ">
                      <Col
                        lg={4}
                        md={6}
                        xs={6}
                        className="d-flex flex-column mb-2"
                      >
                        <Form.Group>
                          <label className="m-1 label">Date</label>
                          <Form.Control
                            name="nameEmailOrMobile"
                            placeholder="date"
                            type="date"
                            value={date}
                            isInvalid={!!dateError}
                            onChange={handledateChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {dateError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col
                        lg={4}
                        md={6}
                        xs={6}
                        className="d-flex flex-column mb-2"
                      >
                        <div className="group d-flex justify-content-center mt-4 ">
                          {/* <button
                              className="btn btn-outline-danger"
                              onClick={handleResetFilter}
                            >
                              Reset
                            </button> */}
                          <button
                            className="btn btn-info ms-2"
                            onClick={handleApplyFilter}
                          >
                            Search
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div
                  style={{ width: "95%", padding: "3%", minHeight: "500px" }}
                >
                  {!totalHourlydata ? (
                    <div
                      style={{
                        display: " flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                      }}
                    >
                      <h4 className="text-center">
                        No activities found for this date
                      </h4>
                    </div>
                  ) : (
                    <LineChart Hourlydata={totalHourlydata} />
                  )}
                </div>
              </div>
            </div>
            {/* )} */}
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default Dashboard;
