import React from "react";
import learnlogo from "../../assets/life.png"
import {  useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../store/slices/auth";
import backgroundImage from "../../assets/images/bg.jpg";

import * as Yup from "yup";
import { useFormik } from "formik";

const Login = (props) => {
  document.title = "LIFE Admin";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '' || '',
      password: '' || '',
      roles:"superadmin"
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(login(values))
        .unwrap()
        .then((res) => {
          if (res?.user?.token) {
            localStorage.setItem("token", res?.user?.token);
            localStorage.setItem("roles", res?.user?.roles[0].name);

            navigate("/dashboard");
          } else {
            navigate("/auth-login");
          }
        })
        .catch((message) => {
          console.log(message)
          alert(message.message);
        });
    },
  });

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages ">
      <div
      className="container-fluid d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={learnlogo}
                          alt=""
                          height="32"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={learnlogo}
                          alt=""
                          height="60"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">
                      Welcome Back!
                    </h4>
                    <p className="mb-5 text-center">
                      Sign in to continue
                    </p>
                    <Form className="form-horizontal" onSubmit={validation.handleSubmit}>
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <label className="form-label" htmlFor="email">
                              Username
                            </label>
                            <Input
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Enter username"
                              value={validation.values.email}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.email && !!validation.errors.email}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <div className="text-danger">{validation.errors.email}</div>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <label className="form-label" htmlFor="password">
                              Password
                            </label>
                            <Input
                              type="password"
                              id="password"
                              name="password"
                              placeholder="Enter password"
                              value={validation.values.password}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.password && !!validation.errors.password}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <div className="text-danger">{validation.errors.password}</div>
                            ) : null}
                          </div>

                         
                          <div className="d-grid mt-4">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                
                <p className="text-white-50">
                  © {new Date().getFullYear()} Life. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Ocatl Optimum
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
