import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Button, Col, Container, Table } from "reactstrap";
import { deleteFaq, getFaq } from "../../store/slices/faq";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";

function Faq() {
  const [faq, setFaq] = useState([]);
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 25;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const navigate = useNavigate();

  const handleEditFaqClick = (id) => {
    navigate(`/editfaq/${id}`);
  };

  const handleAddFaqClick = () => {
    navigate("/addfaq");
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    GetFaq(item);
  }, [itemsPerPage, currentPage]);

  const GetFaq = (item) => {
    setLoading(true);
    dispatch(getFaq(item))
      .unwrap()
      .then((data) => {
        console.log({ data });
        setFaq(data?.faq?.data);
        setTotaldata(data?.faq?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const HandleDelete = () => {
    const deleteItem = {
      id: deleteId,
    };
    dispatch(deleteFaq(deleteItem))
      .unwrap()
      .then((data) => {
        alert(data?.faq?.message);
        setShowModal(false);
        GetFaq(item);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={HandleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp; &#8811; FAQ
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">FAQ</h4>
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={handleAddFaqClick}
                  >
                    Add FAQ{" "}
                  </button>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !faq?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No questions were added to this FAQ.</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead class="responseHeaderStyle">
                        <tr>
                          <th className="text-center">Question</th>
                          <th className="text-center">Answer</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {faq?.map((faqs, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="text-center align-middle">
                            <div
                            dangerouslySetInnerHTML={{
                              __html: faqs.question.replace(
                                '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
                                ""
                              ),
                            }}
                          />
                            </td>
                            <td className="text-center align-middle">
                            <div
                            dangerouslySetInnerHTML={{
                              __html: faqs.answer.replace(
                                '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
                                ""
                              ),
                            }}
                          />
                            </td>

                            <td
                              style={{ minWidth: "200px" }}
                              className="text-center align-middle"
                            >
                              <button
                                className="btn btn-outline-info btn-sm"
                                onClick={() => handleEditFaqClick(faqs._id)}
                              >
                                <MdEdit className="viewbtn" />
                              </button>

                              <button
                                className="btn btn-outline-danger  btn-sm ms-2"
                                onClick={() => handleDeleteClick(faqs._id)}
                              >
                                  <MdDelete className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
              <div className="mr-5">
                {totaldata / itemsPerPage > 1 && (
                  <div className="mt-5 d-flex justify-content-end align-right">
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(totaldata / itemsPerPage)}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Faq;
