import React, { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getActivity } from "../../store/slices/paid";
import { Modal, Button, Container, Table, Col } from "reactstrap";
import ReactPaginate from "react-paginate";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";

const Activity = () => {
  const teamHeaders = ["Name", "Email", "MobileNo", "Date ", "Activity"];

  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 25;
  const pagesToShowInitially = 3;
  const [date, setDate] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  const handleClose = () => setModalShow(false);
  const item = {
    id: id,
    activity: "login",
    limit: itemsPerPage,
    page: currentPage,
    date: date,
  };

  useEffect(() => {
    GetActivity();
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const GetActivity = () => {
    setLoading(true);
    dispatch(getActivity(item))
      .unwrap()
      .then((data) => {
        setData(data?.activity?.data);
        setTotaldata(data?.activity?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811; Activity
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Activity</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No data is added.</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead class="responseHeaderStyle">
                        <tr>
                          {teamHeaders.map((header, index) => (
                            <th
                              className="text-center align-middle"
                              key={index}
                              style={{ backgroundColor: "white" }}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td className="text-center align-middle">
                                <Link to={`/userDetails/${row?.userId?._id}`}>
                                  {row?.userId?.name}
                                </Link>
                              </td>
                              <td className="text-center align-middle">
                                {row?.userId?.email}
                              </td>
                              <td className="text-center align-middle">
                                {row?.userId?.mobileNo}
                              </td>
                              <td className="text-center align-middle">
                                {formatDate(row.createdAt)}
                              </td>
                              <td className="text-center align-middle">
                                {row.activity}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
                </div>

                <div class="mr-5">
                  {totaldata / itemsPerPage > 1 && (
                    <div className=" d-flex justify-content-end align-right">
                      <ReactPaginate
                        key={currentPage}
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakLinkClassName={"page-link"}
                        pageCount={Math.ceil(totaldata / itemsPerPage)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={pagesToShowInitially}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        disabledClassName="disabled"
                        initialPage={currentPage - 1}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>

      <Modal show={modalShow}>
        <Modal.Header>
          <Modal.Title style={{ marginBottom: "20px", marginTop: "10px" }}>
            Sponsor Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalData && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Name:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.name}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Email:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.email}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Phone Number:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.mobileNo}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Sponsor Code:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.sponsorCode}</p>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn saveBtn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Activity;
