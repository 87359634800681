import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AssessmentsService from "../../services/assessments.services"

export const AddAssessment = createAsyncThunk(
    "/post/Assessments",
    async (item, thunkAPI) => {
      try {
        const data = await AssessmentsService.addAssessments(item);
        return { assessments: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const EditAssessments = createAsyncThunk(
    "/edit/Assessments",
    async (item, thunkAPI) => {
      try {
        const data = await AssessmentsService.editAssessments(item);
        return { assessments: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const GetAssessments = createAsyncThunk(
    "/get/Assessments",
    async (item, thunkAPI) => {
      try {
        const data = await AssessmentsService.getAssessments(item);
        return { assessments: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const DeleteAssessments = createAsyncThunk(
    "/delete/Assessments",
    async (id, thunkAPI) => {
      try {
        const data = await AssessmentsService.deleteAssessments(id);
        return { assessments: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  
  
const initialState = {
    loading: false,
    error: "",
  plas :[]||null,
    isLoggedIn: false,
};

const Assessments = createSlice({
    name: "assesments",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = Assessments;
export default reducer;




  


