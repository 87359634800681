import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import learnlogo from "../../assets/life.png"


//i18n
import { withTranslation } from "react-i18next";



const Header = (props) => {

  


  const tToggle = () => {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={learnlogo} alt="logo-sm-dark" height="70px"  width="70px"/>
                </span>
                <span className="logo-lg">
                  <img src={learnlogo} alt="logo-dark" height="70px"  width="70px"/>
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={learnlogo} alt="logo-sm-light" height="70px" width="70px" />
                </span>
                <span className="logo-lg">
                  <img src={learnlogo} alt="logo-light" height="70px"   width="70px"/>
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={tToggle}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>

            
          </div>

          <div className="d-flex">
           

    
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default withTranslation()(Header);
