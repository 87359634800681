import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Container } from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import {
  editfundamentalMaterial,
  getfundamentalMaterials,
  getsinglefundamentalMaterials,
  postfundamentalMaterial,
} from "../../../store/slices/fundamentals";
import TextEditor from "./Editor";
import FileUpload from "../../../utils/helpers/Fileupload";
import { Logout } from "../../../utils/helpers/Logout";

const EditFundamentalMaterial = () => {
  const { id1, id2 } = useParams();
  localStorage.setItem("unitId", id1);
  let unitId = localStorage.getItem("unitId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [textError, setTextError] = useState("");
  const [preview, setPreview] = useState(false);
  const [urlError, setUrlError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState({});
  const [editorLoaded, setEditorLoaded] = useState(false);
  const data1 = JSON.parse(localStorage.getItem("units"))
const [data2,setData2] = useState()


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "materialName":
        const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, '');

        value = filteredValue;

        const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;

        if (filteredValue === "") {
          setNameError("Please enter material name");
        } else if (!categorynameregex.test(filteredValue)) {
          setNameError("Invalid characters in material name");
        } else {
          setNameError("");
        }
        break;
      case "materialNumber":
        setNumberError(
          value === ""
            ? "Please enter material Number"
            : value <= 0
            ? "Please enter valid Number"
            : ""
        );
        value = value < 1 ? "" : value;
        break;
      case "url":
        setUrlError(value === "" ? "Please enter Url" : "");
        break;
      case "description":
        setTextError(value === "" ? "" : "");
        break;
      default:
        break;
    }
    setData({
      ...data,
      [name]: value,
    });
  };

  console.log(data);

  React.useEffect(() => {
    const item = {
      id1: id1,
      id2: id2,
    };
    dispatch(getsinglefundamentalMaterials(item))
      .unwrap()
      .then((data) => {

        const initialData = data.fundamentalmatrial.data[0];
        setData2(initialData)
        setData({
          materialName: initialData?.materialName || "",
          materialNumber: initialData?.materialNumber || "",
          url: initialData?.url || "",
          description: initialData?.description || "",
        });
        setEditorLoaded(true);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const save = (e) => {
    if (data.materialName === "") {
      setNameError("Please enter material name");
    } else if (data.materialNumber === "") {
      setNumberError("Please enter material number");
    } else if (data.materialNumber <= 0) {
      setNumberError("Please enter valid number");
    } else if (data.description === "") {
      setTextError("Please enter description");
    } else {
      upload();
    }
  };

  const clear = () => {
    setData({
      materialName: "",
      materialNumber: "",
      url: "",
      description: "",
      unitId: unitId,
      status: 1,
      type: "pdf",
    });
    setButtonDisabled(!buttonDisabled);
    setShowModal(true);
  };

  const togglePreview = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const urlChange = async (e) => {
    try {
      const val = await FileUpload(e);
      setData({
        ...data,
        url: val,
      });
      setUrlError("");
    } catch (error) {
      alert("File Size is Large");
    }
  };

  const upload = () => {
    const item = {
      id: id2,
      materialName: data?.materialName,
      materialNumber: data.materialNumber,
      description: data.description,
      url: data.url,
      unitId: id1,
      status: 1,
      type: "pdf",
    };

    dispatch(editfundamentalMaterial(item))
      .unwrap()
      .then((res) => {
        if (res.fundamentalmatrial.data) {
          alert(res.fundamentalmatrial.message);
          navigate("/fundamentals/materials/" + unitId);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const cancelHandler = () => {
    navigate(`/fundamentals/materials/${id1}`);
  };
  const fundchapterid = localStorage.getItem("fundmentalchapterId");
  const fundunitid = localStorage.getItem("fundmentalunitId");

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/home">Home </Link>&#8811;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
              &#8811;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>
              &#8811;
              <Link to={`/fundamentals/units/${fundunitid}`}>Units </Link>
              &#8811;
              <Link to={`/fundamentals/materials/${id1}`}>Material </Link>
              &#8811; Edit Material
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">    {data1?.subjectId?.subjectName}  &nbsp;&#8811;&nbsp;
                  {data1?.chapterId?.chapterName}  &nbsp;&#8811;&nbsp; {data2?.unitId?.unitName} </h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Name:</label>
                      <input
                        type="text"
                        name="materialName"
                        className="form-control"
                        placeholder="Topic Name"
                        value={data.materialName}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{nameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Number:</label>
                      <input
                        type="number"
                        name="materialNumber"
                        className="form-control"
                        placeholder="Topic Number"
                        onChange={handleChange}
                        value={data.materialNumber}
                      />
                      <p className="alert-message">{numberError}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Url:</label>
                      <br />

                      <input
                        type="file"
                        name="url"
                        className="form-control mt-2"
                        placeholder="Topic Url"
                        onChange={urlChange}
                      />
                      <p className="alert-message">{urlError}</p>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="bread-head1">Topic Description:</label>
                      <button
                        type="button"
                        className="btn saveBtn col-white my-3"
                        onClick={togglePreview}
                      >
                        {preview ? "Edit" : "Preview"}
                      </button>
                    </div>

                    <TextEditor
                      data={data}
                      setData={setData}
                      handleChange={handleChange}
                    />

                    {preview && (
                      <div className="p-3">
                        <h5>Preview:</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.materialUrl,
                          }}
                        />
                      </div>
                    )}
                    <p className="alert-message">{textError}</p>
                  </div>

                  <div
                    className="col-12 d-flex  justify-content-center "
                    style={{ marginTop: "60px" }}
                  >
                    <button
                      className="btn btn-outline-info wd-100 "
                      onClick={cancelHandler}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100 ms-2"
                      disabled={buttonDisabled}
                      onClick={save}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default EditFundamentalMaterial;
