import React from 'react'
import { Link } from 'react-router-dom';
import { Button ,Container,Table} from 'reactstrap';

 function ViewResponse() {
  const teamHeaders = ['Current Affairs Date','Question','Correct Option','Selected Option', 'True / False']
  const data =[
    {CurrentAffairsDate: '10-12-2022',Question: 'How is your day?', CorrectOption: '1',SelectedOption:'1',TrueFalse:'true'},
    {CurrentAffairsDate: '10-12-2022',Question: 'How is your day?', CorrectOption: '1',SelectedOption:'1',TrueFalse:'true'}
  ];
  const columns = Object.keys(data[0]);
  
  return (
     <>
    <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/dashboard">Home</Link><Link to="/Visitors"  style={{ marginLeft: '5px' }}>
                    &#8811;Visitors</Link>&#8811;ResponseLogs
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Visitors Response</h4>
                        <Button
                        className="btn saveBtn">
                          Visitor Responses
                      </Button>
                      </div>
                    </div>
                    <div class="card-body collapse show">
                      <div class="card-block card-dashboard table-responsive">
                        <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead className='responseHeaderStyle' >
                            <tr>
                            {teamHeaders.map((header, index) => (
                            <th key={index} style={{ backgroundColor: 'white' }}>{header}</th>
                                 ))}
                                 </tr>
                                </thead>
                                  <tbody>
                           {data.map((row, rowIndex) => (
                                 <tr key={rowIndex}>
                                {columns.map((column, colIndex) => (
                               <td key={colIndex}>{row[column]}</td>
                                  ))}
                                 </tr>
                                     ))}
                                   </tbody>
                          </Table>
                      </div>
                   </div>
                 </div>
              </Container>
              </div>
              </React.Fragment>
    </>

  )
}
export default ViewResponse;
