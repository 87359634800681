import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Container } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { getfundamentalUnits, getfundmentalunitsdetails, postfundamentalFinaltest } from "../../../store/slices/fundamentals";
import { useEffect } from "react";
import { Logout } from "../../../utils/helpers/Logout";

function AddFundamentalFinalTest() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const data1 = JSON.parse(localStorage.getItem("units"))
const [data2,setData2] = useState()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    getUnits()
  }, []);

 

const getUnits = () => {
  dispatch(getfundmentalunitsdetails(id))
    .unwrap()
    .then((data) => {
    
      setData2(data?.fundamentalunits?.data[0]);
    })
    .catch(({ message }) => {
      alert(message);
    });
};


  const [questions, setQuestions] = useState(
    [...Array(5)].map((_, index) => ({
      type: "text",
      question: "",
      correctOption: "",
      questionNumber: index + 1,
      explanation: "",
      options: [
        { optionNumber: "1", optionValue: "", correctOption: false },
        { optionNumber: "2", optionValue: "", correctOption: false },
        { optionNumber: "3", optionValue: "", correctOption: false },
        { optionNumber: "4", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
      ],
    }))
  );
  const [errors, setErrors] = useState(
    [...Array(5)].map(() => ({
      question: "",
      questionNumber: "",
      explanation: "",
      correctOption: "",
      options: [
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
      ],
    }))
  );

  
  const handleQuestionChange = (e, questionIndex) => {
    const { name, value } = e.target;
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      if (questionIndex >= 0 && questionIndex < updatedQuestions.length) {
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          [name]: value,
        };
      }

      return updatedQuestions;
    });

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
   
      if (questionIndex >= 0 && questionIndex < updatedErrors.length) {
     
        updatedErrors[questionIndex][name] =
          name === "explanation" && value.trim() === ""
            ? ""
            : value.trim() === ""
            ? ""
            : "";
      }
      return updatedErrors;
    });
  };

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const { name, value, checked } = e.target;

    if (name === "optionNumber" && value !== "" && (value < 1 || value > 6)) {
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] =
          "Option number must be between 1 and 6.";
        return updatedErrors;
      });
      return;
    }

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      const currentQuestion = updatedQuestions[questionIndex];

      if (!currentQuestion) {
        console.error(`Question at index ${questionIndex} is undefined.`);
        return prevQuestions;
      }

      const updatedOptions = currentQuestion.options.map((option, index) => {
        if (index === optionIndex) {
          return {
            ...option,
            [name]: name === "correctOption" ? checked : value,
          };
        }
        return option;
      });

      if (name === "correctOption") {
        currentQuestion.correctOption = checked ? optionIndex + 1 : null;
        updatedOptions.forEach((option, index) => {
          if (option) {
            option.correctOption = index === optionIndex && checked;
          }
        });
      }

      updatedQuestions[questionIndex] = {
        ...currentQuestion,
        options: updatedOptions,
      };

      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] = "";

        if (name === "correctOption" && checked) {
          updatedErrors[questionIndex].options.forEach((option) => {
            option.validationMessage = "";
          });
        }

        return updatedErrors;
      });

      return updatedQuestions;
    });
  };


  const handleAddMainForms = () => {
    const lastQuestionNumber =
      questions.length > 0 ? questions[questions.length - 1].questionNumber : 0;
    const newQuestionNumber = lastQuestionNumber + 1;
    const updatedQuestions = [
      ...questions,
      {
        type: "text",
        question: "",
        correctOption: "",
        explanation: "",
        questionNumber: newQuestionNumber,
        options: [
          { optionNumber: "1", optionValue: "", isCorrect: false },
          { optionNumber: "2", optionValue: "", isCorrect: false },
          { optionNumber: "3", optionValue: "", isCorrect: false },
          { optionNumber: "4", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
        ],
      },
    ];
    const updatedErrors = [
      ...errors,
      {
        question: "",
        correctOption: "",
        questionNumber: newQuestionNumber,
        explanation: "",
        options: [
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
        ],
      },
    ];
    setQuestions(updatedQuestions);
    setErrors(updatedErrors);
  };

  const handleDeleteQuestion = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(questionIndex, 1);

      return updatedQuestions.map((question, index) => ({
        ...question,
        questionNumber: index + 1,
      }));
    });

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors.splice(questionIndex, 1);

      return updatedErrors.map((error, index) => ({
        ...error,
        questionNumber: index + 1,
      }));
    });
  };


  const save = (e) => {
   
    const filledQuestions = questions.filter(
      (question) => question.question.trim() !== ""
    );

    if (filledQuestions.length === 0) {
      alert("Please fill at least one question");
      return;
    }

    const updatedErrors = filledQuestions.map((question, qIndex) => {

      const isAnyCheckboxSelected = question.correctOption;
      const isValidOrder = question.questionNumber !== "" || parseInt(question.questionNumber) > 0;

      const isValidOption =
        question.options.length >= 2 &&
        question.options.filter(
          (option) =>
            option.optionNumber.trim() !== "" &&
            option.optionValue.trim() !== ""
        ).length >= 2;

      const errors = question.options.map((option, oIndex) => {
        const error = {};

        if (!isValidOption) {
          error.optionNumber = "Please enter option number";
        } else if (
          option.optionNumber !== "" &&
          (parseInt(option.optionNumber) <= 0 ||
            parseInt(option.optionNumber) > 6)
        ) {
          error.optionNumber = "Please enter valid option number";
        } else {
          error.optionNumber = ""; 
        }

       
        error.optionValue =
          isValidOption || option.optionValue !== ""
            ? ""
            : "Please enter option value";

      
        error.validationMessage = isAnyCheckboxSelected
          ? ""
          : "Please select correct option";

        return error;
      });

      return {
        question:
          question.question.trim() !== "" ? "" : "Please enter question",
        questionNumber: isValidOrder ? "" : "Please enter questionNumber",
        options: errors,
      };
    });

    setErrors(updatedErrors);

  
    const hasErrors = updatedErrors.some(
      (error) =>
        error.question !== "" ||
        // error.questionNumber !== "" ||
        error.options.some(
          (opt) =>
            opt.optionNumber !== "" ||
            opt.optionValue !== "" ||
            opt.validationMessage
        )
    );

    if (hasErrors) {
      alert("Please fill in all required fields correctly");
      return;
    } else {

      const validQuestions = questions.filter(
        (question) => question.question.trim() !== ""
      );
      const item = validQuestions.map((question) => {
        const validOptions = question.options.filter(
          (option) =>
            option.optionNumber.trim() !== "" &&
            option.optionValue.trim() !== ""
        );

        return {
          question: question.question,
          questionNumber: question.questionNumber,
          explanation: question.explanation || undefined,
          correctOption: question.correctOption,
          options: validOptions, 
        };
      });

      const qitem = {
        unitId: id,
        questions: item,
      };
      setButtonDisabled(true);
      dispatch(postfundamentalFinaltest(qitem))
        .unwrap()
        .then((res) => {
          if (res.finaltest.message) {
            alert(res.finaltest.message);
            navigate(`/fundamentals/finaltest/${id}`);
          }
          setButtonDisabled(false);
        })
        .catch(({ message }) => {
          setButtonDisabled(false);
          alert(message);
        });
    }
  };


  const cancelHandler = () => {
    navigate(`/fundamentals/finaltest/${id}`);
  };

  const fundchapterid = localStorage.getItem("fundmentalchapterId");
  const fundunitid = localStorage.getItem("fundmentalunitId");

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/home">Home </Link>&#8811;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
              &#8811;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>
              &#8811;
              <Link to={`/fundamentals/units/${fundunitid}`}>Units </Link>
              &#8811;
              <Link to={`/fundamentals/finaltest/${id}`}>Final Test</Link>
              &#8811; Add Final Test
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                <h4 class="card-title">

{data1?.subjectId?.subjectName}  &nbsp;&#8811;&nbsp;
{data1?.chapterId?.chapterName} &nbsp;&#8811;&nbsp; {data2?.unitName}
</h4>
                  &nbsp;
                  <div className="container-buttons1">
                    <div className="col-12 d-flex justify-content-end mt-1">
                      <button
                        className="btn btn-primay btn-sm"
                        style={{ height: "30px", width: "60px" }}
                        onClick={(e) => handleAddMainForms(e)}
                      >
                        <CiCirclePlus style={{ fontSize: "20px" }} />
                      </button>
                     
                    </div>
                  </div>
                </div>
              </div>

              <section className="form-Questionnaire p-4">
               
                {questions.map((question, qindex) => (
                  <form key={qindex}>
                    <span className="bread-head1 mt-3">
                      Question No - {qindex + 1}
                    </span>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm p-1 "
                      onClick={() => handleDeleteQuestion(qindex)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <CiCircleMinus
                        style={{ fontSize: "20px", color: "red" }}
                      />
                    </button>
                    <div className="row">
                      <div className="col-md-11 col-sm-12 pt-2">
                        <div className="question-explanation-section">
                          <textarea
                            className="form-control"
                            name="question"
                            placeholder="Enter your question"
                            onChange={(e) => handleQuestionChange(e, qindex)}
                            value={question.question}
                          />
                        </div>
                        <p className="alert-message">
                          {errors[qindex]?.question}
                        </p>
                      </div>
                      <div className="col-md-11 col-sm-12 pt-2">
                        <div className="question-explanation-section">
                          <span className="bread-head1 mt-3">Explanation</span>
                          <textarea
                            className="form-control"
                            name="explanation"
                            placeholder="Enter explanation"
                            onChange={(e) => handleQuestionChange(e, qindex)}
                            value={question.explanation}
                          />
                        </div>
                        <p className="alert-message">
                          {errors[qindex]?.explanation}
                        </p>
                      </div>

                      <div className=" d-flex col-md-4 col-sm-12 pt-2 pb-1 col-ml-5">
                       
                        <span className="bread-head1 ">Correct option :</span>
                        <span className="bread-head1 ">
                          {questions[qindex]?.correctOption}
                        </span>
                      </div>

                      <div className="row mt-2">
                        <div className="col-6">
                          <span className="bread-head1">Options</span>
                        </div>
                      </div>
                      <div style={{ paddingBottom: "30px" }}>
                        <div className="row option-section ">
                          {question.options.map((option, optionIndex) => (
                            <div className="col-12" key={optionIndex}>
                              <div className="row">
                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-2 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={`Option No - ${
                                      optionIndex + 1
                                    }`}
                                    value={option.optionNumber}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    name="optionNumber"
                                  />
                                  <p className="alert-message">
                                    {option.optionNumber !== "" && (
                                      <span>
                                        {
                                          errors[qindex]?.options[optionIndex]
                                            ?.optionNumber
                                        }
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-6 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter option ${
                                      optionIndex + 1
                                    }`}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    value={option.optionValue}
                                    name="optionValue"
                                    required
                                  />
                                  <p className="alert-message">
                                    {optionIndex < 2
                                      ? errors[qindex]?.options[optionIndex]
                                          ?.optionValue
                                      : ""}
                                  </p>
                                </div>

                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-2 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="checkbox"
                                    className="mt-2"
                                    label=""
                                    checked={option.correctOption}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    name="correctOption"
                                    id={`correctOption-${qindex}-${optionIndex}`}
                                    disabled={
                                      !option.optionNumber.trim() ||
                                      !option.optionValue.trim()
                                    }
                                  />
                                  <p className="alert-message">
                                    {optionIndex < 2
                                      ? errors[qindex]?.options[optionIndex]
                                          ?.validationMessage
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>
                ))}
              </section>

              <div className="container-buttons">
                <div className="col-12 d-flex  justify-content-center p-2">
                  <button
                    className="btn btn-outline-info wd-100 "
                    onClick={cancelHandler}
                    disabled={buttonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                     className="btn btn-info wd-100 ms-2"
                    disabled={buttonDisabled}
                    onClick={save}
                  >
                     Submit
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default AddFundamentalFinalTest;
