import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Container, Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetCategoryDetails, editCategory } from "../../store/slices/courses";
import { Logout } from "../../utils/helpers/Logout";

function EditCategoery() {
  const [categoryName, setCategoryName] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate("");
  const [categoryError, setCategoryError] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const save = (e) => {
    if (categoryName === "") {
      setCategoryError("Please enter category name");
    } else {
      addData(e);
    }
  };

  const courseChange = (value) => {
    setCategoryName(value);
    setCategoryError("");
  };

  async function addData(e) {
    e.preventDefault();

    setButtonDisabled((prevState) => !prevState);
    setTimeout(() => {
      setButtonDisabled((prevState) => !prevState);
    }, 4000);
    let status = "active";

    let item = {
      name: categoryName,
      status,
      id: id,
    };
    dispatch(editCategory(item))
      .unwrap()
      .then(() => {
        alert("Category updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {});
  }
  useEffect(() => {
    dispatch(GetCategoryDetails(id))
      .unwrap()
      .then((data) => {
        setCategoryName(data?.user?.paginatedData[0]?.name);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  const cancelClick = () => {
    navigate("/category");
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/category">Category</Link>&nbsp;&#8811; Edit Category
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Category</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Category Name</span>
                      <input
                        type="text"
                        placeholder="Enter category name"
                        className="form-control"
                        value={categoryName}
                        onChange={(e) => courseChange(e.target.value)}
                      />
                      <p className="alert-message">{categoryError}</p>
                    </div>
                  </div>

                  <div className="col-md-12" align="center">
                    <button
                      className="btn btn-outline-danger "
                      onClick={cancelClick}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default EditCategoery;
