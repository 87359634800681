import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddSubjects } from "../../store/slices/courses";
import FileUpload from "../../utils/helpers/Fileupload";
import { Logout } from "../../utils/helpers/Logout";
import { categorynameregex } from "../../utils/Regex";

function AddSubject() {
  const [subjectName, setSubjectName] = useState("");
  const [icon, setIcon] = useState("");
  const navigate = useNavigate();
  const [subjectError, setSubjectError] = useState();
  const [iconError, setIconError] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const save = (e) => {
    e.preventDefault();
    let isValid = true;

    if (subjectName === "") {
      setSubjectError("Please enter subject name");
      isValid = false;
    } else if (!categorynameregex.test(subjectName)) {
      setSubjectError("Please enter valid name");
      isValid = false;
    }

    if (icon === "") {
      setIconError("Please upload an icon");
      isValid = false;
    }

    if (!isValid) {
      return;
    } else {
      addData(e);
    }
  };

  async function addData(e) {
    e.preventDefault();

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 4000);

    let item = {
      subjectName,
      status: "active",
      icon,
    };

    dispatch(AddSubjects(item))
      .unwrap()
      .then(() => {
        alert("Subject added successfully");
        navigate("/coursesubjects");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const subjectChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
    setSubjectName(filteredValue);
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;

    if (!categorynameregex.test(filteredValue)) {
      setSubjectError("Please enter a valid name");
    } else {
      setSubjectError("");
    }
  };

  const IconChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setIconError(
          "File size is too large. Please choose a file smaller than 2 MB."
        );
        return;
      }

      try {
        const val = await FileUpload(e);
        console.log("Uploaded file URL or path:", val); // Log to check the returned file URL or path
        setIcon(val); // Set the returned file URL or path
        setIconError(""); // Clear error after successful upload
      } catch (error) {
        console.error("Error during file upload:", error);
        alert("An error occurred while uploading the file");
        setIconError("Error uploading file");
      }
    }
  };

  const cancelClick = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              className="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/subjects">Subjects</Link>&nbsp;&#8811;&nbsp; Add
              Subject
            </div>
            <div className="card pb-5">
              <div className="card-header">
                <div className="card-title-wrap bar-success d-flex align-items-center">
                  <h4 className="card-title">Add Subject</h4>
                </div>
              </div>
              <div className="card-body collapse show">
                <div className="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div className="col-md-5 p-2">
                      <span className="label">Subject Name</span>
                      <input
                        type="text"
                        placeholder="Enter Subject name"
                        className="form-control"
                        value={subjectName}
                        onChange={(e) => subjectChange(e.target.value)}
                      />
                      <p className="alert-message">{subjectError}</p>
                    </div>
                    <div style={{ height: "100%" }} className="col-md-5 p-2">
                      <span className="label">Icon</span>
                      <input
                        type="file"
                        className="form-control"
                        onChange={IconChange}
                      />
                      <p className="alert-message">{iconError}</p>
                    </div>
                  </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={cancelClick}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100 ms-2"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default AddSubject;
