import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PlanService from "../../services/plans.service"


export const Addplans = createAsyncThunk(
    "/post/plans",
    async (item, thunkAPI) => {
      try {
        const data = await PlanService.postplans(item);
        return { plans: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const editPlans = createAsyncThunk(
    "/edit/plans",
    async (item, thunkAPI) => {
      try {
        const data = await PlanService.editplans(item);
        return { plans: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  export const getPlanDetails = createAsyncThunk(
    "/get/plans",
    async (id, thunkAPI) => {
      try {
        const data = await PlanService.getPlanDetails(id);
        return { plans: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const getplans = createAsyncThunk(
    "/get/plans",
    async (item, thunkAPI) => {
      try {
        const data = await PlanService.getallplans(item);
        return { plans: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const deleteplans = createAsyncThunk(
    "/delete/deleteplans",
    async (id, thunkAPI) => {
      try {
        const data = await PlanService.deleteplans(id);
        return { plans: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const Getcategoryplans = createAsyncThunk(
    "categories/get",
    async ( item,thunkAPI) => {
      try {
        const data = await PlanService.getcategories(item);
        return { user: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const AssignPlans = createAsyncThunk(
    "/post/assignplans",
    async (item, thunkAPI) => {
      try {
        const data = await PlanService.assignPlans(item);
        return { plans: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  

  export const upgradePlans = createAsyncThunk(
    "/post/upgrade",
    async (item, thunkAPI) => {
      try {
        const data = await PlanService.upgradePlans(item);
        return { plans: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


const initialState = {
    loading: false,
    error: "",
  plas :[]||null,
    isLoggedIn: false,
};

const Plans = createSlice({
    name: "plans",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = Plans;
export default reducer;




  


