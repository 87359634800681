import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Col, Table, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import { AssignPlans, getplans } from "../../store/slices/plans";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import AssignModal from "../../utils/helpers/Modals/AssignModal";

const AssignPlan = () => {
  const { id } = useParams();
  const teamHeaders = ["Name", "Price", "Description", "Action"];
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [planId, setPlanId] = useState();
  const [loading, setLoading] = useState(false);



  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleopenmodal = (elem) => {
    setShowModal(true);
    setPlanId(elem._id);
  };

 


 
  useEffect(() => {
    GetPlans();
  }, [dispatch]);

  const GetPlans = () => {
    setLoading(true);
    let item ={

    }
    dispatch(getplans(item))
      .unwrap()
      .then((response) => {
        console.log("API Response:", response);
        setData(response.plans.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching plans:", err);
        setLoading(false);
      });
  };


  return (
    <>
      <AssignModal
        showModal={showModal}
        setShowModal={setShowModal}
        planId={planId}
        id={id}
        
      />

      <div>
        <React.Fragment>
          <div className="page-content">
            <Container fluid={true}>
              <div
                class="text-start my-2 ms-1"
                style={{
                  fontWeight: "600",
                }}
              >
                <Link to="/dashboard">Home</Link>&nbsp;&#8811; Assign Plan
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title-wrap bar-success d-flex align-items-center">
                    <h4 class="card-title">Assign Plan</h4>
                  </div>
                </div>

                <div class="card-body collapse show">
                  <div class="card-block card-dashboard table-responsive">
                    {loading ? (
                      <Col lg={12} xs={12} md={12} align="center">
                        {ShowSkeleton()}
                      </Col>
                    ) : !data?.length ? (
                      <div className="mt-4 text-center card-body collapse show pb-3">
                        <h5> No plans were added yet. </h5>
                      </div>
                    ) : (
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead>
                          <tr>
                            {teamHeaders.map((header, index) => (
                              <th
                                className="text-center align-middle"
                                key={index}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => (
                            <tr key={index}>
                              <td className="text-center align-middle">
                                {item.name}
                              </td>
                              <td className="text-center align-middle">
                                {item.price}
                              </td>
                              <td className="text-center align-middle">
                                {item.description}
                              </td>

                              <td
                                style={{ minWidth: "300px" }}
                                className="text-center align-middle"
                              >
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  onClick={() => handleopenmodal(item)}
                                >
                                  Select Plan
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
export default AssignPlan;
