import React, { useState,useEffect } from "react";
import { Link,useSearchParams} from "react-router-dom";
import  {Table,Col,Container} from "reactstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import {  getPendingPayment } from "../../store/slices/paid";
import Moment from "moment";
const Payment = () => {
  const teamHeaders = [
    " Name",
    "Email",
    "Phone Number",
    "Amount",
    "Payment Status",
    "Date of Payment",
  ];
 const [data,setData] = useState([]);
 const dispatch = useDispatch();
 const [totaldata,setTotaldata] = useState();
 const [search, setSearch] = useSearchParams();
 const querypage = parseInt(search.get("page")) || 1;
 const [currentPage, setCurrentPage] = useState(querypage);
 const [loading, setLoading] = useState(true);
 const itemsPerPage = 15;
 const pagesToShowInitially = 2;


  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };
 let paymentStatus=1;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
    paymentStatus:paymentStatus,
  };
  useEffect(() => {
    GetPayment();
  }, [itemsPerPage, currentPage]);

  const GetPayment = () => {
    setLoading(true);
    dispatch(getPendingPayment(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setData(data?.payment?.data);
        setTotaldata(data?.payment?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {});
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/dashboard">Home</Link> &#8811; Payments Completed
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Payments Completed</h4>
                      </div>
                    </div>
                    <div class="card-body collapse show mt-4">
                      <div class="card-block card-dashboard table-responsive">
                      {loading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : !data?.length ? (
                          <div className="mt-4 text-center card-body collapse show pb-3">
                            <h5> No data is added.</h5>
                          </div>
                        ) : ( 
                        <Table responsive hover style={{ overflowX: "scroll" }}>
                          <thead className="responseHeaderStyle">
                            <tr>
                              {teamHeaders.map((header, index) => (
                                <th
                                  key={index}
                                  style={{ backgroundColor: "white" }}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {data && 
                            data.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                              <td>{row.userId.name}</td>
                              <td>{row.userId.email}</td>
                              <td>{row.userId.mobileNo}</td>
                              <td>{row.totalPrice}</td>
                              <td>{row.paymentStatus ==1 ? "Completed" : "Pending"}</td>
                              <td> {Moment.utc(row?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}</td>
                             
                              <td>{row.dateofpayment}</td>
                              </tr>
                            ))}
                        
                          </tbody>
                        </Table>
                        )}
                      </div>
                    
                    
                    <div class="mr-5">
                      {totaldata / itemsPerPage > 1 && (
                        <div className=" d-flex justify-content-end mt-5 align-right">
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={Math.ceil(totaldata / itemsPerPage)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        </div>
                      )}
                    </div>
                    </div>
                    </div>
                    </Container>
                  
                  </div>
                
                </React.Fragment>
             
    </>
  );
};
export default Payment;
