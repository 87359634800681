import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddCourses } from "../../../store/slices/courses";
import FileUpload from "../../../utils/helpers/Fileupload";
import { Logout } from "../../../utils/helpers/Logout";
import { categorynameregex } from "../../../utils/Regex";

function AddCourse() {
  const [courseName, setCourseName] = useState("");
  const [icon, setIcon] = useState("");
  const navigate = useNavigate();
  const [courseError, setCourseError] = useState("");
  const [iconError, setIconError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const save = (e) => {
    e.preventDefault();
    let valid = true;

    if (courseName === "") {
      setCourseError("Please enter course name");
      valid = false;
    }

    if (icon === "") {
      setIconError("Please upload icon");
      valid = false;
    }

    if (!valid) {
      return;
    }

    addData(e);
  };

  const courseChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, '');
    setCourseName(filteredValue );
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
    if (!categorynameregex.test(filteredValue )) {
      setCourseError("Please enter valid name");
    } else {
      setCourseError("");
    }  
  };

  const IconChange = async (e) => {
    try {
      console.log("Uploading file...");
      const val = await FileUpload(e);
      console.log("File uploaded. URL:", val);
      setIcon(val);
      setIconError("");
    } catch (error) {
      console.error("File upload failed:", error);
      alert("File Size is Large");
      setIconError("File Size is Large. Choose a smaller size.");
    }
  };

  async function addData(e) {
    e.preventDefault();

    const item = {
      courseName,
      type: "fulltime",
      status: 1,
      icon,
    };

    setButtonDisabled(true);

    dispatch(AddCourses(item))
      .unwrap()
      .then(() => {
        alert("Course added successfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        console.error("Error adding course:", message);
        alert(message);
        setButtonDisabled(false);
      });
  }

  const cancelClick = () => {
    navigate("/courses");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
            <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
            <Link to="/courses">Courses</Link>&nbsp;&#8811; Add Course
          </div>
          <div className="card">
            <div className="card-header">
              <div className="card-title-wrap bar-success d-flex align-items-center">
                <h4 className="card-title">Add Course</h4>
              </div>
            </div>
            <div className="card-body collapse show">
              <div className="card-block card-dashboard table-responsive">
                <div className="row">
                  <div
                    style={{ height: "100%" }}
                    className="col-md-5 pt-2 pb-2"
                  >
                    <span className="label">Course Name</span>
                    <input
                      type="text"
                      placeholder="Enter course name"
                      className="form-control"
                      value={courseName}
                      onChange={(e) => courseChange(e.target.value)}
                    />
                    <p className="alert-message">{courseError}</p>
                  </div>

                  <div
                    style={{ height: "100%" }}
                    className="col-md-5 pt-2 pb-2"
                  >
                    <span className="label">Icon</span>
                    <input
                      type="file"
                      placeholder="Enter icon URL link"
                      className="form-control"
                      onChange={IconChange}
                    />
                    <p className="alert-message">{iconError}</p>
                  </div>
                </div>

                <div className="col-md-10 mt-3" align="center">
                  <button
                    className="btn btn-outline-info wd-100"
                    onClick={cancelClick}
                    disabled={buttonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info ms-2 wd-100"
                    onClick={save}
                    disabled={buttonDisabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddCourse;
