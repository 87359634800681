import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { GetUnitDetails, postMaterial } from "../../../store/slices/courses";
import ContentEditor from "../../Fundamentals/fundamentalMaterial/ContentEditor";
import FileUpload from "../../../utils/helpers/Fileupload";
import { Logout } from "../../../utils/helpers/Logout";

const AddMaterial = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [textError, setTextError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [breadData, setBreadData] = useState();
  const [data, setData] = useState({
    materialName: "",
    materialNumber: "",
    description: "",
    materialUrl: "test",
    unitId: id,
    status: "active",
    type: "pdf",
  });
  const [editorLoaded, setEditorLoaded] = useState(false);
  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
  }, []);

  const fetchData1 = () => {
    dispatch(GetUnitDetails(id))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      })
      .catch(({ message }) => {});
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "materialName":
        const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
        value = filteredValue;
        const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
        if (filteredValue === "") {
          setNameError("Please enter material name");
        } else if (!categorynameregex.test(filteredValue)) {
          setNameError("Invalid characters in material name");
        } else {
          setNameError("");
        }
        break;
      case "materialNumber":
        setNumberError(
          value === ""
            ? "Please enter material Number"
            : value <= 0
            ? "Please enter valid Number"
            : ""
        );
        value = value < 1 ? "" : value;
        break;
      case "materialUrl":
        setUrlError(value === null ? "Please enter Url" : "");
        break;
      case "description":
        setTextError(value === "" ? "Please enter description" : "");
        break;
      default:
        break;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const urlChange = async (e) => {
    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const file = e.target.files[0];

    if (file.size > maxSizeInBytes) {
      setUrlError("File size exceeds 2MB");
      return;
    } else {
      setUrlError("");
    }

    try {
      const val = await FileUpload(e);
      setData({
        ...data,
        materialUrl: val,
      });
    } catch (error) {
      alert("File Size is Large");
      setUrlError("File Size is Large choose smaller size");
    }
  };

  const save = (e) => {
    e.preventDefault();
    let isValid = true;
    if (data.materialName === "") {
      setNameError("Please enter material name");
      isValid = false;
    }

    if (data.materialNumber === "") {
      setNumberError("Please enter material number");
      isValid = false;
    }
    if (data.materialNumber <= 0) {
      setNumberError("Please enter valid number");
      isValid = false;
    }

    if (!isValid) {
      return;
    } else {
      upload(e);
    }
  };

  const togglePreview = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const upload = (e) => {
    e.preventDefault();
    let item = data;
    setButtonDisabled(true);
    dispatch(postMaterial(item))
      .unwrap()
      .then((res) => {
        if (res.material.data) {
          alert(res.material.message);
          navigate("/materials/" + id);
        }
        setButtonDisabled(false);
      })
      .catch(({ message }) => {
        setButtonDisabled(false);
        alert(message);
      });
  };

  const chapterId = localStorage.getItem("chapterId");
  const combinationId = localStorage.getItem("combinationId");
  const unitId = localStorage.getItem("unitId");
  const materialId = localStorage.getItem("materialId");

  const cancelHandler = () => {
    navigate(`/materials/${id}`);
  };

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>&nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationId}/${chapterId}`}>
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/units/${unitId}`}>Units</Link>
              &nbsp;&#8811;&nbsp;{" "}
              <Link to={`/materials/${materialId}`}>Material</Link>
              &nbsp;&#8811;&nbsp;Add Material
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Add Material</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Name:</label>
                      <input
                        type="text"
                        name="materialName"
                        className="form-control"
                        placeholder="Topic Name"
                        value={data.materialName}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{nameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Number:</label>
                      <input
                        type="string"
                        name="materialNumber"
                        className="form-control"
                        placeholder="Topic Number"
                        onChange={handleChange}
                        maxLength={3}
                        onInput={(e) => {
                          e.target.value = e?.target?.value
                            ?.replace(/[^0-9]/g, "")
                            .slice(0, 3);
                        }}
                        value={data.materialNumber}
                      />
                      <p className="alert-message">{numberError}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Url:</label>

                      <input
                        type="file"
                        name="materialUrl"
                        className="form-control"
                        placeholder="Topic Url"
                        onChange={urlChange}
                      />
                      <p className="alert-message">{urlError}</p>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="bread-head1">Topic Description:</label>
                      <button
                        type="button"
                        className="btn saveBtn col-white my-3"
                        onClick={togglePreview}
                      >
                        Preview
                      </button>
                    </div>

                    {/* <SunTextEditor data={data} setData={setData} handleChange={handleChange} /> */}
                    <ContentEditor
                      data={data}
                      setData={setData}
                      handleChange={handleChange}
                    />

                    <p className="alert-message">{textError}</p>
                  </div>

                  <div
                    className="col-12 d-flex justify-content-center "
                    style={{ marginTop: "60px" }}
                  >
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={cancelHandler}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100 ms-2"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default AddMaterial;
