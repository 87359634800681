import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class AssessmentsService {
  static addAssessments(item) {
    return axiosInstance.post(API_PATHS.addAssessments, item);
  }


  static getAssessments(item) {
    let api;
    if (item.id) {
      api = `${API_PATHS.getAssessments}?id=${item.id}`;
    } else {
      api = `${API_PATHS.getAssessments}?challengeId=${item.challengeId}`;
    }
    return axiosInstance.get(api);
  }



  static editAssessments(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;
    const config = {
      headers: {
        "access-token": token,
      },
    };
    return axiosInstance.put(API_PATHS.editAssessments, item);
  }

  static deleteAssessments(id) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;
    const config = {
      headers: {
        "access-token": token,
      },
    };
    return axiosInstance.delete(`${API_PATHS.deleteAssesments}?id=${id}`);
  }

  
}

export default AssessmentsService;
