import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { getFaqDetails, editFaq } from "../../../store/slices/faq";
import { Logout } from "../../../utils/helpers/Logout";
import FaqTextEditor from "../FaqEditor";
import FaqTextEditor2 from "../FaqEditor2";

function EditFaq (){
  const [question,setQuestion] = useState('');
  const [answer,setAnswer] = useState('');
 const navigate = useNavigate('');
 const  dispatch = useDispatch();
 const {id} = useParams();
 const [questionError, setQuestionError] = useState();
 const [answerError, setAnswerError] = useState();

 useEffect(() =>{
  dispatch(getFaqDetails(id))
  .unwrap()
  .then((data) =>{
      console.log({getData:data});
      setQuestion(data?.faq?.data?.data[0]?.question);
      setAnswer(data?.faq?.data?.data[0]?.answer);
  })
  .catch((err) => {});
  },[dispatch])
  
  useEffect(() => { 
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout()
    }, []);

 const handleSubmit = (e) => {
  e.preventDefault();

  if (question === "") {
    setQuestionError("Please enter question");
  }
  if (answer === "") {
    setAnswerError("Please enter answer ");
  }

  if (!answer || !question) {
    return;
  }
  let item = {
   question,
   answer,
   status:1,
   id:id
  };

  dispatch(editFaq(item))
    .unwrap()
    .then((data) => {
      console.log({data})
      alert("Faq updated successfully");
      navigate("/faq");
    })
    .catch(({ message }) => {
      alert(message)
      console.log({message})
    });
};

 const questionChange = (value) => {
     setQuestion(value);
     setQuestionError("");
   };
   
   const answerChange = (val) => {
     setAnswer(val);
     setAnswerError("");
   };

  const handleCancelClick = () => {
    navigate("/faq");
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to={"/faq"} style={{ marginLeft: "3px" }}>
                FAQ
              </Link>
              &nbsp;&#8811;&nbsp;Edit FAQ
            </div>
            <div class="card">
                           <div class="card-header">
                              <div class="card-title-wrap bar-success d-flex align-items-center">
                                  <h4 class="card-title">Edit FAQ</h4>
                             </div>
                          </div>
                          <div class="card-body collapse show">
                             <div class="card-block card-dashboard table-responsive">
                               <div className="row">
                                <div style={{ height: "100%"}} className="col-md-10 pt-2 pb-2">
                                <span className="label">Question</span>
                                <FaqTextEditor
                      data={question}
                      setData={setQuestion}
                      handleChange={questionChange}
                    />
                                    <p className="alert-message">{questionError}</p>
                                 </div>
                                 <div style={{ height: "100%" }}className="col-md-10 pt-2 pb-2">
                                 <span className="label">Answer</span>
                                 <FaqTextEditor2
                      data={answer}
                      setData={setAnswer}
                      handleChange={answerChange}
                    />
                                     <p className="alert-message">{answerError}</p>
                                  </div>
                              </div>
                  <div className="container-buttons mb-5 me-3">
                  <div className="col-12 d-flex justify-content-center p-2">
                    <button
                      className="btn btn-outline-info wd-100 "
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                       className="btn btn-info wd-100  ms-2"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default EditFaq;


