import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PaidService from "../../services/paid.service";

export const getPaid = createAsyncThunk(
    "paid/get",
    async (item,thunkAPI) =>{
     try {
         const data = await PaidService.getPaid(item);
         return {users: data?.data };
          } catch (error) {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            return thunkAPI.rejectWithValue({ message });
          } 
    }
);

export const GetUserCourses = createAsyncThunk(
  "paid/getusercourses",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getUserCourses(item);
       return {users: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);


export const GetSeriestestLogs = createAsyncThunk(
  "paid/getseriestestlogs",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getSeriesLogs(item);
       return {users: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);

export const GetPaidUserDetails = createAsyncThunk(
  "paid/getdetails",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getPaidUserDetails(item);
       return {paid: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);


export const GetUserStats= createAsyncThunk(
  "paid/GetUserStats",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getUserStats(item);
       return {data: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);

export const getUnPaid = createAsyncThunk(
  "paid/get",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getUnPaid(item);
       return {users: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);
export const getPayment = createAsyncThunk(
  "payment/get",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getPayment(item);
       return {payment: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);

export const getPendingPayment = createAsyncThunk(
  "payment/get",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getPendingPayment(item);
       return {payment: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);

export const getActivity = createAsyncThunk(
  "activity/get",
  async (item,thunkAPI) =>{
   try {
       const data = await PaidService.getActivity(item);
       return {activity: data?.data };
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return thunkAPI.rejectWithValue({ message });
        } 
  }
);

const initialState = {
    loading: false,
    error: "",
    paid :[]||null,
    isLoggedIn: false,
};
const paid = createSlice({
    name: "paid",
    initialState,
    extraReducers: {
   
    },
});
const { reducer } = paid;
export default reducer;
