import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {Container} from "reactstrap"
import {getSeriesDetails,editSeries } from "../../store/slices/courses";

function EditSeries() {
  const { id, id1 } = useParams();
  const [seriesName, setSeriesName] = useState("");
  const [seriesNumber, setSeriesNumber] = useState("");
  const [loading, setLoading] = useState("idle");
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [seriesNameError, setSeriesNameError] = useState();
  const [seriesNumberError, setSeriesNumberError] = useState();
  const [buttonDisabled, setButtonDisabled] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (seriesName === "") {
      setSeriesNameError("Please enter series name");
    }
    if (seriesNumber === "") {
      setSeriesNumberError("Please enter series number ");
    } else if (seriesNumber <= 0) {
      setSeriesNumberError("Please enter valid series number");
    }

    if (!seriesNumber || !seriesName) {
      return;
    }

    let item = {
        seriesName,
        seriesNumber: parseInt(seriesNumber),
        status: 1,
        combinationId: id,
        id: id1,
      };
    
      dispatch(editSeries(item))
      .unwrap()
      .then((data) => {
    
        alert("Series updated succesfully..");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const seriesNameChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, '');
    setSeriesName(filteredValue );
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
    if (!categorynameregex.test(filteredValue )){
      setSeriesNameError("Please enter valid Name"); 
    }else{
      setSeriesNameError("");
    }
   
  };
  const seriesNumberChange = (val) => {
    if (val <= 0) {
      setSeriesNumberError("Please enter valid series number");
    } else {
      setSeriesNumberError("");
    }
    setSeriesNumber(val);
  };

  useEffect(() => {
    dispatch(getSeriesDetails({id,id1}))
      .unwrap()
      .then((data) => {
       console.log(data)
        setSeriesName(data?.user?.data[0]?.seriesName);
        setSeriesNumber(data?.user?.data[0]?.seriesNumber);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, []);
  
  const combinationId = localStorage.getItem("combinationId");


  const cancelHandler = () => {
    navigate(`/series/${id}`);
  };


  return (
    <>
       <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                   <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
             
              <Link to={`/series/${id}`}>Series</Link>
                    &nbsp;&#8811;&nbsp; Edit Series
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                        <h4 class="card-title">Edit Series</h4>
                      </div>
                    </div>

                    <div class="card-body collapse show">
                      <div class="card-block card-dashboard table-responsive">
                        <div>
                          <div className="row">
                            <div
                              style={{ height: "100%" }}
                              className="col-md-5 pt-2 pb-2"
                            >
                              <span className="label">Series Name</span>
                              <input
                                type="text"
                                placeholder="Enter series name"
                                className="form-control"
                                value={seriesName}
                                onChange={(e) =>
                                  seriesNameChange(e.target.value)
                                }
                              />
                              <p className="alert-message">
                                {seriesNameError}
                              </p>
                            </div>

                            <div
                              style={{ height: "100%" }}
                              className="col-md-5 pt-2 pb-2"
                            >
                              <span className="label">Series Number</span>
                              <input
                                  type="string"
                                placeholder="Enter series number"
                                className="form-control"
                                
                                value={seriesNumber}
                                maxLength={3}
                                onInput={(e) => {
                                  e.target.value = e?.target?.value
                                    ?.replace(/[^0-9]/g, "")
                                    .slice(0, 3);
                                }}
                                onChange={(e) =>
                                  seriesNumberChange(e.target.value)
                                }
                              />
                              <p className="alert-message">
                                {seriesNumberError}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-10" align="center">
                            <button
                            className="btn btn-outline-info wd-100" 
                              onClick={cancelHandler}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                                className="btn btn-info wd-100 ms-2"
                              disabled={buttonDisabled}
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Container>
                </div>
                </React.Fragment>
            
    </>
  );
}
export default EditSeries;
