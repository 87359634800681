import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TicketsService from "../../services/tickets.service";




  export const EditTickets = createAsyncThunk(
    "/edit/tickets",
    async (item, thunkAPI) => {
      try {
        const data = await TicketsService.editTickets(item);
        return { tickets : data?.data }; 
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const GetTickets = createAsyncThunk(
    "/get/tickets",
    async (item, thunkAPI) => {
      try {
        const data = await TicketsService.getTickets(item);
        return { tickets : data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );




  
  
const initialState = {
    loading: false,
    error: "",
  plas :[]||null,
    isLoggedIn: false,
};

const ticketSlice = createSlice({
    name: "tickets",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = ticketSlice;
export default reducer;
