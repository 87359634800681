import axios from 'axios';
import { API_BASE_URL } from '../utils/constants/api.constants';
import AuthService from '../services/auth.services';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['access-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const config = {
          headers: {
            'refresh-token': refreshToken,
          },
        };
        const response = await axiosInstance.get('/admin/generate/access-token', config);
        if (response.data) {
          AuthService.setTokens(response.data);
          axiosInstance.defaults.headers['access-token'] = response.data.token;
          originalRequest.headers['access-token'] = response.data.token;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
