import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container, Col,Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import { getContentQuiz,deleteContentQuiz, getCategoryQuiz } from "../../../store/slices/faq";
import ReactPaginate from "react-paginate";
import Moment from "moment";
import { MdDelete,MdEdit } from "react-icons/md";

const ContentQuiz= () => {
  const { id } = useParams();
  const [content, setContent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [contentId, setContentId] = useState();
  const [totaldata, setTotaldata] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [quizType,setQuiztype] = useState();
  const [quizName,setQuizName] = useState();
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;


  const item = {
    id: id,
    limit: itemsPerPage,
    page: currentPage,
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout()
    getQuiz()
  }, []);

  const getQuiz = () => {
 const item ={
  id:id
 }
    dispatch(getCategoryQuiz(item))
      .unwrap()
      .then((data) => {
        setQuiztype(data?.quiz.data[0]?.type);
        setQuizName(data?.quiz.data[0]?.name);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };


  const handleEditClick = (id1) => {
    if(quizType ==="mcq"){
      navigate(`/editcontent/mcq/${id}/${id1}`);
    }else{
    navigate(`/editcontent/${id}/${id1}`);
    }
  };

  
  const handleAddClick = () => {
    if(quizType ==="mcq"){
      navigate(`/addcontent/mcq/${id}`);
    }else{
    navigate(`/addcontent/${id}`);
    }
  };


  useEffect(() => {
    GetContentQuiz();
  }, [dispatch,itemsPerPage, currentPage]);

  const GetContentQuiz = () => {
    setLoading(true);
    dispatch(getContentQuiz(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setContent(data.content?.data || []);
        setTotaldata(data?.content?.totalDataCount);
        
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {});
  };

 

  const handleDelete = () => {
    const deleteItem = {
      id:contentId,
  };
    dispatch(deleteContentQuiz(deleteItem))
      .unwrap()
      .then((data) => {
        console.log(fetch);
        alert(data?.content?.message);
        setShowModal(false);
        GetContentQuiz();
      })
      .catch((errr) => {});
  };
  const handleDeleteClick = (id) => {
    setShowModal(true);
    setContentId(id)
  };


  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
      <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                    <div
                      class="text-start my-2 ms-1"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      <Link to="/home">Home</Link>&nbsp;&#8811;&nbsp;
                      <Link to={"/quiz"}>
                      Quiz
                      </Link>
                      &nbsp;&#8811;&nbsp;Quiz Content
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <h4 class="card-title">{quizName}</h4>&nbsp;
                           
                            <button 
                              className="btn btn-primary ms-2"
                              onClick={handleAddClick}
                            >
                              Add Content
                            </button> 
                          </div>

                        </div>
                      </div>

                      <div class="card-body collapse show">
                        <div class="card-block card-dashboard table-responsive">
                          {loading ? (
                            <Col lg={12} xs={12} md={12} align="center">
                              {ShowSkeleton()}
                            </Col>
                          ) : !content?.length ? (
                            <div className="mt-4 text-center card-body collapse show pb-3">
                              <h5>No content added!</h5>
                            </div>
                          ) : (
                            <Table
                              responsive
                              hover
                              style={{ overflowX: "scroll" }}
                            >
                              <thead>
                                <tr>
                                  
                                  <th className="text-center align-middle">
                                    Date
                                  </th>
                                  <th className="text-center">Action</th>
                                 
                                </tr>
                              </thead>
                              <tbody>
                                {content.map((data, index) => (
                                  <tr key={index}>
                                  
                                    <td className="text-center align-middle">{Moment.utc(data?.date).format(
                                      "DD-MM-YYYY"
                                    )}</td>
                                    <td className="text-center align-middle">
                                    <button
                                className="btn btn-outline-info btn-sm"
                                onClick={() =>handleEditClick(data._id)}
                              >
                                 <MdEdit className="viewbtn"  />
                              </button>
                              <button
                                className="btn btn-outline-danger btn-sm ms-2"
                                onClick={() =>handleDeleteClick(data._id)}
                              >
                                  <MdDelete  className="viewbtn" />
                              </button>

                                    </td>
                                    
                                    
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    
          <div className="mr-5">
            {totaldata / itemsPerPage > 1 && (
              <div className="mt-5 d-flex justify-content-end align-right">
                <ReactPaginate
                  key={currentPage}
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakLinkClassName={"page-link"}
                  pageCount={Math.ceil(totaldata / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={pagesToShowInitially}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  initialPage={currentPage - 1}
                />
              </div>
            )}
          </div>
        </div>
        </Container>
      </div>
    </React.Fragment>
    </div>
    </>
  );
};
export default ContentQuiz;
