import axiosInstance from "../authUtils";
import { API_PATHS } from "../constants/api.constants";

export const FileUpload = async (e) => {
  try {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const response = await axiosInstance.post(API_PATHS.fileUpload, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
      console.log("Parsed response data:", response);
   return response?.data?.data?.location;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export default FileUpload;
