import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form,Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { postSeriesTest } from "../../store/slices/courses";
import { useRef } from "react";
import { useEffect } from "react";
import TextEditor2 from "../../utils/helpers/Editor2";
import TextEditor3 from "../../utils/helpers/Editor3";

function AddSeriesTest() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const [isPretestMandatory, setisPretestMandatory] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const questionRefs = [
    { 0: useRef(null) },
    { 1: useRef(null) },
    { 2: useRef(null) },
    { 3: useRef(null) },
    { 4: useRef(null) },
    { 5: useRef(null) },
    { 6: useRef(null) },
    { 7: useRef(null) },
    { 8: useRef(null) },
    { 9: useRef(null) },
    { 10: useRef(null) },
    { 11: useRef(null) },
    { 12: useRef(null) },
    { 13: useRef(null) },
    { 14: useRef(null) },
    { 15: useRef(null) },
    { 16: useRef(null) },
    { 17: useRef(null) },
    { 18: useRef(null) },
    { 19: useRef(null) },
  ];
  const [questions, setQuestions] = useState(
    [...Array(5)].map(() => ({
      type: "text",
      question: "",
      correctOption: "",
      order: "",
      explanation: "",
      questionNumber: "",
      options: [
        { optionNumber: "1", optionValue: "", correctOption: false },
        { optionNumber: "2", optionValue: "", correctOption: false },
        { optionNumber: "3", optionValue: "", correctOption: false },
        { optionNumber: "4", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
        { optionNumber: "", optionValue: "", correctOption: false },
      ],
    }))
  );
  const [errors, setErrors] = useState(
    [...Array(5)].map(() => ({
      question: "",
      order: "",
      explanation: "",
      correctOption: "",
      options: [
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
        { optionNumber: "", isCorrect: false, optionValue: "" },
      ],
    }))
  );

  const handleQuestionChange = (e, questionIndex) => {
    const { name, value } = e.target;
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];

      if (questionIndex >= 0 && questionIndex < updatedQuestions.length) {
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          [name]: value,
          questionNumber: `${questionIndex + 1}`, 
        };
      }

      return updatedQuestions;
    });

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];

      if (questionIndex >= 0 && questionIndex < updatedErrors.length) {
        updatedErrors[questionIndex][name] =
          name === "explanation" && value.trim() === ""
            ? ""
            : value.trim() === ""
            ? ""
            : "";
      }

      return updatedErrors;
    });
  };

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const { name, value, checked } = e.target;

    if (name === "optionNumber" && value !== "" && (value < 1 || value > 6)) {
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] =
          "Option number must be between 1 and 6.";
        return updatedErrors;
      });
      return;
    }

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      const currentQuestion = updatedQuestions[questionIndex];

      if (!currentQuestion) {
        console.error(`Question at index ${questionIndex} is undefined.`);
        return prevQuestions;
      }

      const updatedOptions = currentQuestion.options.map((option, index) => {
        if (index === optionIndex) {
          return {
            ...option,
            [name]: name === "correctOption" ? checked : value,
          };
        }
        return option;
      });

      if (name === "correctOption") {
        currentQuestion.correctOption = checked ? optionIndex + 1 : null;
        updatedOptions.forEach((option, index) => {
          if (option) {
            option.correctOption = index === optionIndex && checked;
          }
        });
      }

      updatedQuestions[questionIndex] = {
        ...currentQuestion,
        options: updatedOptions,
      };

      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (!updatedErrors[questionIndex]) {
          updatedErrors[questionIndex] = { options: [] };
        }
        if (!updatedErrors[questionIndex].options[optionIndex]) {
          updatedErrors[questionIndex].options[optionIndex] = {};
        }
        updatedErrors[questionIndex].options[optionIndex][name] = "";

        if (name === "correctOption" && checked) {
          updatedErrors[questionIndex].options.forEach((option) => {
            option.validationMessage = "";
          });
        }

        return updatedErrors;
      });

      return updatedQuestions;
    });
  };

 
  

  const handleAddMainForms = () => {
    const lastQuestionNumber =
    questions.length > 0 ? questions[questions.length - 1].questionNumber : 0;
    const newQuestionNumber = lastQuestionNumber + 1;
    const updatedQuestions = [
      ...questions,
      {
        type: "text",
        question: "",
        correctOption: "",
        explanation: "",
        questionNumber: newQuestionNumber,
        options: [
          { optionNumber: "1", optionValue: "", isCorrect: false },
          { optionNumber: "2", optionValue: "", isCorrect: false },
          { optionNumber: "3", optionValue: "", isCorrect: false },
          { optionNumber: "4", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
          { optionNumber: "", optionValue: "", isCorrect: false },
        ],
      },
    ];
    const updatedErrors = [
      ...errors,
      {
        question: "",
        correctOption: "",
        questionNumber: newQuestionNumber,
        explanation: "",
        options: [
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
          { optionNumber: "", isCorrect: false, optionValue: "" },
        ],
      },
    ];
    setQuestions(updatedQuestions);
    setErrors(updatedErrors);
  };

  const handleDeleteQuestion = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(questionIndex, 1);

      return updatedQuestions.map((question, index) => ({
        ...question,
        questionNumber: index + 1,
      }));
    });

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors.splice(questionIndex, 1);

      return updatedErrors.map((error, index) => ({
        ...error,
        questionNumber: index + 1,
      }));
    });
  };

  const handleSwitchChange = (event) => {
    setisPretestMandatory(event.target.checked);
  };
  const save = (e) => {
    e.preventDefault();

    const filledQuestions = questions.filter(
      (question) => question.question.trim() !== ""
    );

    if (filledQuestions.length === 0) {
      alert("Please fill at least one question");

      return;
    }

    const updatedErrors = filledQuestions.map((question, qIndex) => {
      const isAnyCheckboxSelected = question.correctOption !== "";
      const isValidOrder = question.questionNumber !== "";

      const isValidOption =
        question.options.length >= 2 &&
        question.options.filter(
          (option) => option.optionNumber !== "" && option.optionValue !== ""
        ).length >= 2;
        

      return {
        question: question.question !== "" ? "" : "Please enter question",
        questionNumber: isValidOrder ? "" : "Please enter questionNumber",

        options: question.options.map((option, oIndex) => ({
          optionNumber:
            isValidOption || option.optionNumber !== ""
              ? ""
              : "Please enter option number",
          optionValue:
            isValidOption || option.optionValue !== ""
              ? ""
              : "Please enter option value",
          validationMessage: isAnyCheckboxSelected
            ? ""
            : "please select correct option",
        })),
      };
    });

    setErrors(updatedErrors);

    const hasErrors = updatedErrors.some(
      (error) =>
        error.question !== "" ||
        error.options.some(
          (opt) =>
            opt.optionNumber !== "" ||
            opt.optionValue !== "" ||
            opt.validationMessage
        )
    );

    if (hasErrors) {
      alert("Please fill in all required fields correctly");
      return;
    } else {
      const validQuestions = questions.filter(
        (question) => question.question.trim() !== ""
      );
      const item = validQuestions.map((question) => {
        const validOptions = question.options.filter(
          (option) =>
            option.optionNumber.trim() !== "" &&
            option.optionValue.trim() !== ""
        );

        return {
          question: question.question,
          explanation: question.explanation,
          correctOption: question.correctOption,
          questionNumber: question.questionNumber,
          options: validOptions,
        };
      });
      
      
      const qitem = {
        seriesId: id,
        questions: filledQuestions.map((question) => ({
          question: question.question,
          explanation: question.explanation,
          correctOption: question.correctOption,
          questionNumber: question.questionNumber,
          options: question.options.filter(
            (option) =>
              option.optionNumber.trim() !== "" &&
              option.optionValue.trim() !== ""
          ),
        })),
      };
      console.log({ qitem });

      dispatch(postSeriesTest(qitem))
        .unwrap()
        .then((res) => {
          if (res.series.message) {
            alert(res.series.message);
            navigate(-1);
          }
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

 
  const cancelHandler = () => {
    navigate(-1);
  };
  const cancelHandler2 = () => {
    navigate(-2);
  };
  const combinationId = localStorage.getItem("combinationId");
  const seriesId = localStorage.getItem("seriesId");

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                   <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>{" "}
                    &nbsp;&#8811;&nbsp;
                    <Link to={`/series/${seriesId}`}>Series</Link>
                    &nbsp;&#8811;&nbsp;
                    <Link to={`/testseries/${id}`}>Series Test</Link>
                    &nbsp;&#8811;&nbsp;Add test series
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                        <h4 class="card-title">Add Question</h4>
                        &nbsp;
                        <div className="container-buttons1">
                          <div className="col-12 d-flex justify-content-end mt-1">
                            <button
                              className="btn btn-info btn-sm"
                              style={{ height: "30px", width: "60px" }}
                              onClick={(e) => handleAddMainForms(e)}
                            >
                              <CiCirclePlus style={{ fontSize: "24px" }} />
                            </button>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    <section className="form-Questionnaire p-4">
                      <Form.Group
                        controlId="formBasicSwitch"
                        style={{ marginBottom: "2%", marginTop: "-30px" }}
                      >
                        <div className="d-flex justify-content-start  mt-4">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="bread-head1 ms-3"
                            label="Pretest Mandatory"
                            checked={isPretestMandatory}
                            onChange={handleSwitchChange}
                          />
                        </div>
                      </Form.Group>
                      {questions.map((question, qindex) => (
                        <form key={qindex}>
                          <span className="bread-head1">
                            Question No - {qindex + 1}
                          </span>
                          <button
                      type="button"
                      className="btn btn-danger btn-sm p-1 "
                      onClick={() => handleDeleteQuestion(qindex)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <CiCircleMinus
                        style={{ fontSize: "20px", color: "red" }}
                      />
                    </button>
                          <div className="row">
                            <div className="col-md-12 col-sm-12 pt-2">
                              <div className="question-explanation-section">
                                <TextEditor3
                                  question={question}
                                  handleQuestionChange={handleQuestionChange}
                                  questionIndex={qindex}
                                />
                              </div>
                              <p className="alert-message">
                                {errors[qindex]?.question}
                              </p>
                            </div>
                            <div className="col-md-12 col-sm-12 pt-2">
                              <div className="question-explanation-section">
                                <span className="bread-head1 mt-3">
                                  Explanation
                                </span>
                                <TextEditor2
                                  question={question}
                                  handleQuestionChange={handleQuestionChange}
                                  questionIndex={qindex}
                                />
                              </div>
                              <p className="alert-message">
                                {errors[qindex]?.explanation}
                              </p>
                            </div>
                            <div className="d-flex col-md-12 col-sm-12 pt-2 pb-1 col-ml-5">
                              <p className="bread-head1 mt-2">
                                Correct option :&nbsp;
                              </p>
                              &nbsp;
                              <span className="bread-head1  m-2">
                                {questions[qindex]?.correctOption}
                              </span>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <span className="bread-head1">Options</span>
                              </div>
                            </div>
                            <div style={{ paddingBottom: "30px" }}>
                              <div className="row option-section ">
                                {question.options.map((option, optionIndex) => (
                                  <div className="col-12" key={optionIndex}>
                                    <div className="row">
                                      <div
                                        style={{ height: "100%" }}
                                        className="col-md-2 col-sm-12 pt-2 pb-1"
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder={`Option No - ${
                                            optionIndex + 1
                                          }`}
                                          value={option.optionNumber}
                                          onChange={(e) =>
                                            handleOptionChange(
                                              e,
                                              qindex,
                                              optionIndex
                                            )
                                          }
                                          name="optionNumber"
                                        />
                                        <p className="alert-message">
                                          {optionIndex < 2
                                            ? errors[qindex]?.options[
                                                optionIndex
                                              ]?.optionNumber
                                            : ""}
                                        </p>
                                      </div>
                                      <div
                                        style={{ height: "100%" }}
                                        className="col-md-6 col-sm-12 pt-2 pb-1"
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={`Enter option ${
                                            optionIndex + 1
                                          }`}
                                          onChange={(e) =>
                                            handleOptionChange(
                                              e,
                                              qindex,
                                              optionIndex
                                            )
                                          }
                                          value={option.optionValue}
                                          name="optionValue"
                                          required
                                        />
                                        <p className="alert-message">
                                          {optionIndex < 2
                                            ? errors[qindex]?.options[
                                                optionIndex
                                              ]?.optionValue
                                            : ""}
                                        </p>
                                      </div>

                                      <div
                                        style={{ height: "100%" }}
                                        className="col-md-2 col-sm-12 pt-2 pb-1"
                                      >
                                        <input
                                          type="checkbox"
                                          label=""
                                          checked={
                                            question.correctOption !== "" &&
                                            question.correctOption ==
                                              optionIndex + 1
                                          }
                                          onChange={(e) =>
                                            handleOptionChange(
                                              e,
                                              qindex,
                                              optionIndex
                                            )
                                          }
                                          name="correctOption"
                                          id={`correctOption-${qindex}-${optionIndex}`}
                                          disabled={
                                            !option.optionNumber ||
                                            !option.optionValue
                                          }
                                        />
                                        <p className="alert-message">
                                          {optionIndex < 2
                                            ? errors[qindex]?.options[
                                                optionIndex
                                              ]?.validationMessage
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </form>
                      ))}
                    </section>
                    <div className="container-buttons mb-5 me-3">
                      <div className="col-12 d-flex justify-content-center p-2">
                        <button
                         
                            className="btn btn-outline-info wd-100 "
                          onClick={cancelHandler}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-info wd-100  ms-2"
                          disabled={buttonDisabled}
                          onClick={save}
                        >
                         Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  </Container>
                </div>
                </React.Fragment>
    
    </>
  );
}

export default AddSeriesTest;
