import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editfundamentalSubjects,
  getfundamentalSubjectsDetails,
} from "../../../store/slices/fundamentals";
import { useEffect } from "react";
import FileUpload from "../../../utils/helpers/Fileupload";
import { Logout } from "../../../utils/helpers/Logout";

function EditFundamentalSubject() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState("idle");
  const dispatch = useDispatch();
  // const [buttons, setButtons] = useState();
  const [subjectName, setsubjectName] = useState("");
  const [icon, setIcon] = useState("");
  const [subjectError, setSubjectError] = useState();
  const [iconError, setIconError] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const save = (e) => {
    e.preventDefault();
    if (subjectName === "") {
      setSubjectError("Please enter subject name");
    }
    if (icon === "") {
      setIconError("Please enter subject icon");
    }
    if (!icon || !subjectName) {
      return;
    } else {
      addData();
    }
  };

  React.useEffect(() => {
    dispatch(getfundamentalSubjectsDetails(id))
      .unwrap()
      .then((data) => {
        setsubjectName(data.fundamentalsubjects.data[0].subjectName);
        setIcon(data.fundamentalsubjects.data[0].icon);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function addData() {
    setLoading("loading");
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 4000);
    let status = 1;
    let item = {
      subjectName,
      status,
      id: id,
      icon,
    };
    dispatch(editfundamentalSubjects(item))
      .unwrap()
      .then((data) => {
        alert(data.fundamentalsubjects.message);
        navigate("/fundamentals/subjects");
      })
      .catch(({ message }) => {
        setLoading("idle");
        alert(message);
        setButtonDisabled(false);
      });
  }

  async function reset(e) {
    setsubjectName("");
  }

  const subjectChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
    setsubjectName(filteredValue);
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;

    if (!categorynameregex.test(filteredValue)) {
      setSubjectError("Please enter a valid name");
    } else {
      setSubjectError("");
    }
  };

  const IconChange = async (e) => {
    try {
      const val = await FileUpload(e);
      setIcon(val);
      setIconError("");
    } catch (error) {
      alert("File Size is Large");
      setIconError("File Size is Large choose small size");
      // Handle error if needed
    }
  };
  const handleCancelClick = () => {
    navigate("/fundamentals/subjects");
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>
              <Link to="/fundamentals/subjects" style={{ marginLeft: "5px" }}>
                &#8811; Fundamental Subjects
              </Link>
              &nbsp;&#8811; Edit Fundamental Subjects
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Fundamental Subjects</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Subject Name</span>
                      <input
                        type="text"
                        placeholder="Enter subject name"
                        className="form-control"
                        value={subjectName}
                        onChange={(e) => subjectChange(e.target.value)}
                      />
                      <p className="alert-message">{subjectError}</p>
                    </div>

                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Icon</span>
                      <input
                        type="file"
                        placeholder="Enter icon  url link"
                        className="form-control"
                        onChange={IconChange}
                      />
                      <p className="alert-message">{iconError}</p>
                    </div>
                  </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={handleCancelClick}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100  ms-2"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default EditFundamentalSubject;
