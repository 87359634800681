import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Button, Col,Table,Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import { deleteFinaltests, getFinaltest } from "../../store/slices/courses";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import {
  DeleteAssessments,
  GetAssessments,
} from "../../store/slices/assessments";
import { DeleteChallenges, GetChallenges } from "../../store/slices/challenges";

const ViewChallengeDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [totaldata, setTotaldata] = useState();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const handleEditClick = (id2) => {
    navigate(`/edit-challenges/${id2}`);
  };
  const handleAddChallenges = () => {
    navigate(`/add-challenges`);
  };

  const handleViewAssessmentsClick =(id)=>{
    navigate(`/assessments/${id}`)
  }


  useEffect(()=>{
    localStorage.setItem("courseId", id);
  },[])

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    Getchallenges();
  }, [itemsPerPage, currentPage]);

  const Getchallenges = () => {
    setLoading(true);
    const item = {
      page: currentPage,
      limit: itemsPerPage,
      id:id
    };
    dispatch(GetChallenges(item))
      .unwrap()
      .then((data) => {
        console.log(data)
        setData(data.challenges.data);
        setTotaldata(data?.challenges?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {});
  };

  


  return (
    <>
    
      <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                    <div
                      class="text-start my-2 ms-1"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      <Link to="/dashboard">Home</Link>
                      &nbsp;&#8811;&nbsp;<Link to="/challenges">Challenges</Link>&nbsp;&#8811;&nbsp;Challenge Details
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <h4 class="card-title">Challenge Details</h4>

                         
                          </div>
                        </div>
                      </div>

                      <div class="card-body collapse show">
                        <div class="card-block card-dashboard table-responsive">
                          {loading ? (
                            <Col lg={12} xs={12} md={12} align="center">
                              {ShowSkeleton()}
                            </Col>
                          ) : !data?.length ? (
                            <div className="mt-4 text-center card-body collapse show pb-3">
                              <h5> No Challenges were added to this course</h5>
                            </div>
                          ) : (
                            <Table
                              responsive
                              hover
                              style={{ overflowX: "scroll" }}
                            >
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">
                                    Challenge No
                                  </th>
                                  <th className="text-center align-middle">
                                    Title
                                  </th>
                                  <th className="text-center align-middle">
                                    Rules
                                  </th>
                                  <th className="text-center align-middle">
                                    Start Date
                                  </th>
                                  <th className="text-center align-middle">
                                    End Date
                                  </th>
                                  <th className="text-center align-middle">
                                    Last Date
                                  </th>
                                 
                                </tr>
                              </thead>
                              <tbody>
                              {data?.map((item, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td className="text-center align-middle">
                                    {rowIndex+1}
                                  </td>
                                  <td className="text-center align-middle">
                                  {item.title}
                                  </td>
                                  <td className="text-center align-middle">
                                  <div   dangerouslySetInnerHTML={{
                                            __html: item.rules
                                  }}/>
                                  </td>
                                  <td className="text-center align-middle">
                                  {item?.startDate.split('T')[0]}
                                  </td>
                                  <td className="text-center align-middle">
                                  {item?.endDate.split('T')[0]}
                                  </td>
                                  <td className="text-center align-middle">
                                  {item.lastDate.split('T')[0]}
                                  </td>
                                  
                                 
                                </tr>
                              ))}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                   
            
             
          <div className="mr-5">
            {totaldata / itemsPerPage > 1 && (
              <div className="mt-5 d-flex justify-content-end align-right">
                <ReactPaginate
                  key={currentPage}
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakLinkClassName={"page-link"}
                  pageCount={Math.ceil(totaldata / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={pagesToShowInitially}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  initialPage={currentPage - 1}
                />
              </div>
            )}
            </div>
            </div>
                    </Container>
                  </div>
       </React.Fragment>
       </div>
    </>
  );
};
export default ViewChallengeDetails;
