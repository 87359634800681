
const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        isHasArrow: true,
    },
   

  
   

    {
        label: "Users",
        icon: "mdi mdi-account-multiple-plus",
        isHasArrow: true,
        // subItem: [
            
        //     { sublabel: "Paid Users", link: "/paidusers" },
           
            
        //     { sublabel: "Payments", link: "/payments" },
        //     { sublabel: "Pending Payments", link: "/pendingpayments" },
        // ],
        url: "/paidusers",
    },
    
    {
        label: "Courses",
        icon: "mdi  mdi-clipboard-text",
        isHasArrow: true,
        url: "/courses",
    },
    {
        label: "Subjects",
        icon: "mdi  mdi-clipboard-text",
        isHasArrow: true,
        url: "/subjects",
    },  {
        label: "Plans",
        icon: "mdi mdi-bowling",
        isHasArrow: true,
        url: "/plans",
    },  {
        label: "Fundamentals",
        icon: "mdi mdi-arrange-bring-forward",
        isHasArrow: true,
        url: "/fundamentals/subjects",
    },
      {
        label: "Current Affairs",
        icon: "mdi mdi-newspaper",
        isHasArrow: true,
        url: "/currentaffairs",
    }, 
    {
        label: "Quiz",
        icon: "mdi mdi-radioactive",
        isHasArrow: true,
        url: "/quiz",
    }, 
    {
        label: "Support",
        icon: "mdi mdi-ticket-account",
        subItem: [
           
            { sublabel: "Support", link: "/support" },
            { sublabel: "Tickets", link: "/tickets" },
            { sublabel: "FAQs", link: "/faq" },
        ]
    },
    //  {
    //     label: "Activity",
    //     icon: "mdi mdi-radioactive",
    //     isHasArrow: true,
    //     url: "/activity",
    // },
    // {
    //     label: "Alerts ",
    //     icon: "mdi mdi-alert",
    //     isHasArrow: true,
    //     url: "/alerts",
    // },

    // {
    //     label: "Tickets ",
    //     icon: "mdi mdi-ticket",
    //     isHasArrow: true,
    //     url: "/tickets",
    // },
  {
        label: "Logout",
        icon: "mdi mdi-logout",
        isHasArrow: true,
        url: "/auth-login",
    },
    
]
export default SidebarData;