import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Container, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import {
  getSeries,
  deleteSeries,
  GetCombinationsDetails,
} from "../../store/slices/courses";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import { MdDelete, MdEdit } from "react-icons/md";
import { Logout } from "../../utils/helpers/Logout";

function Series() {
  const teamHeaders = ["Series Number", "Series Name", "Test Series", "Action"];
  const [series, setSeries] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [breadData, setBreadData] = useState();
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
  }, []);

  const fetchData1 = () => {
    dispatch(GetCombinationsDetails(id))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      });
  };

  const item = {
    limit: itemsPerPage,
    page: currentPage,
    id: id,
    status: 1,
    // seriesName,
    // seriesNumber,
  };

  const navigate = useNavigate();
  const handleAddSeriesClick = () => {
    navigate(`/addseries/${id}`);
  };
  const handleAddQuestionClick = (id) => {
    navigate(`/testseries/${id}`);


  };

  const handleEditSeriesClick = (id1) => {
    navigate(`/editseries/${id}/${id1}`);
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, itemsPerPage, currentPage]);
  const fetchData = () => {
    setLoading(true);
    dispatch(getSeries(item))
      .unwrap()
      .then((data) => {
        console.log({ data });
        setSeries(data.user.data);
        setTotaldata(data?.user?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {});
  };

  const HandleDelete = () => {
    const item = {
      id: deleteId,
    };
    dispatch(deleteSeries(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        setShowModal(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting series:", error);
        alert("Error deleting series. Please try again.");
      });
  };
  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  useEffect(() => {
    localStorage.setItem("seriesId", id);
  }, []);


  const combinationId = localStorage.getItem("combinationId");
  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={HandleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;Series
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">
                    {breadData?.courseId?.courseName} &#8811;{" "}
                    {breadData?.subjectId?.subjectName} &#8811;{" "}
                  </h4>
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={() => handleAddSeriesClick(id)}
                  >
                    Add Series
                  </button>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !series?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>
                        Series have not yet been added to this combination.{" "}
                      </h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead>
                        <tr>
                          {teamHeaders.map((header, index) => (
                            <th
                              className="text-center align-middle"
                              key={index}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {series.map((series, rowIndex) => (
                          <tr
                            className="text-center align-middle"
                            key={rowIndex}
                          >
                            <td>{series.seriesNumber}</td>
                            <td>{series.seriesName}</td>

                            <td>
                              <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() =>
                                  handleAddQuestionClick(series._id)
                                }
                              >
                                Question
                              </button>
                            </td>

                            <td>
                              <button
                                className="btn btn-sm btn-outline-info"
                                onClick={() =>
                                  handleEditSeriesClick(series._id)
                                }
                              >
                                <MdEdit className="viewbtn" />
                              </button>

                              <button
                                className="btn btn-outline-danger btn-sm ms-2"
                                onClick={() => handleDeleteClick(series._id)}
                              >
                                <MdDelete className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
             

        <div className="mr-5">
          {totaldata / itemsPerPage > 1 && (
            <div className="mt-5 d-flex justify-content-end align-right">
              <ReactPaginate
                key={currentPage}
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakLinkClassName={"page-link"}
                pageCount={Math.ceil(totaldata / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={pagesToShowInitially}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                initialPage={currentPage - 1}
              />
            </div>
          )}
        </div>
        </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Series;
