import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EditIssues, GetissueDetails } from "../../store/slices/faq";
import { Logout } from "../../utils/helpers/Logout";
import FileUpload from "../../utils/helpers/Fileupload";

function EditIssue() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [nameError, setNameError] = useState();
  const [iconError, setIconError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setNameError("Please enter name");
    }
    if (icon === "") {
      setIconError("Please enter icon");
    }

    if (!name || !icon) {
      return;
    }

    let item = {
      name,
      icon,
      id: id,
      status: 1,
    };

    dispatch(EditIssues(item))
      .unwrap()
      .then((data) => {
        alert("Issue is successfully updated");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
        console.log({ message });
      });
  };

  const IconChange = async (e) => {
    try {
      const val = await FileUpload(e);
      setIcon(val);
      setIconError("");
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    GetTickets();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const GetTickets = () => {
    const item = {
      id: id,
    };

    dispatch(GetissueDetails(item))
      .unwrap()
      .then((data) => {
        setName(data?.tickets?.data[0]?.name);
        setIcon(data?.tickets?.data[0]?.icon);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };
  const nameChange = (value) => {
    setName(value);
    setNameError("");
  };

  const handleClickCancel = () => {
    navigate(`/issues/${name}/${id}`);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp; &#8811;
              <Link to="/support">Tickets Statistics</Link>&nbsp; &#8811;
              <Link to={`/issues/${name}/${id}`}>Issues</Link>&nbsp; &#8811;
              Edit Issue
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Issue</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Name</span>
                      <input
                        type="text"
                        placeholder="Enter the issue "
                        className="form-control"
                        value={name}
                        onChange={(e) => nameChange(e.target.value)}
                      />
                      <p className="alert-message">{nameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Icon</span>
                      <input
                        type="file"
                        placeholder="Enter the icon"
                        className="form-control"
                        onChange={IconChange}
                      />
                      <p className="alert-message">{iconError}</p>
                    </div>
                  </div>
                  <div className="col-md-10 mt-3" align="center">
                    <button
                      type="submit"
                      className="btn btn-outline-danger "
                      onClick={handleClickCancel}
                    >
                      Cancel{" "}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2"
                      onClick={handleSubmit}
                    >
                      Submit{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default EditIssue;
