import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EditSubjects1, GetSubjectDetails } from "../../store/slices/courses";
import { useEffect } from "react";
import FileUpload from "../../utils/helpers/Fileupload";
import { Logout } from "../../utils/helpers/Logout";

function EditSubjects() {
  const { id1 } = useParams();
  const [subjectName, setSubjectName] = useState("");
  const [icon, setIcon] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subjectError, setSubjectError] = useState();
  const [iconError, setIconError] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (subjectName === "") {
      setSubjectError("Please enter subject name");
    }
    if (icon === "") {
      setIconError("Please enter subject icon");
    }
    if (!icon || !subjectName) {
      return;
    } else {
      Editsubjects();
    }
  };

  async function Editsubjects() {
    setButtonDisabled((prevState) => !prevState);
    setTimeout(() => {
      setButtonDisabled((prevState) => !prevState);
    }, 4000);
    let status = "active";
    let type = "fulltime";
    let item = {
      subjectName,
      id: id1,
      type,
      status,
      icon,
    };

    dispatch(EditSubjects1(item))
      .unwrap()
      .then(() => {
        alert("Subject updated succesfully");
        navigate("/subjects");
      })
      .catch(({ message }) => {
        alert(message);
        setButtonDisabled(false);
      });
  }

  const subjectChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
    setSubjectName(filteredValue);
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;

    if (!categorynameregex.test(filteredValue)) {
      setSubjectError("Please enter a valid name");
    } else {
      setSubjectError("");
    }
  };

  const IconChange = async (e) => {
    try {
      const val = await FileUpload(e);
      setIcon(val);
      setIconError("");
    } catch (error) {
      alert("File Size is Large");
      setIconError("File Size is Large choose small size");
    }
  };

  useEffect(() => {
    dispatch(GetSubjectDetails(id1))
      .unwrap()
      .then((data) => {
        setSubjectName(data?.user?.data[0]?.subjectName);
        setIcon(data?.user?.data[0]?.icon);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  const courseid = localStorage.getItem("courseId");
  const categoryid = localStorage.getItem("categoryId");
  const cancelClick = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/subjects">Subjects</Link>
              &nbsp;&#8811; Edit Subject
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Subject</h4>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Subject Name</span>
                      <input
                        type="text"
                        placeholder="Enter subject name"
                        className="form-control"
                        value={subjectName}
                        onChange={(e) => subjectChange(e.target.value)}
                      />
                      <p className="alert-message">{subjectError}</p>
                    </div>

                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Icon</span>
                      <br />
                      <img src={icon} style={{ height: "70px" }} alt="" />{" "}
                      <br />
                      <br />
                      <input
                        type="file"
                        placeholder="Enter icon url link"
                        className="form-control"
                        onChange={IconChange}
                      />
                      <p className="alert-message">{iconError}</p>
                    </div>
                  </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={cancelClick}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2 wd-100"
                      onClick={handleSubmit}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default EditSubjects;
