import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Table, Container } from "reactstrap";
import ReactPaginate from "react-paginate";
import { DeleteSubject, getSubjects } from "../../store/slices/courses";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";

function CourseSubjects() {
  const teamHeaders = ["Name", "Icon", "Combination", "Action"];
  const [courseSubjects, setCourseSubjects] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  const item = {
    courseId: id,
    limit: itemsPerPage,
    page: currentPage,
  };

  const navigate = useNavigate();
  const handleAddSubjectClick = () => {
    navigate("/addsubject");
  };
  useEffect(() => {
    localStorage.setItem("combinationId", id);
  }, []);

  const handleEditSubjectClick = (id1) => {
    navigate(`/editsubject/${id1}`);
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, itemsPerPage, currentPage]);
  const fetchData = () => {
    setLoading(true);
    dispatch(getSubjects(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setCourseSubjects(data.user.data);
        setTotaldata(data?.user?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {});
  };

  const HandleDelete = () => {
    const item = {
      id: deleteId,
    };
    dispatch(DeleteSubject(item))
      .unwrap()
      .then((data) => {
        alert(data?.user.message);
        setShowModal(false);
        fetchData();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleViewCombinationsClick = (courseId) => {
    navigate(-1);
  };
  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={HandleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>
              &nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811; Subjects
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Subjects</h4>
                  <button
                    className="btn btn-primary btn-sm ms-3"
                    onClick={handleAddSubjectClick}
                  >
                    Add Subjects
                  </button>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !courseSubjects?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>Subjects have not yet been added.</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead>
                        <tr>
                          {teamHeaders.map((header, index) => (
                            <th
                              className="text-center align-middle"
                              key={index}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {courseSubjects.map(
                          (
                            subject,
                            index // Updated variable name
                          ) => (
                            <tr key={index}>
                              <td className="text-center align-middle">
                                {subject?.subjectName}
                              </td>
                              <td className="text-center align-middle">
                                <img
                                  src={subject.icon}
                                  alt={`${subject.subjectName} logo`}
                                  style={{
                                    maxWidth: "80px",
                                    maxHeight: "50px",
                                  }}
                                />
                              </td>
                              <td className="text-center align-middle">
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={handleViewCombinationsClick}
                                >
                                  View Combinations
                                </button>
                              </td>

                              <td className="text-center align-middle">
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  onClick={() =>
                                    handleEditSubjectClick(subject._id)
                                  }
                                >
                                  <MdEdit className="viewbtn" />
                                </button>

                                <button
                                  className="btn btn-outline-danger  btn-sm ms-2"
                                  onClick={() => handleDeleteClick(subject._id)}
                                >
                                  <MdDelete className="viewbtn" />
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
              
        </div>
        <div className="mr-5">
          {totaldata / itemsPerPage > 1 && (
            <div className="mt-5 d-flex justify-content-end align-right">
              <ReactPaginate
                key={currentPage}
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakLinkClassName={"page-link"}
                pageCount={Math.ceil(totaldata / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={pagesToShowInitially}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                initialPage={currentPage - 1}
              />
            </div>
          )}
        </div>
        </div>
          
          </Container>
          </div>
                </React.Fragment>
    </>
  );
}
export default CourseSubjects;
