import React, { useState, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import ImageResize from "quill-image-resize-module-react";
import axios from "axios";
Quill.register("modules/imageResize", ImageResize);
const Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
];
Quill.register(Size, true);

const TextEditor2 = ({ question, handleQuestionChange, questionIndex }) => {
  const [showModal, setShowModal] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const quillRef = useRef(null);

  // Initialize editor with content if available
  useEffect(() => {
    if (question?.explanation && !isInitialized) {
      setEditorHtml(question?.explanation);
      setIsInitialized(true);

      // Manually set the correct font size in the dropdown after initialization
      const editor = quillRef.current?.getEditor();
      const selectedSize = editor.getFormat().size || "16px"; // Default to 16px
      console.log(selectedSize);
      const picker = document.querySelector(".ql-size .ql-picker-label");
      picker.setAttribute("data-value", selectedSize);
    }
  }, [question?.explanation, isInitialized]);

  const handleEditorChange = (html, delta, source, editor) => {
    setEditorHtml(html);
    handleQuestionChange(
      { target: { name: "explanation", value: html } },
      questionIndex
    );
  };
  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the image using Axios
        const response = await axios.post(
          "https://api.learningmastery.in/api/v1/admin/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const imageUrl = response.data.location;
        console.log("Image URL:", imageUrl); // Check if the URL is valid

        const editor = quillRef.current.getEditor(); // Access the Quill editor instance
        editor.focus();
        const range = editor.getSelection(); // Get the current cursor position

        if (range) {
          editor.insertEmbed(range.index, "image", imageUrl);
          editor.setSelection(range.index + 1);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: Size.whitelist }],
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  return (
    <div className="mt-2">
      <input
        type="file"
        id="imageUpload"
        accept="image/*"
        style={{ width: "20%" }}
        className="form-control"
        onChange={handleImageUpload}
      />
      <br />
      <ReactQuill
        ref={quillRef}
        value={editorHtml}
        onChange={handleEditorChange}
        modules={modules}
        placeholder="Start From Here..."
        theme="snow"
      />
      <br />
    </div>
  );
};

export default TextEditor2;
