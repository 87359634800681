import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  getFundamentalChapters,
  deleteFundamentalChapters,
} from "../../../store/slices/fundamentals";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Button, Col ,Container,Table} from "reactstrap";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import ReactPaginate from "react-paginate";
import { MdDelete, MdEdit } from "react-icons/md";

function FundamentalChapters() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const teamHeaders = ["Number", "Name", "Units", "Action"];
  const [loading, setLoading] = useState(true);
  const [totaldata, setTotaldata] = useState();
  const [data, setData] = useState([]);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
    id
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };


  useEffect(() => {
   
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout()
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [chapterId, setChapterId] = useState("");
  const navigate = useNavigate("");

  useEffect(() => {
    GetFundamentalChapters();
  }, [dispatch,itemsPerPage, currentPage]);

  const GetFundamentalChapters = () => {
    setLoading(true);
    dispatch(getFundamentalChapters(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setData(data.chapters.data);
        setTotaldata(data?.chapters?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {});
  };
  const handleDelete = () => {
    dispatch(deleteFundamentalChapters(chapterId))
      .unwrap()
      .then((data) => {
        console.log({ data });
        alert(data.chapters.message);
        setShowModal(false);
        GetFundamentalChapters(id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };
  const handleDeleteClick = (elem) => {
    setShowModal(true);
    setChapterId(elem._id);
  };

  const handleFundamentalAddChapterClick = () => {
    navigate(`/fundamentals/add-chapter/${id}`,{state:data[0]});
  };
  const handleChapterUnitsClick = (elem) => {
    navigate(`/fundamentals/units/${elem._id}`);
  };

  useEffect(()=>{
    localStorage.setItem("fundmentalchapterId", id);
  },[])

  const handleEditClick = (elem) => {
    navigate(`/fundamentals/edit-chapter/${id}/${elem._id}`,{state:data[0]});
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
 <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{ fontWeight: "600" }}
                  >
                    <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
                    <Link to="/fundamentals/subjects">Fundamentals</Link>
                    &nbsp;&#8811;&nbsp;Fundamental Chapters
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex align-items-center">
                      <h4 class="card-title">
                    {data?.length >0 && data[0]?.subjectId?.subjectName} &#8811;&nbsp;
                   
                  </h4>
                        <button
                          className="btn btn-primary btn-sm ms-2"
                          onClick={handleFundamentalAddChapterClick}
                        >
                          Add Chapter
                        </button>
                      </div>
                    </div>

                    <div class="card-body collapse show">
                      <div class="card-block card-dashboard table-responsive">
                        {loading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : !data?.length ? (
                          <div className="mt-4 text-center card-body collapse show pb-3">
                            <h5> No chapters were added to this screen.</h5>
                          </div>
                        ) : (
                          <Table
                            responsive
                            hover
                            style={{ overflowX: "scroll" }}
                          >
                             <thead>
                        <tr>
                          {teamHeaders.map((header, index) => (
                            <th
                              className="text-center align-middle"
                              key={index}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                            <tbody>
                              {data?.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">
                                   
                                    {item.chapterNumber}
                                  </td>
                                  <td className="text-center align-middle">
                                  {item.chapterName}
                                  </td>
                                  <td className="text-center align-middle">
                                    <button
                                      className="btn btn-sm btn-outline-primary"
                                      onClick={() =>
                                        handleChapterUnitsClick(item)
                                      }
                                    >
                                      View Units
                                    </button>
                                  </td>
                                  <td className="text-center align-middle">
                                    <button
                                      className="btn btn-outline-info btn-sm"
                                      onClick={() => handleEditClick(item)}
                                    >
                                      <MdEdit className="viewbtn" />
                                    </button>

                                    <button
                                      className="btn btn-outline-danger btn-sm ms-2"
                                      onClick={() => handleDeleteClick(item)}
                                    >
                                     <MdDelete className="viewbtn" />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                  
        <div className="mr-5">
        {totaldata / itemsPerPage > 1 && (
          <div className="mt-5 d-flex justify-content-end align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
      </div>
     </Container>
      </div>
      </React.Fragment>
    </>
  );
}
export default FundamentalChapters;
