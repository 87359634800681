import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postissues } from "../../store/slices/faq";
import FileUpload from "../../utils/helpers/Fileupload";
import { Logout } from "../../utils/helpers/Logout";

function AddIssue() {
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [nameError, setNameError] = useState();
  const [iconError, setIconError] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setNameError("Please enter name");
    }
    if (icon === "") {
      setIconError("Please enter icon");
    }

    if (!name || !icon) {
      return;
    }

    let item = {
      name,
      icon,
      status: 1,
    };
    setButtonDisabled(true);

    dispatch(postissues(item))
      .unwrap()
      .then((data) => {
        setButtonDisabled(false);
        alert("Issue is successfully added");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
        setButtonDisabled(false);
      });
  };

  const nameChange = (value) => {
    setName(value);
    setNameError("");
  };

  const IconChange = async (e) => {
    try {
      const val = await FileUpload(e);
      setIcon(val);
      setIconError("");
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to={"/support"}>Support</Link>&nbsp;&#8811; Add Issue
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Add Issue</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Name</span>
                      <input
                        type="text"
                        placeholder="Enter the issue "
                        className="form-control"
                        value={name}
                        onChange={(e) => nameChange(e.target.value)}
                      />
                      <p className="alert-message">{nameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Icon</span>
                      <input
                        type="file"
                        placeholder="Enter the icon"
                        className="form-control"
                        onChange={IconChange}
                      />
                      <p className="alert-message">{iconError}</p>
                    </div>
                  </div>
                  <div className="col-md-10 mt-3" align="center">
                    <button
                      type="submit"
                      className="btn btn-outline-danger"
                      onClick={handleClickCancel}
                    >
                      Cancel{" "}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2"
                      onClick={handleSubmit}
                    >
                      Submit{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default AddIssue;
