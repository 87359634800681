import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AddCombinations, getSubjects } from "../../store/slices/courses";
import { Logout } from "../../utils/helpers/Logout";

function AddCombination() {
  const navigate = useNavigate("");
  const { id } = useParams();
  const [subjectError, setSubjectError] = useState();
  const [subjectName, setSubjectName] = useState();
  const [subjects, setSubjects] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const combinationId = localStorage.getItem("combinationId");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const save = (e) => {
    e.preventDefault();
    if (!subjectName) {
      setSubjectError("Please select subject");
    }

    if (!subjectName) {
      return;
    } else {
      addData(e);
    }
  };

  async function addData(e) {
    e.preventDefault();
    let status = 1;
    let item = {
      subjectId: subjectName,
      status,
      courseId: id,
    };
    setButtonDisabled(true);
    dispatch(AddCombinations(item))
      .unwrap()
      .then(() => {
        setButtonDisabled(false);
        alert("Combination added sucessfully!");
        navigate(-1);
      })
      .catch(({ message }) => {
        setButtonDisabled(false);
        alert(message);
      });
  }

  const subjectChange = (value) => {
    setSubjectName(value);
    setSubjectError("");
  };

  useEffect(() => {
    const item = {
      limit: 100,
      page: 1,
    };
    dispatch(getSubjects(item))
      .unwrap()
      .then((data) => {
        setSubjects(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
        setButtonDisabled(false);
      });
  }, []);

  const cancelHandler = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/combination/${id}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;Add Combinations
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Add Combination</h4>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Subject</span>
                      <select
                        type="select"
                        placeholder="select subject"
                        className="form-control"
                        value={subjectName}
                        onChange={(e) => subjectChange(e.target.value)}
                      >
                        <option value="">Select</option>
                        {subjects.map((el) => (
                          <option value={el._id}>{el.subjectName}</option>
                        ))}
                      </select>
                      <p className="alert-message">{subjectError}</p>
                    </div>
                  </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={cancelHandler}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2 wd-100"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default AddCombination;
