import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Col, Container, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { DeleteCategory, Getcategory } from "../../store/slices/courses";
import DeleteModal from "../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";

function Category() {
  const teamHeaders = ["Name", "Course", "Action"];
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const handleViewResponseClick = (id) => {
    navigate(`/courses/${id}`);
  };

  const handleAddCategoryClick = () => {
    navigate("/add-category");
  };

  const HandleEdit = (id) => {
    navigate(`/edit-category/${id}`);
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };
  useEffect(() => {
    getcategories(item);
  }, [itemsPerPage, currentPage]);

  const getcategories = (item) => {
    setLoading(true);
    dispatch(Getcategory(item))
      .unwrap()
      .then((data) => {
        setCategories(data?.user?.paginatedData);
        setTotaldata(data?.user?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const HandleDelete = () => {
    const item = {
      id: deleteId,
    };
    dispatch(DeleteCategory(item))
      .unwrap()
      .then((data) => {
        alert(data?.user?.message);
        setShowModal(false);
        getcategories(item);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={HandleDelete}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811; Category
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Category</h4>
                  <button
                    className="btn btn-primary btn-sm ms-2 "
                    onClick={handleAddCategoryClick}
                  >
                    Add Category
                  </button>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div class="card-body collapse show">
                    <div class="card-block card-dashboard table-responsive">
                      {loading ? (
                        <Col lg={12} xs={12} md={12} align="center">
                          {ShowSkeleton()}
                        </Col>
                      ) : !categories?.length ? (
                        <div className="mt-4 text-center card-body collapse show pb-3">
                          <h5> No Categories were added yet. </h5>
                        </div>
                      ) : (
                        <Table responsive hover style={{ overflowX: "scroll" }}>
                          <thead class="responseHeaderStyle">
                            <tr>
                              {teamHeaders.map((header, index) => (
                                <th className="text-center" key={index}>
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {categories?.map((category, rowIndex) => (
                              <tr className="text-center" key={rowIndex}>
                                <td className="text-center align-middle">
                                  {category.name}
                                </td>

                                <td>
                                  <button
                                    onClick={() =>
                                      handleViewResponseClick(category._id)
                                    }
                                    className="btn btn-outline-primary btn-sm"
                                  >
                                    View Course
                                  </button>
                                </td>
                                <td>
                                  <button
                                    onClick={() => HandleEdit(category._id)}
                                    className="btn btn-outline-info btn-sm"
                                  >
                                    Edit
                                  </button>

                                  <button
                                    onClick={() =>
                                      handleDeleteClick(category._id)
                                    }
                                    className="btn btn-outline-danger btn-sm  ms-3"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {totaldata / itemsPerPage > 1 && (
                    <div className="mt-5 d-flex justify-content-end align-right">
                      <ReactPaginate
                        key={currentPage}
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakLinkClassName={"page-link"}
                        pageCount={Math.ceil(totaldata / itemsPerPage)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={pagesToShowInitially}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        disabledClassName="disabled"
                        initialPage={currentPage - 1}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Category;
