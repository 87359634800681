import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { useDispatch } from "react-redux";
import FileUpload from "../../utils/helpers/Fileupload";
import { Logout } from "../../utils/helpers/Logout";
import { getCourses } from "../../store/slices/courses";
import { AddAlert, EditAlerts, GetAlerts } from "../../store/slices/alert";
import TextEditorDetails from "../../utils/helpers/DetailsEditor";

function EditAlert() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [details, setDetails] = useState("");
  const [courseId, setCourseId] = useState("");
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [titleError, settitleError] = useState("");
  const [iconError, setIconError] = useState("");
  const [courseIdError, setCourseIdError] = useState("");
  const [detailsError, setDetailsError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    GetCourses();
    GetData();
  }, []);

  const GetCourses = () => {
    dispatch(getCourses())
      .unwrap()
      .then((data) => {
        setCourses(data?.user?.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const GetData = () => {
    let item = {
      id: id,
    };
    dispatch(GetAlerts(item))
      .unwrap()
      .then((data) => {
        setCourseId(data?.alerts?.data[0]?.courseId?._id);
        setTitle(data?.alerts?.data[0]?.title);
        setDetails(data?.alerts?.data[0]?.details);
        setIcon(data?.alerts?.data[0]?.icon);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const save = (e) => {
    e.preventDefault();
    let valid = true;

    if (title === "") {
      settitleError("Please enter course name");
      valid = false;
    }

    if (details === "") {
      setDetailsError("Please enter  details");
      valid = false;
    }

    if (courseId === "") {
      setCourseIdError("Please choose course");
      valid = false;
    }
    if (icon === "") {
      setIconError("Please upload icon");
      valid = false;
    }

    if (!valid) {
      return;
    }

    EditData(e);
  };

  const titleChange = (value) => {
    setTitle(value);
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
    if (!categorynameregex.test(value)) {
      settitleError("Please enter valid name");
    } else {
      settitleError("");
    }
  };

  const detailsChange = (value) => {
    setDetails(value);
    if (!value) {
      setDetailsError("Please enter details");
    } else {
      setDetailsError("");
    }
  };

  const courseIdChange = (value) => {
    setCourseId(value);
    if (!value) {
      setCourseIdError("Please choose courseid");
    } else {
      setCourseIdError("");
    }
  };

  const IconChange = async (e) => {
    try {
      console.log("Uploading file...");
      const val = await FileUpload(e);
      console.log("File uploaded. URL:", val);
      setIcon(val);
      setIconError("");
    } catch (error) {
      console.error("File upload failed:", error);
      alert("File Size is Large");
      setIconError("File Size is Large. Choose a smaller size.");
    }
  };

  async function EditData(e) {
    e.preventDefault();

    const item = {
      title: title,
      details: details,
      courseId: courseId,
      icon: icon,
      id: id,
    };

    setButtonDisabled(true);

    dispatch(EditAlerts(item))
      .unwrap()
      .then(() => {
        alert("Alert edited successfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        console.error("Error edit alert:", message);
        alert(message);
        setButtonDisabled(false);
      });
  }

  const cancelClick = () => {
    navigate("/alerts");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
            <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp; Add Alerts
          </div>
          <div className="card">
            <div className="card-header">
              <div className="card-title-wrap bar-success d-flex align-items-center">
                <h4 className="card-title">Add Alert</h4>
              </div>
            </div>
            <div className="card-body collapse show">
              <div className="card-block card-dashboard table-responsive">
                <div className="row">
                  <div
                    style={{ height: "100%" }}
                    className="col-md-5 pt-2 pb-2"
                  >
                    <span className="label">Title </span>
                    <input
                      type="text"
                      placeholder="Enter title"
                      className="form-control"
                      value={title}
                      onChange={(e) => titleChange(e.target.value)}
                    />
                    <p className="alert-message">{titleError}</p>
                  </div>

                  <div
                    style={{ height: "100%" }}
                    className="col-md-5 pt-2 pb-2"
                  >
                    <span className="label">Icon</span>
                    <input
                      type="file"
                      placeholder="Enter icon URL link"
                      className="form-control"
                      onChange={IconChange}
                    />
                    <p className="alert-message">{iconError}</p>
                  </div>
                </div>
                <div style={{ height: "100%" }} className="col-md-5 pt-2 pb-2">
                  <span className="label">Course</span>
                  <select
                    name="upgradePlan"
                    className="form-control"
                    value={courseId}
                    onChange={(e) => courseIdChange(e.target.value)}
                  >
                    <option value="">Select</option>

                    {courses.map((elem) => (
                      <option value={elem?._id}>{elem?.courseName}</option>
                    ))}
                  </select>
                  <p className="alert-message">{courseIdError}</p>
                </div>
                <div className="row">
                  <div
                    style={{ height: "100%" }}
                    className="col-md-10 pt-2 pb-2"
                  >
                    <span className="label">Details</span>
                    <TextEditorDetails
                      data={details}
                      handleChange={detailsChange}
                    />
                    <p className="alert-message">{detailsError}</p>
                  </div>
                </div>

                <div className="col-md-10 mt-3" align="center">
                  <button
                    className="btn btn-outline-info wd-100"
                    onClick={cancelClick}
                    disabled={buttonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info ms-2 wd-100"
                    onClick={save}
                    disabled={buttonDisabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default EditAlert;
