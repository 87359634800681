import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import challengesService from "../../services/challenges.service"



export const AddChallenges = createAsyncThunk(
    "/post/challenges",
    async (item, thunkAPI) => {
      try {
        const data = await challengesService.addChallenge(item);
        return { challenges: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const EditChallenges = createAsyncThunk(
    "/put/challenges",
    async (item, thunkAPI) => {
      try {
        const data = await challengesService.editChallenge(item);
        return { challenges: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const DeleteChallenges = createAsyncThunk(
    "/delete/challenges",
    async (item, thunkAPI) => {
      try {
        const data = await challengesService.deleteChallenge(item);
        return { challenges: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const GetChallenges = createAsyncThunk(
    "/get/challenges",
    async (item, thunkAPI) => {
      try {
        const data = await challengesService.getchallenges(item);
        return { challenges: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  


  
  
const initialState = {
    loading: false,
    error: "",
  plas :[]||null,
    isLoggedIn: false,
};

const challenges = createSlice({
    name: "challenges",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = challenges;
export default reducer;




  


