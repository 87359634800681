import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Button, Container, Table, Col } from "reactstrap";
import { Getissues, deleteIssues, getTickets } from "../../../store/slices/faq";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";

function GetIssues() {
  const [tickets, setTickets] = useState([]);
  const { name, id } = useParams();
  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  const navigate = useNavigate();

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    GetTickets();
  }, [itemsPerPage, currentPage]);

  const GetTickets = () => {
    setLoading(true);
    const item = {
      id: id,
      limit: itemsPerPage,
      page: currentPage,
    };
    dispatch(Getissues(item))
      .unwrap()
      .then((data) => {
        setTickets(data?.tickets?.data);
        setTotaldata(data?.tickets?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleEditClick = (id) => {
    navigate(`/issues-edit/${id}`);
  };

  const DeleteIssues = () => {
    const item = {
      id: deleteId,
    };
    dispatch(deleteIssues(item))
      .unwrap()
      .then((data) => {
        alert(data.tickets.message);
        setShowModal(false);
        GetTickets();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={DeleteIssues}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {" "}
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp; &#8811;
              <Link to="/support">Tickets Statistics</Link>&nbsp; &#8811; Issues
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Issues</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !tickets?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5> No Issues were raised yet. </h5>
                    </div>
                  ) : (
                    <>
                      <h4 className="text-center pb-2">{name} </h4>
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead class="responseHeaderStyle">
                          <tr>
                            <th className="text-center">Name</th>
                            <th className="text-center">Icon</th>

                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tickets?.map((ticket, rowIndex) => (
                            <tr key={rowIndex}>
                              <td className="text-center align-middle">
                                {ticket?.name}
                              </td>
                              <td className="text-center align-middle">
                                <img
                                  style={{ height: "50px", width: "50px" }}
                                  src={ticket?.icon}
                                />
                              </td>

                              <td className="text-center align-middle">
                                <button
                                  className="btn saveBtn1 ms-2"
                                  onClick={() => handleEditClick(ticket?._id)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-danger ms-2 mt-2"
                                  onClick={() => handleDeleteClick(ticket?._id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </div>
              <div className="mr-5">
                {totaldata / itemsPerPage > 1 && (
                  <div className="mt-5 d-flex justify-content-end align-right">
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(totaldata / itemsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default GetIssues;
