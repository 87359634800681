import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class FaqService {
  static postFaq(item) {
  
    return axiosInstance.post(API_PATHS.addFaq, item)
  }

  static getFaq(item) {
  
    let api = "";
    api = `${API_PATHS.getFaq}?limit=${item?.limit}&page=${item?.page}`;

    return axiosInstance.get(api)
  }

  static editFaq(item) {
  
    return axiosInstance.put(API_PATHS.editFaq, item)
  }

  static getFaqDetails(id) {
    let api = "";
      api = `${API_PATHS.getFaq}?id=${id}`;
    return axiosInstance.get(api)
  }

  static deleteFaq(item) {
  
    let api = `${API_PATHS.deleteFaq}?id=${item.id}`;
        return axiosInstance.delete(api)
  }

  // tickets
  static getTicketList() {

    let api = `${API_PATHS.getTicketsList}`;
  
    return axiosInstance.get(api)
  }

  //close tickets
  static closeTicket(item) {
  
    let api = `${API_PATHS.closeTicket}`;
        return axiosInstance.post(api, item)
  }

  static getTicketStatistics() {
  
    let api = `${API_PATHS.getTicketStatistics}`;
        return axiosInstance.get(api)
  }

  //issues

  static getIssues(item) {
  
    let api = `${API_PATHS.getIssues}?id=${item.id}`;
        return axiosInstance.get(api)
  }

  static getIssueDetails(item) {
  
    let api = `${API_PATHS.getIssues}?id=${item.id}`;
        return axiosInstance.get(api)
  }

  static addIssues(item) {
  
    let api = `${API_PATHS.addIssues}`;
        return axiosInstance.post(api, item)
  }

  static editIssues(item) {
  
    let api = `${API_PATHS.editIssues}`;
        return axiosInstance.put(api, item)
  }

  static deleteIssues(item) {
  
    let api = `${API_PATHS.deleteIssues}?id=${item.id}`;
        return axiosInstance.delete(api)
  }

  static getSupportIssues(item) {
  
    let api = "";
    api = `${API_PATHS.getSupportIssues}`;

    return axiosInstance.get(api, item)
  }
  static postIssues(item) {
  
    return axiosInstance.post(API_PATHS.addIssues, item)
  }

  static getVisitorsList(item) {
  
    let api = `${API_PATHS.getVisitorsList}?limit=${item.limit}&page=${item.page}`;
    return axiosInstance.get(api)
  }

  static getCategoryQuiz(item) {
   

      
      let api = "";
      if(item.id){
        api = `${API_PATHS.getCategoryQuiz}?id=${item.id}`;
      }else{
      api = `${API_PATHS.getCategoryQuiz}?limit=${item?.limit}&page=${item?.page}`;
      }
      
    return axiosInstance.get(api)
}

static deleteCategoryQuiz(item) {

  let api = `${API_PATHS.deleteCategoryQuiz}?id=${item.id}`;
 
  return axiosInstance.delete(api)
}

static postCategoryQuiz(item) {
 
  return axiosInstance.post(API_PATHS.postCategoryQuiz, item)
 
}

static editCategoryQuiz(item) {

  return axiosInstance.put(API_PATHS.editCategoryQuiz, item)
}

static getCategoryQuizDetails(id) {
 
  let api;
  if (id) {
    api = `${API_PATHS.getCategoryQuiz}?id=${id}`;
  } else {
    api = `${API_PATHS.getCategoryQuiz}`;
  }
 

  return axiosInstance.get(api)

}

static getContentQuiz(item) {
  let api;
 
     api= `${API_PATHS.getContentQuiz}?quizCategoryId=${item.id}`
 
    return axiosInstance
      .get(api )
    
}

static deleteContentQuiz(item) {
 
  let api = `${API_PATHS.deleteContentQuiz}?id=${item.id}`;
    return axiosInstance.delete(api)
   
    }

    static postContentQuiz(item) {
   
      return axiosInstance.post(API_PATHS.postContentQuiz, item)
    }

    static editContentQuiz(item) {
    let api;
     
        api = API_PATHS.editContentQuiz 
    
      return axiosInstance
          .put(api,item)
    }

    static getContentQuizDetails(quizCategoryId,id) {
    let api;
    
        api = `${API_PATHS.getContentQuiz}?quizCategoryId=${quizCategoryId}&id=${id}`;
      return axiosInstance.get(api)
          
    }


}
export default FaqService;
