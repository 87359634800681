import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { postFundmentalChapters } from "../../../../store/slices/fundamentals";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Logout } from "../../../../utils/helpers/Logout";
import { categorynameregex } from "../../../../utils/Regex";

function AddFundamentalChapter() {
  const { id } = useParams();
  const [chapterName, setChapterName] = useState("");
  const [chapterNumber, setChapterNumber] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const location = useLocation();
  const data = location.state || {};

  console.log(data)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  const [chapterNameError, setChapterNameError] = useState();
  const [chapterNumberError, setChapterNumberError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (chapterName === "") {
      setChapterNameError("Please enter chapter name");
    }
    if (chapterNumber === "") {
      setChapterNumberError("Please enter chapter number ");
    }

    if (!chapterNumber || !chapterName) {
      return;
    }

    let item = {
      chapterName,
      chapterNumber: parseInt(chapterNumber),
      status: 1,
      subjectId: id,
    };
    setButtonDisabled(true);
    dispatch(postFundmentalChapters(item))
      .unwrap()
      .then((data) => {
        setButtonDisabled(false);
        alert(data.chapters.message);
        navigate(`/fundamentals/chapters/${id}`);
      })
      .catch(({ message }) => {
        alert(message);
        setButtonDisabled(false);
      });
  };

  const chapterNameChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
    setChapterName(filteredValue);
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
    if (!categorynameregex.test(filteredValue)) {
      setChapterNameError("Please enter valid name");
    } else {
      setChapterNameError("");
    }
  };

  const chapterNumberChange = (val) => {
    if (val <= 0) {
      setChapterNumberError("Please enter valid chapter number");
    } else {
      setChapterNumberError("");
    }
    setChapterNumber(val);
  };
  const handleCancelClick = () => {
    navigate(`/fundamentals/chapters/${id}`);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div class="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link
                to={`/fundamentals/chapters/${id}`}
                style={{ marginLeft: "3px" }}
              >
                FundamentalChapters
              </Link>
              &nbsp;&#8811; AddFundamentalChapter
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                <h4 class="card-title">
                  {data?.subjectId?.subjectName}
                  </h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Chapter Name</span>
                      <input
                        type="text"
                        placeholder="Enter chapter name"
                        className="form-control"
                        value={chapterName}
                        onChange={(e) => chapterNameChange(e.target.value)}
                      />
                      <p className="alert-message">{chapterNameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Chapter Number</span>
                      <input
                        type="string"
                        placeholder="Enter chapter number"
                        className="form-control"
                        value={chapterNumber}
                        maxLength={3}
                        onInput={(e) => {
                          e.target.value = e?.target?.value
                            ?.replace(/[^0-9]/g, "")
                            .slice(0, 3);
                        }}
                        onChange={(e) => chapterNumberChange(e.target.value)}
                      />
                      <p className="alert-message">{chapterNumberError}</p>
                    </div>
                  </div>
                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100 "
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100 ms-2"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default AddFundamentalChapter;
