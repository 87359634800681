import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button ,Container} from "reactstrap";
import {useDispatch} from "react-redux";
import { useNavigate ,useParams} from "react-router-dom";
import {  editPlans, getPlanDetails } from "../../store/slices/plans";
import { useEffect } from "react";


function EditPlans() {
    const {id} = useParams();
    const [planName, setPlanName] = useState();
  const [price, setPrice] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch()
const [error,setErrors] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [validity,setValidity] = useState()
  const [description,setDescription] = useState()
  const [gst, setGst] = useState("");  
  const [maxCourse, setMaxCourse] = useState("");  
  const [upgradePlan, setUpgradePlan] = useState("");  


  const save = (e) => {
    e.preventDefault();

    let vaidationError ={}
    if (!planName) {
        vaidationError.planName = "Please enter plan name"
    }
    if (!price) {
        vaidationError.price = "Please enter  price"
    }
    if(!validity){
        vaidationError.validity = "Please enter validity"
    }
    
    if(!description){
        vaidationError.description = "Please enter description"
    }
    if (!gst) {
      vaidationError.gst = "Please enter GST percentage";
    }
    if (!maxCourse) {
      vaidationError.maxCourse = "Please enter max course";
    }
    if (!upgradePlan) {
      vaidationError.upgradePlan= "Please enter upgarde plan";
    }
    if (Object.keys(vaidationError).length > 0) {
        setErrors(vaidationError);
        return;
      }  else {
      addData(e);
    }
  };

  useEffect(()=>{
    dispatch(getPlanDetails(id))
    .unwrap()
    .then((data) => {
      console.log({data});
     setPlanName(data?.plans?.data[0]?.name)
     setPrice(data?.plans?.data[0]?.price)
     setValidity(data?.plans?.data[0]?.validityInDays)
     setDescription(data?.plans?.data[0]?.description)
     setGst(data?.plans?.data[0]?.gstPercent)
     setMaxCourse(data?.plans?.data[0]?.maxCourse)
     setUpgradePlan(data?.plans?.data[0]?.upgradePlan)
    })
    .catch(({ message }) => {
      alert(message);
     
    });
  },[])

  async function addData(e) {
    e.preventDefault();
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 4000);

    let item = {
        id:id,
        name: planName,
        price: price,
        validityInDays: validity,
        maxCourse: maxCourse,
        gstPercent: gst,
        status: 1,
        description: description,
        upgradePlan: upgradePlan,  
    };
    dispatch(editPlans(item))
      .unwrap()
      .then(() => {
        alert("Plan updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
       
      });
  }

 const handleChange =(e)=>{
    const validationErrors = { ...error };
    const { name, value } = e.target;
    switch (name) {
      case "planName":
        const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        setPlanName(filteredValue);
        validationErrors[name] = filteredValue === "" ? "Please enter a plan name" : "";
          break;

      case "price":
        if (/^\d{0,4}$/.test(value)) {
        setPrice(value) 
        validationErrors[name] =
          value === "" ? "Please enter  price" : "";
        } else {
          validationErrors[name] = "";
        }
        break;

      case "validity":
        if (/^\d{0,3}$/.test(value)) {
        setValidity(value);
        validationErrors[name] = value === "" ? "Please enter validity" : "";
      } else {
          validationErrors[name] = "";
        }
        break;
        case "gst":
          if (/^\d{0,2}$/.test(value)) {
          setGst(value);
          validationErrors[name] = value === "" ? "Please enter GST percentage" : "";
        } else {
          validationErrors[name] = ""
        }
          break;
        case "maxCourse":
          if (/^\d{0,2}$/.test(value)) {
          setMaxCourse(value);
          validationErrors[name] = value === "" ? "Please enter max course" : "";
        } else {
          validationErrors[name] = ""
        }
          break;
    
          case "description":
            setDescription(value);
            validationErrors[name] = value === "" ? "Please enter description" : "";
            break;
    
            case "upgradePlan":
              setUpgradePlan(value);
                 validationErrors[name] = value === "" ? "Please enter Upgrade Plan" : "";
              break;
            default:
              break;
     
    }
    setErrors(validationErrors);
  };

  

  const handleCancelClick = () =>{
    navigate("/plans");
   }
  return (
    <>
   <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                <div
                  class="text-start my-2 ms-1"
                  style={{
                    fontWeight: "600",
                  }}
                >
                <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
                <Link to="/plans" >
                  Plans
                </Link>&nbsp;&#8811; Edit Plan
      
                </div>
                 <div className="card">
              <div className="card-header">
                <div className="card-title-wrap bar-success d-flex align-items-center">
                  <h4 className="card-title">Edit Plan</h4>
                </div>
              </div>
              <div className="card-body collapse show">
                <div className="card-block card-dashboard table-responsive">
                  <div className="row pl-2">
                    <div style={{ height: "100%" }} className="col-md-5 pt-2 pb-2">
                      <span className="label">Plan Name</span>
                      <input
                        type="text"
                        name="planName"
                        placeholder="Enter plan name"
                        className="form-control"
                        value={planName}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{error?.planName}</p>
                    </div>
                    <div style={{ height: "100%" }} className="col-md-5 pt-2 pb-2">
                      <span className="label">Price</span>
                      <input
                           type="text"
                      inputMode="numeric"
                        placeholder="Enter price"
                        className="form-control"
                        name="price"
                        value={price}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{error?.price}</p>
                    </div>
                  </div>
                  <div className="row pl-2">
                    <div style={{ height: "100%" }} className="col-md-5 pt-2 pb-2">
                      <span className="label">Validity</span>
                      <input
                           type="text"
                      inputMode="numeric"
                        name="validity"
                        placeholder="Enter validity in days"
                        className="form-control"
                        value={validity}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{error?.validity}</p>
                    </div>
                    <div style={{ height: "100%" }} className="col-md-5 pt-2 pb-2">
                      <span className="label">GST (%)</span>
                      <input
                      type="text"
                      inputMode="numeric"
                        name="gst"
                        placeholder="Enter GST percentage"
                        className="form-control"
                        value={gst}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{error?.gst}</p>
                    </div>
                  </div>
                  <div className="row pl-2">
                    <div style={{ height: "100%" }} className="col-md-5 pt-2 pb-2">
                      <span className="label">Max Course</span>
                      <input
                            type="text"
                      inputMode="numeric"
                        name="maxCourse"
                        placeholder="Enter max course"
                        className="form-control"
                        value={maxCourse}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{error?.maxCourse}</p>
                    </div>
                    <div style={{ height: "100%" }} className="col-md-5 pt-2 pb-2">
                      <span className="label">Upgrade Plan</span>
                      <select
                        name="upgradePlan"
                        className="form-control"
                        value={upgradePlan}
                        onChange={handleChange}
                      >
                        <option value="" disabled hidden>
                        Plan
                      </option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>
                    <p className="alert-message">{error?.upgradePlan}</p>
                  </div>
                  <div className="row pl-2">
                    <div style={{ height: "100%" }} className="col-md-10 col-sm-12 mt-2">
                      <label className="bread-head1">Description:</label>
                      <textarea
                        name="description"
                        style={{ height: "60px" }}
                        className="form-control"
                        placeholder="Enter description"
                        value={description}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{error?.description}</p>
                    </div>
                  </div>
               
                  <div className="col-md-12 mt-3" align="center">
                  <button
                      className='btn btn-outline-danger'
                      onClick={handleCancelClick}
                      disabled={buttonDisabled}
                    >
                     Cancel
                    </button>
                    <Button
                      type="submit"
                      className='btn btn-info ms-2'
                      disabled={buttonDisabled}
                      onClick={save}
                    >
                      Submit
                    </Button>
                   
                  </div>
                </div>
              </div>
            </div>
            </Container>
          </div>
          </React.Fragment>
       
    </>
  );
}
export default EditPlans;
